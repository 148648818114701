import { Box, SimpleGrid } from "@chakra-ui/react";
import ReactPlayer from "react-player";

function Dashboard() {
  return (
    <Box p={4} bgColor="white" rounded="base">
      <strong>AÇILIŞ</strong>
      <Box>
        <ReactPlayer
          width={"100%"}
          controls
          height="100%"
          url={"https://cdn.polyline.works/standByMe/acilis-1715932087628.mp4"}
        />
      </Box>
    </Box>
  );
}

export default Dashboard;
