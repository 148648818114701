import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateAnswer = {
  __typename?: 'AggregateAnswer';
  _avg?: Maybe<AnswerAvgAggregate>;
  _count?: Maybe<AnswerCountAggregate>;
  _max?: Maybe<AnswerMaxAggregate>;
  _min?: Maybe<AnswerMinAggregate>;
  _sum?: Maybe<AnswerSumAggregate>;
};

export type AggregateCategory = {
  __typename?: 'AggregateCategory';
  _avg?: Maybe<CategoryAvgAggregate>;
  _count?: Maybe<CategoryCountAggregate>;
  _max?: Maybe<CategoryMaxAggregate>;
  _min?: Maybe<CategoryMinAggregate>;
  _sum?: Maybe<CategorySumAggregate>;
};

export type AggregateChoice = {
  __typename?: 'AggregateChoice';
  _avg?: Maybe<ChoiceAvgAggregate>;
  _count?: Maybe<ChoiceCountAggregate>;
  _max?: Maybe<ChoiceMaxAggregate>;
  _min?: Maybe<ChoiceMinAggregate>;
  _sum?: Maybe<ChoiceSumAggregate>;
};

export type AggregateCompletedLesson = {
  __typename?: 'AggregateCompletedLesson';
  _count?: Maybe<CompletedLessonCountAggregate>;
  _max?: Maybe<CompletedLessonMaxAggregate>;
  _min?: Maybe<CompletedLessonMinAggregate>;
};

export type AggregateContent = {
  __typename?: 'AggregateContent';
  _avg?: Maybe<ContentAvgAggregate>;
  _count?: Maybe<ContentCountAggregate>;
  _max?: Maybe<ContentMaxAggregate>;
  _min?: Maybe<ContentMinAggregate>;
  _sum?: Maybe<ContentSumAggregate>;
};

export type AggregateDescription = {
  __typename?: 'AggregateDescription';
  _count?: Maybe<DescriptionCountAggregate>;
  _max?: Maybe<DescriptionMaxAggregate>;
  _min?: Maybe<DescriptionMinAggregate>;
};

export type AggregateLesson = {
  __typename?: 'AggregateLesson';
  _count?: Maybe<LessonCountAggregate>;
  _max?: Maybe<LessonMaxAggregate>;
  _min?: Maybe<LessonMinAggregate>;
};

export type AggregateNews = {
  __typename?: 'AggregateNews';
  _avg?: Maybe<NewsAvgAggregate>;
  _count?: Maybe<NewsCountAggregate>;
  _max?: Maybe<NewsMaxAggregate>;
  _min?: Maybe<NewsMinAggregate>;
  _sum?: Maybe<NewsSumAggregate>;
};

export type AggregatePage = {
  __typename?: 'AggregatePage';
  _avg?: Maybe<PageAvgAggregate>;
  _count?: Maybe<PageCountAggregate>;
  _max?: Maybe<PageMaxAggregate>;
  _min?: Maybe<PageMinAggregate>;
  _sum?: Maybe<PageSumAggregate>;
};

export type AggregateQuestion = {
  __typename?: 'AggregateQuestion';
  _avg?: Maybe<QuestionAvgAggregate>;
  _count?: Maybe<QuestionCountAggregate>;
  _max?: Maybe<QuestionMaxAggregate>;
  _min?: Maybe<QuestionMinAggregate>;
  _sum?: Maybe<QuestionSumAggregate>;
};

export type AggregateQuestionAnswer = {
  __typename?: 'AggregateQuestionAnswer';
  _count?: Maybe<QuestionAnswerCountAggregate>;
  _max?: Maybe<QuestionAnswerMaxAggregate>;
  _min?: Maybe<QuestionAnswerMinAggregate>;
};

export type AggregateSubject = {
  __typename?: 'AggregateSubject';
  _avg?: Maybe<SubjectAvgAggregate>;
  _count?: Maybe<SubjectCountAggregate>;
  _max?: Maybe<SubjectMaxAggregate>;
  _min?: Maybe<SubjectMinAggregate>;
  _sum?: Maybe<SubjectSumAggregate>;
};

export type AggregateTransaction = {
  __typename?: 'AggregateTransaction';
  _avg?: Maybe<TransactionAvgAggregate>;
  _count?: Maybe<TransactionCountAggregate>;
  _max?: Maybe<TransactionMaxAggregate>;
  _min?: Maybe<TransactionMinAggregate>;
  _sum?: Maybe<TransactionSumAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
};

export type Answer = {
  __typename?: 'Answer';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  downVote: Scalars['Int'];
  id: Scalars['String'];
  subject: Subject;
  subjectId: Scalars['String'];
  upVote: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['String'];
};

export type AnswerAvgAggregate = {
  __typename?: 'AnswerAvgAggregate';
  downVote?: Maybe<Scalars['Float']>;
  upVote?: Maybe<Scalars['Float']>;
};

export type AnswerAvgOrderByAggregateInput = {
  downVote?: InputMaybe<SortOrder>;
  upVote?: InputMaybe<SortOrder>;
};

export type AnswerCountAggregate = {
  __typename?: 'AnswerCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  downVote: Scalars['Int'];
  id: Scalars['Int'];
  subjectId: Scalars['Int'];
  upVote: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type AnswerCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  downVote?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subjectId?: InputMaybe<SortOrder>;
  upVote?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type AnswerCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  downVote?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  subject: SubjectCreateNestedOneWithoutAnswersInput;
  upVote?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutAnswersInput;
};

export type AnswerCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  downVote?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  subjectId: Scalars['String'];
  upVote?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type AnswerCreateManySubjectInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  downVote?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  upVote?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type AnswerCreateManySubjectInputEnvelope = {
  data: Array<AnswerCreateManySubjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AnswerCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  downVote?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  subjectId: Scalars['String'];
  upVote?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerCreateManyUserInputEnvelope = {
  data: Array<AnswerCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AnswerCreateNestedManyWithoutSubjectInput = {
  connect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerCreateOrConnectWithoutSubjectInput>>;
  create?: InputMaybe<Array<AnswerCreateWithoutSubjectInput>>;
  createMany?: InputMaybe<AnswerCreateManySubjectInputEnvelope>;
};

export type AnswerCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AnswerCreateWithoutUserInput>>;
  createMany?: InputMaybe<AnswerCreateManyUserInputEnvelope>;
};

export type AnswerCreateOrConnectWithoutSubjectInput = {
  create: AnswerCreateWithoutSubjectInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerCreateOrConnectWithoutUserInput = {
  create: AnswerCreateWithoutUserInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerCreateWithoutSubjectInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  downVote?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  upVote?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutAnswersInput;
};

export type AnswerCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  downVote?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  subject: SubjectCreateNestedOneWithoutAnswersInput;
  upVote?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerGroupBy = {
  __typename?: 'AnswerGroupBy';
  _avg?: Maybe<AnswerAvgAggregate>;
  _count?: Maybe<AnswerCountAggregate>;
  _max?: Maybe<AnswerMaxAggregate>;
  _min?: Maybe<AnswerMinAggregate>;
  _sum?: Maybe<AnswerSumAggregate>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  downVote: Scalars['Int'];
  id: Scalars['String'];
  subjectId: Scalars['String'];
  upVote: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type AnswerListRelationFilter = {
  every?: InputMaybe<AnswerWhereInput>;
  none?: InputMaybe<AnswerWhereInput>;
  some?: InputMaybe<AnswerWhereInput>;
};

export type AnswerMaxAggregate = {
  __typename?: 'AnswerMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  downVote?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  subjectId?: Maybe<Scalars['String']>;
  upVote?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type AnswerMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  downVote?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subjectId?: InputMaybe<SortOrder>;
  upVote?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type AnswerMinAggregate = {
  __typename?: 'AnswerMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  downVote?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  subjectId?: Maybe<Scalars['String']>;
  upVote?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type AnswerMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  downVote?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subjectId?: InputMaybe<SortOrder>;
  upVote?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type AnswerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum AnswerOrderByRelevanceFieldEnum {
  description = 'description',
  id = 'id',
  subjectId = 'subjectId',
  userId = 'userId'
}

export type AnswerOrderByRelevanceInput = {
  fields: Array<AnswerOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type AnswerOrderByWithAggregationInput = {
  _avg?: InputMaybe<AnswerAvgOrderByAggregateInput>;
  _count?: InputMaybe<AnswerCountOrderByAggregateInput>;
  _max?: InputMaybe<AnswerMaxOrderByAggregateInput>;
  _min?: InputMaybe<AnswerMinOrderByAggregateInput>;
  _sum?: InputMaybe<AnswerSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  downVote?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subjectId?: InputMaybe<SortOrder>;
  upVote?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type AnswerOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<AnswerOrderByRelevanceInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  downVote?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SubjectOrderByWithRelationAndSearchRelevanceInput>;
  subjectId?: InputMaybe<SortOrder>;
  upVote?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum AnswerScalarFieldEnum {
  createdAt = 'createdAt',
  description = 'description',
  downVote = 'downVote',
  id = 'id',
  subjectId = 'subjectId',
  upVote = 'upVote',
  updatedAt = 'updatedAt',
  userId = 'userId'
}

export type AnswerScalarWhereInput = {
  AND?: InputMaybe<Array<AnswerScalarWhereInput>>;
  NOT?: InputMaybe<Array<AnswerScalarWhereInput>>;
  OR?: InputMaybe<Array<AnswerScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  downVote?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  subjectId?: InputMaybe<StringFilter>;
  upVote?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AnswerScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AnswerScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AnswerScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AnswerScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  downVote?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  subjectId?: InputMaybe<StringWithAggregatesFilter>;
  upVote?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type AnswerSumAggregate = {
  __typename?: 'AnswerSumAggregate';
  downVote?: Maybe<Scalars['Int']>;
  upVote?: Maybe<Scalars['Int']>;
};

export type AnswerSumOrderByAggregateInput = {
  downVote?: InputMaybe<SortOrder>;
  upVote?: InputMaybe<SortOrder>;
};

export type AnswerUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  downVote?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  subject?: InputMaybe<SubjectUpdateOneRequiredWithoutAnswersInput>;
  upVote?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAnswersInput>;
};

export type AnswerUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  downVote?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  upVote?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AnswerUpdateManyWithWhereWithoutSubjectInput = {
  data: AnswerUpdateManyMutationInput;
  where: AnswerScalarWhereInput;
};

export type AnswerUpdateManyWithWhereWithoutUserInput = {
  data: AnswerUpdateManyMutationInput;
  where: AnswerScalarWhereInput;
};

export type AnswerUpdateManyWithoutSubjectInput = {
  connect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerCreateOrConnectWithoutSubjectInput>>;
  create?: InputMaybe<Array<AnswerCreateWithoutSubjectInput>>;
  createMany?: InputMaybe<AnswerCreateManySubjectInputEnvelope>;
  delete?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AnswerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  set?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  update?: InputMaybe<Array<AnswerUpdateWithWhereUniqueWithoutSubjectInput>>;
  updateMany?: InputMaybe<Array<AnswerUpdateManyWithWhereWithoutSubjectInput>>;
  upsert?: InputMaybe<Array<AnswerUpsertWithWhereUniqueWithoutSubjectInput>>;
};

export type AnswerUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AnswerCreateWithoutUserInput>>;
  createMany?: InputMaybe<AnswerCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AnswerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  set?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  update?: InputMaybe<Array<AnswerUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<AnswerUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<AnswerUpsertWithWhereUniqueWithoutUserInput>>;
};

export type AnswerUpdateWithWhereUniqueWithoutSubjectInput = {
  data: AnswerUpdateWithoutSubjectInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerUpdateWithWhereUniqueWithoutUserInput = {
  data: AnswerUpdateWithoutUserInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerUpdateWithoutSubjectInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  downVote?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  upVote?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAnswersInput>;
};

export type AnswerUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  downVote?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  subject?: InputMaybe<SubjectUpdateOneRequiredWithoutAnswersInput>;
  upVote?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AnswerUpsertWithWhereUniqueWithoutSubjectInput = {
  create: AnswerCreateWithoutSubjectInput;
  update: AnswerUpdateWithoutSubjectInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerUpsertWithWhereUniqueWithoutUserInput = {
  create: AnswerCreateWithoutUserInput;
  update: AnswerUpdateWithoutUserInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerWhereInput = {
  AND?: InputMaybe<Array<AnswerWhereInput>>;
  NOT?: InputMaybe<Array<AnswerWhereInput>>;
  OR?: InputMaybe<Array<AnswerWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  downVote?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  subject?: InputMaybe<SubjectRelationFilter>;
  subjectId?: InputMaybe<StringFilter>;
  upVote?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AnswerWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type Category = {
  __typename?: 'Category';
  _count?: Maybe<CategoryCount>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  lessons: Array<Lesson>;
  order: Scalars['Int'];
  parent?: Maybe<Category>;
  parentId?: Maybe<Scalars['String']>;
  photos: Array<Scalars['String']>;
  questions: Array<Question>;
  status: Scalars['Boolean'];
  subCategories: Array<Category>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CategoryDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type CategoryLessonsArgs = {
  cursor?: InputMaybe<LessonWhereUniqueInput>;
  distinct?: InputMaybe<Array<LessonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LessonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LessonWhereInput>;
};


export type CategoryQuestionsArgs = {
  cursor?: InputMaybe<QuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionWhereInput>;
};


export type CategorySubCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryAvgAggregate = {
  __typename?: 'CategoryAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type CategoryAvgOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type CategoryCount = {
  __typename?: 'CategoryCount';
  descriptions: Scalars['Int'];
  lessons: Scalars['Int'];
  questions: Scalars['Int'];
  subCategories: Scalars['Int'];
};

export type CategoryCountAggregate = {
  __typename?: 'CategoryCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  parentId: Scalars['Int'];
  photos: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CategoryCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  lessons?: InputMaybe<LessonCreateNestedManyWithoutCategoryInput>;
  order?: InputMaybe<Scalars['Int']>;
  parent?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  questions?: InputMaybe<QuestionCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryCreateManyParentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryCreateManyParentInputEnvelope = {
  data: Array<CategoryCreateManyParentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CategoryCreateNestedManyWithoutParentInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutParentInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutParentInput>>;
  createMany?: InputMaybe<CategoryCreateManyParentInputEnvelope>;
};

export type CategoryCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<CategoryCreateWithoutDescriptionsInput>;
};

export type CategoryCreateNestedOneWithoutLessonsInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutLessonsInput>;
  create?: InputMaybe<CategoryCreateWithoutLessonsInput>;
};

export type CategoryCreateNestedOneWithoutQuestionsInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<CategoryCreateWithoutQuestionsInput>;
};

export type CategoryCreateNestedOneWithoutSubCategoriesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutSubCategoriesInput>;
  create?: InputMaybe<CategoryCreateWithoutSubCategoriesInput>;
};

export type CategoryCreateOrConnectWithoutDescriptionsInput = {
  create: CategoryCreateWithoutDescriptionsInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutLessonsInput = {
  create: CategoryCreateWithoutLessonsInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutParentInput = {
  create: CategoryCreateWithoutParentInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutQuestionsInput = {
  create: CategoryCreateWithoutQuestionsInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutSubCategoriesInput = {
  create: CategoryCreateWithoutSubCategoriesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateWithoutDescriptionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  lessons?: InputMaybe<LessonCreateNestedManyWithoutCategoryInput>;
  order?: InputMaybe<Scalars['Int']>;
  parent?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  questions?: InputMaybe<QuestionCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryCreateWithoutLessonsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parent?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  questions?: InputMaybe<QuestionCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryCreateWithoutParentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  lessons?: InputMaybe<LessonCreateNestedManyWithoutCategoryInput>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  questions?: InputMaybe<QuestionCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryCreateWithoutQuestionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  lessons?: InputMaybe<LessonCreateNestedManyWithoutCategoryInput>;
  order?: InputMaybe<Scalars['Int']>;
  parent?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryCreateWithoutSubCategoriesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  lessons?: InputMaybe<LessonCreateNestedManyWithoutCategoryInput>;
  order?: InputMaybe<Scalars['Int']>;
  parent?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  questions?: InputMaybe<QuestionCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryCreatephotosInput = {
  set: Array<Scalars['String']>;
};

export type CategoryGroupBy = {
  __typename?: 'CategoryGroupBy';
  _avg?: Maybe<CategoryAvgAggregate>;
  _count?: Maybe<CategoryCountAggregate>;
  _max?: Maybe<CategoryMaxAggregate>;
  _min?: Maybe<CategoryMinAggregate>;
  _sum?: Maybe<CategorySumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Scalars['Int'];
  parentId?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Scalars['String']>>;
  status: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryListRelationFilter = {
  every?: InputMaybe<CategoryWhereInput>;
  none?: InputMaybe<CategoryWhereInput>;
  some?: InputMaybe<CategoryWhereInput>;
};

export type CategoryMaxAggregate = {
  __typename?: 'CategoryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryMinAggregate = {
  __typename?: 'CategoryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum CategoryOrderByRelevanceFieldEnum {
  id = 'id',
  parentId = 'parentId',
  photos = 'photos'
}

export type CategoryOrderByRelevanceInput = {
  fields: Array<CategoryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CategoryOrderByWithAggregationInput = {
  _avg?: InputMaybe<CategoryAvgOrderByAggregateInput>;
  _count?: InputMaybe<CategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<CategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<CategoryMinOrderByAggregateInput>;
  _sum?: InputMaybe<CategorySumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<CategoryOrderByRelevanceInput>;
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  lessons?: InputMaybe<LessonOrderByRelationAggregateInput>;
  order?: InputMaybe<SortOrder>;
  parent?: InputMaybe<CategoryOrderByWithRelationAndSearchRelevanceInput>;
  parentId?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  questions?: InputMaybe<QuestionOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  subCategories?: InputMaybe<CategoryOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryRelationFilter = {
  is?: InputMaybe<CategoryWhereInput>;
  isNot?: InputMaybe<CategoryWhereInput>;
};

export enum CategoryScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  order = 'order',
  parentId = 'parentId',
  photos = 'photos',
  status = 'status',
  updatedAt = 'updatedAt'
}

export type CategoryScalarWhereInput = {
  AND?: InputMaybe<Array<CategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<CategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<CategoryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  parentId?: InputMaybe<StringNullableFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  status?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type CategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  parentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type CategorySumAggregate = {
  __typename?: 'CategorySumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type CategorySumOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type CategoryUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCategoryInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lessons?: InputMaybe<LessonUpdateManyWithoutCategoryInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parent?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  questions?: InputMaybe<QuestionUpdateManyWithoutCategoryInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateManyWithWhereWithoutParentInput = {
  data: CategoryUpdateManyMutationInput;
  where: CategoryScalarWhereInput;
};

export type CategoryUpdateManyWithoutParentInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutParentInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutParentInput>>;
  createMany?: InputMaybe<CategoryCreateManyParentInputEnvelope>;
  delete?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: InputMaybe<Array<CategoryUpdateManyWithWhereWithoutParentInput>>;
  upsert?: InputMaybe<Array<CategoryUpsertWithWhereUniqueWithoutParentInput>>;
};

export type CategoryUpdateOneRequiredWithoutLessonsInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutLessonsInput>;
  create?: InputMaybe<CategoryCreateWithoutLessonsInput>;
  update?: InputMaybe<CategoryUpdateWithoutLessonsInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutLessonsInput>;
};

export type CategoryUpdateOneRequiredWithoutQuestionsInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<CategoryCreateWithoutQuestionsInput>;
  update?: InputMaybe<CategoryUpdateWithoutQuestionsInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutQuestionsInput>;
};

export type CategoryUpdateOneWithoutDescriptionsInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<CategoryCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CategoryUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutDescriptionsInput>;
};

export type CategoryUpdateOneWithoutSubCategoriesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutSubCategoriesInput>;
  create?: InputMaybe<CategoryCreateWithoutSubCategoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CategoryUpdateWithoutSubCategoriesInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutSubCategoriesInput>;
};

export type CategoryUpdateWithWhereUniqueWithoutParentInput = {
  data: CategoryUpdateWithoutParentInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpdateWithoutDescriptionsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lessons?: InputMaybe<LessonUpdateManyWithoutCategoryInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parent?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  questions?: InputMaybe<QuestionUpdateManyWithoutCategoryInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutLessonsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCategoryInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parent?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  questions?: InputMaybe<QuestionUpdateManyWithoutCategoryInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutParentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCategoryInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lessons?: InputMaybe<LessonUpdateManyWithoutCategoryInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  questions?: InputMaybe<QuestionUpdateManyWithoutCategoryInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutQuestionsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCategoryInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lessons?: InputMaybe<LessonUpdateManyWithoutCategoryInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parent?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutSubCategoriesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCategoryInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lessons?: InputMaybe<LessonUpdateManyWithoutCategoryInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parent?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  questions?: InputMaybe<QuestionUpdateManyWithoutCategoryInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdatephotosInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CategoryUpsertWithWhereUniqueWithoutParentInput = {
  create: CategoryCreateWithoutParentInput;
  update: CategoryUpdateWithoutParentInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpsertWithoutDescriptionsInput = {
  create: CategoryCreateWithoutDescriptionsInput;
  update: CategoryUpdateWithoutDescriptionsInput;
};

export type CategoryUpsertWithoutLessonsInput = {
  create: CategoryCreateWithoutLessonsInput;
  update: CategoryUpdateWithoutLessonsInput;
};

export type CategoryUpsertWithoutQuestionsInput = {
  create: CategoryCreateWithoutQuestionsInput;
  update: CategoryUpdateWithoutQuestionsInput;
};

export type CategoryUpsertWithoutSubCategoriesInput = {
  create: CategoryCreateWithoutSubCategoriesInput;
  update: CategoryUpdateWithoutSubCategoriesInput;
};

export type CategoryWhereInput = {
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  lessons?: InputMaybe<LessonListRelationFilter>;
  order?: InputMaybe<IntFilter>;
  parent?: InputMaybe<CategoryRelationFilter>;
  parentId?: InputMaybe<StringNullableFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  questions?: InputMaybe<QuestionListRelationFilter>;
  status?: InputMaybe<BoolFilter>;
  subCategories?: InputMaybe<CategoryListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type CategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Choice = {
  __typename?: 'Choice';
  _count?: Maybe<ChoiceCount>;
  answers: Array<QuestionAnswer>;
  createdAt: Scalars['DateTime'];
  credit: Scalars['Int'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  photos: Array<Scalars['String']>;
  question: Question;
  questionId: Scalars['String'];
  transactions: Array<Transaction>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ChoiceAnswersArgs = {
  cursor?: InputMaybe<QuestionAnswerWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestionAnswerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestionAnswerOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionAnswerWhereInput>;
};


export type ChoiceDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type ChoiceTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type ChoiceAvgAggregate = {
  __typename?: 'ChoiceAvgAggregate';
  credit?: Maybe<Scalars['Float']>;
};

export type ChoiceAvgOrderByAggregateInput = {
  credit?: InputMaybe<SortOrder>;
};

export type ChoiceCount = {
  __typename?: 'ChoiceCount';
  answers: Scalars['Int'];
  descriptions: Scalars['Int'];
  transactions: Scalars['Int'];
};

export type ChoiceCountAggregate = {
  __typename?: 'ChoiceCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  credit: Scalars['Int'];
  id: Scalars['Int'];
  isCorrect: Scalars['Int'];
  photos: Scalars['Int'];
  questionId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ChoiceCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  credit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCorrect?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ChoiceCreateInput = {
  answers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutChoiceInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutChoiceInput>;
  id?: InputMaybe<Scalars['String']>;
  isCorrect: Scalars['Boolean'];
  photos?: InputMaybe<ChoiceCreatephotosInput>;
  question: QuestionCreateNestedOneWithoutChoicesInput;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutChoiceInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChoiceCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isCorrect: Scalars['Boolean'];
  photos?: InputMaybe<ChoiceCreatephotosInput>;
  questionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChoiceCreateManyQuestionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isCorrect: Scalars['Boolean'];
  photos?: InputMaybe<ChoiceCreatephotosInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChoiceCreateManyQuestionInputEnvelope = {
  data: Array<ChoiceCreateManyQuestionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ChoiceCreateNestedManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<ChoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChoiceCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<ChoiceCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<ChoiceCreateManyQuestionInputEnvelope>;
};

export type ChoiceCreateNestedOneWithoutAnswersInput = {
  connect?: InputMaybe<ChoiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChoiceCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<ChoiceCreateWithoutAnswersInput>;
};

export type ChoiceCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<ChoiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChoiceCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<ChoiceCreateWithoutDescriptionsInput>;
};

export type ChoiceCreateNestedOneWithoutTransactionsInput = {
  connect?: InputMaybe<ChoiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChoiceCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<ChoiceCreateWithoutTransactionsInput>;
};

export type ChoiceCreateOrConnectWithoutAnswersInput = {
  create: ChoiceCreateWithoutAnswersInput;
  where: ChoiceWhereUniqueInput;
};

export type ChoiceCreateOrConnectWithoutDescriptionsInput = {
  create: ChoiceCreateWithoutDescriptionsInput;
  where: ChoiceWhereUniqueInput;
};

export type ChoiceCreateOrConnectWithoutQuestionInput = {
  create: ChoiceCreateWithoutQuestionInput;
  where: ChoiceWhereUniqueInput;
};

export type ChoiceCreateOrConnectWithoutTransactionsInput = {
  create: ChoiceCreateWithoutTransactionsInput;
  where: ChoiceWhereUniqueInput;
};

export type ChoiceCreateWithoutAnswersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutChoiceInput>;
  id?: InputMaybe<Scalars['String']>;
  isCorrect: Scalars['Boolean'];
  photos?: InputMaybe<ChoiceCreatephotosInput>;
  question: QuestionCreateNestedOneWithoutChoicesInput;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutChoiceInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChoiceCreateWithoutDescriptionsInput = {
  answers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutChoiceInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isCorrect: Scalars['Boolean'];
  photos?: InputMaybe<ChoiceCreatephotosInput>;
  question: QuestionCreateNestedOneWithoutChoicesInput;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutChoiceInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChoiceCreateWithoutQuestionInput = {
  answers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutChoiceInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutChoiceInput>;
  id?: InputMaybe<Scalars['String']>;
  isCorrect: Scalars['Boolean'];
  photos?: InputMaybe<ChoiceCreatephotosInput>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutChoiceInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChoiceCreateWithoutTransactionsInput = {
  answers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutChoiceInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['Int']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutChoiceInput>;
  id?: InputMaybe<Scalars['String']>;
  isCorrect: Scalars['Boolean'];
  photos?: InputMaybe<ChoiceCreatephotosInput>;
  question: QuestionCreateNestedOneWithoutChoicesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChoiceCreatephotosInput = {
  set: Array<Scalars['String']>;
};

export type ChoiceGroupBy = {
  __typename?: 'ChoiceGroupBy';
  _avg?: Maybe<ChoiceAvgAggregate>;
  _count?: Maybe<ChoiceCountAggregate>;
  _max?: Maybe<ChoiceMaxAggregate>;
  _min?: Maybe<ChoiceMinAggregate>;
  _sum?: Maybe<ChoiceSumAggregate>;
  createdAt: Scalars['DateTime'];
  credit: Scalars['Int'];
  id: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  photos?: Maybe<Array<Scalars['String']>>;
  questionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChoiceListRelationFilter = {
  every?: InputMaybe<ChoiceWhereInput>;
  none?: InputMaybe<ChoiceWhereInput>;
  some?: InputMaybe<ChoiceWhereInput>;
};

export type ChoiceMaxAggregate = {
  __typename?: 'ChoiceMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  credit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  questionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChoiceMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  credit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCorrect?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ChoiceMinAggregate = {
  __typename?: 'ChoiceMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  credit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  questionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChoiceMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  credit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCorrect?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ChoiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ChoiceOrderByRelevanceFieldEnum {
  id = 'id',
  photos = 'photos',
  questionId = 'questionId'
}

export type ChoiceOrderByRelevanceInput = {
  fields: Array<ChoiceOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChoiceOrderByWithAggregationInput = {
  _avg?: InputMaybe<ChoiceAvgOrderByAggregateInput>;
  _count?: InputMaybe<ChoiceCountOrderByAggregateInput>;
  _max?: InputMaybe<ChoiceMaxOrderByAggregateInput>;
  _min?: InputMaybe<ChoiceMinOrderByAggregateInput>;
  _sum?: InputMaybe<ChoiceSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  credit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCorrect?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ChoiceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ChoiceOrderByRelevanceInput>;
  answers?: InputMaybe<QuestionAnswerOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  credit?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isCorrect?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByWithRelationAndSearchRelevanceInput>;
  questionId?: InputMaybe<SortOrder>;
  transactions?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ChoiceRelationFilter = {
  is?: InputMaybe<ChoiceWhereInput>;
  isNot?: InputMaybe<ChoiceWhereInput>;
};

export enum ChoiceScalarFieldEnum {
  createdAt = 'createdAt',
  credit = 'credit',
  id = 'id',
  isCorrect = 'isCorrect',
  photos = 'photos',
  questionId = 'questionId',
  updatedAt = 'updatedAt'
}

export type ChoiceScalarWhereInput = {
  AND?: InputMaybe<Array<ChoiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<ChoiceScalarWhereInput>>;
  OR?: InputMaybe<Array<ChoiceScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credit?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  isCorrect?: InputMaybe<BoolFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  questionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ChoiceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ChoiceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ChoiceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ChoiceScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  credit?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isCorrect?: InputMaybe<BoolWithAggregatesFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  questionId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type ChoiceSumAggregate = {
  __typename?: 'ChoiceSumAggregate';
  credit?: Maybe<Scalars['Int']>;
};

export type ChoiceSumOrderByAggregateInput = {
  credit?: InputMaybe<SortOrder>;
};

export type ChoiceUpdateInput = {
  answers?: InputMaybe<QuestionAnswerUpdateManyWithoutChoiceInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credit?: InputMaybe<IntFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutChoiceInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCorrect?: InputMaybe<BoolFieldUpdateOperationsInput>;
  photos?: InputMaybe<ChoiceUpdatephotosInput>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutChoicesInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutChoiceInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ChoiceUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credit?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCorrect?: InputMaybe<BoolFieldUpdateOperationsInput>;
  photos?: InputMaybe<ChoiceUpdatephotosInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ChoiceUpdateManyWithWhereWithoutQuestionInput = {
  data: ChoiceUpdateManyMutationInput;
  where: ChoiceScalarWhereInput;
};

export type ChoiceUpdateManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<ChoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChoiceCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<ChoiceCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<ChoiceCreateManyQuestionInputEnvelope>;
  delete?: InputMaybe<Array<ChoiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ChoiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ChoiceWhereUniqueInput>>;
  set?: InputMaybe<Array<ChoiceWhereUniqueInput>>;
  update?: InputMaybe<Array<ChoiceUpdateWithWhereUniqueWithoutQuestionInput>>;
  updateMany?: InputMaybe<Array<ChoiceUpdateManyWithWhereWithoutQuestionInput>>;
  upsert?: InputMaybe<Array<ChoiceUpsertWithWhereUniqueWithoutQuestionInput>>;
};

export type ChoiceUpdateOneRequiredWithoutAnswersInput = {
  connect?: InputMaybe<ChoiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChoiceCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<ChoiceCreateWithoutAnswersInput>;
  update?: InputMaybe<ChoiceUpdateWithoutAnswersInput>;
  upsert?: InputMaybe<ChoiceUpsertWithoutAnswersInput>;
};

export type ChoiceUpdateOneWithoutDescriptionsInput = {
  connect?: InputMaybe<ChoiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChoiceCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<ChoiceCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ChoiceUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<ChoiceUpsertWithoutDescriptionsInput>;
};

export type ChoiceUpdateOneWithoutTransactionsInput = {
  connect?: InputMaybe<ChoiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChoiceCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<ChoiceCreateWithoutTransactionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ChoiceUpdateWithoutTransactionsInput>;
  upsert?: InputMaybe<ChoiceUpsertWithoutTransactionsInput>;
};

export type ChoiceUpdateWithWhereUniqueWithoutQuestionInput = {
  data: ChoiceUpdateWithoutQuestionInput;
  where: ChoiceWhereUniqueInput;
};

export type ChoiceUpdateWithoutAnswersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credit?: InputMaybe<IntFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutChoiceInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCorrect?: InputMaybe<BoolFieldUpdateOperationsInput>;
  photos?: InputMaybe<ChoiceUpdatephotosInput>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutChoicesInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutChoiceInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ChoiceUpdateWithoutDescriptionsInput = {
  answers?: InputMaybe<QuestionAnswerUpdateManyWithoutChoiceInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credit?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCorrect?: InputMaybe<BoolFieldUpdateOperationsInput>;
  photos?: InputMaybe<ChoiceUpdatephotosInput>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutChoicesInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutChoiceInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ChoiceUpdateWithoutQuestionInput = {
  answers?: InputMaybe<QuestionAnswerUpdateManyWithoutChoiceInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credit?: InputMaybe<IntFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutChoiceInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCorrect?: InputMaybe<BoolFieldUpdateOperationsInput>;
  photos?: InputMaybe<ChoiceUpdatephotosInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutChoiceInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ChoiceUpdateWithoutTransactionsInput = {
  answers?: InputMaybe<QuestionAnswerUpdateManyWithoutChoiceInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credit?: InputMaybe<IntFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutChoiceInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCorrect?: InputMaybe<BoolFieldUpdateOperationsInput>;
  photos?: InputMaybe<ChoiceUpdatephotosInput>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutChoicesInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ChoiceUpdatephotosInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ChoiceUpsertWithWhereUniqueWithoutQuestionInput = {
  create: ChoiceCreateWithoutQuestionInput;
  update: ChoiceUpdateWithoutQuestionInput;
  where: ChoiceWhereUniqueInput;
};

export type ChoiceUpsertWithoutAnswersInput = {
  create: ChoiceCreateWithoutAnswersInput;
  update: ChoiceUpdateWithoutAnswersInput;
};

export type ChoiceUpsertWithoutDescriptionsInput = {
  create: ChoiceCreateWithoutDescriptionsInput;
  update: ChoiceUpdateWithoutDescriptionsInput;
};

export type ChoiceUpsertWithoutTransactionsInput = {
  create: ChoiceCreateWithoutTransactionsInput;
  update: ChoiceUpdateWithoutTransactionsInput;
};

export type ChoiceWhereInput = {
  AND?: InputMaybe<Array<ChoiceWhereInput>>;
  NOT?: InputMaybe<Array<ChoiceWhereInput>>;
  OR?: InputMaybe<Array<ChoiceWhereInput>>;
  answers?: InputMaybe<QuestionAnswerListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credit?: InputMaybe<IntFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isCorrect?: InputMaybe<BoolFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  question?: InputMaybe<QuestionRelationFilter>;
  questionId?: InputMaybe<StringFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ChoiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CompletedLesson = {
  __typename?: 'CompletedLesson';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  lesson: Lesson;
  lessonId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type CompletedLessonCountAggregate = {
  __typename?: 'CompletedLessonCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  lessonId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type CompletedLessonCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CompletedLessonCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  lesson: LessonCreateNestedOneWithoutCompletedLessonInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCompletedLessonsInput;
};

export type CompletedLessonCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  lessonId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type CompletedLessonCreateManyLessonInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type CompletedLessonCreateManyLessonInputEnvelope = {
  data: Array<CompletedLessonCreateManyLessonInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CompletedLessonCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  lessonId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompletedLessonCreateManyUserInputEnvelope = {
  data: Array<CompletedLessonCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CompletedLessonCreateNestedManyWithoutLessonInput = {
  connect?: InputMaybe<Array<CompletedLessonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompletedLessonCreateOrConnectWithoutLessonInput>>;
  create?: InputMaybe<Array<CompletedLessonCreateWithoutLessonInput>>;
  createMany?: InputMaybe<CompletedLessonCreateManyLessonInputEnvelope>;
};

export type CompletedLessonCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CompletedLessonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompletedLessonCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CompletedLessonCreateWithoutUserInput>>;
  createMany?: InputMaybe<CompletedLessonCreateManyUserInputEnvelope>;
};

export type CompletedLessonCreateOrConnectWithoutLessonInput = {
  create: CompletedLessonCreateWithoutLessonInput;
  where: CompletedLessonWhereUniqueInput;
};

export type CompletedLessonCreateOrConnectWithoutUserInput = {
  create: CompletedLessonCreateWithoutUserInput;
  where: CompletedLessonWhereUniqueInput;
};

export type CompletedLessonCreateWithoutLessonInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCompletedLessonsInput;
};

export type CompletedLessonCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  lesson: LessonCreateNestedOneWithoutCompletedLessonInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompletedLessonGroupBy = {
  __typename?: 'CompletedLessonGroupBy';
  _count?: Maybe<CompletedLessonCountAggregate>;
  _max?: Maybe<CompletedLessonMaxAggregate>;
  _min?: Maybe<CompletedLessonMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  lessonId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type CompletedLessonListRelationFilter = {
  every?: InputMaybe<CompletedLessonWhereInput>;
  none?: InputMaybe<CompletedLessonWhereInput>;
  some?: InputMaybe<CompletedLessonWhereInput>;
};

export type CompletedLessonMaxAggregate = {
  __typename?: 'CompletedLessonMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lessonId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CompletedLessonMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CompletedLessonMinAggregate = {
  __typename?: 'CompletedLessonMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lessonId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CompletedLessonMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CompletedLessonOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum CompletedLessonOrderByRelevanceFieldEnum {
  id = 'id',
  lessonId = 'lessonId',
  userId = 'userId'
}

export type CompletedLessonOrderByRelevanceInput = {
  fields: Array<CompletedLessonOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CompletedLessonOrderByWithAggregationInput = {
  _count?: InputMaybe<CompletedLessonCountOrderByAggregateInput>;
  _max?: InputMaybe<CompletedLessonMaxOrderByAggregateInput>;
  _min?: InputMaybe<CompletedLessonMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CompletedLessonOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<CompletedLessonOrderByRelevanceInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lesson?: InputMaybe<LessonOrderByWithRelationAndSearchRelevanceInput>;
  lessonId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum CompletedLessonScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  lessonId = 'lessonId',
  updatedAt = 'updatedAt',
  userId = 'userId'
}

export type CompletedLessonScalarWhereInput = {
  AND?: InputMaybe<Array<CompletedLessonScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompletedLessonScalarWhereInput>>;
  OR?: InputMaybe<Array<CompletedLessonScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lessonId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CompletedLessonScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CompletedLessonScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CompletedLessonScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CompletedLessonScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lessonId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type CompletedLessonUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lesson?: InputMaybe<LessonUpdateOneRequiredWithoutCompletedLessonInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCompletedLessonsInput>;
};

export type CompletedLessonUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompletedLessonUpdateManyWithWhereWithoutLessonInput = {
  data: CompletedLessonUpdateManyMutationInput;
  where: CompletedLessonScalarWhereInput;
};

export type CompletedLessonUpdateManyWithWhereWithoutUserInput = {
  data: CompletedLessonUpdateManyMutationInput;
  where: CompletedLessonScalarWhereInput;
};

export type CompletedLessonUpdateManyWithoutLessonInput = {
  connect?: InputMaybe<Array<CompletedLessonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompletedLessonCreateOrConnectWithoutLessonInput>>;
  create?: InputMaybe<Array<CompletedLessonCreateWithoutLessonInput>>;
  createMany?: InputMaybe<CompletedLessonCreateManyLessonInputEnvelope>;
  delete?: InputMaybe<Array<CompletedLessonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompletedLessonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompletedLessonWhereUniqueInput>>;
  set?: InputMaybe<Array<CompletedLessonWhereUniqueInput>>;
  update?: InputMaybe<Array<CompletedLessonUpdateWithWhereUniqueWithoutLessonInput>>;
  updateMany?: InputMaybe<Array<CompletedLessonUpdateManyWithWhereWithoutLessonInput>>;
  upsert?: InputMaybe<Array<CompletedLessonUpsertWithWhereUniqueWithoutLessonInput>>;
};

export type CompletedLessonUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<CompletedLessonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompletedLessonCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CompletedLessonCreateWithoutUserInput>>;
  createMany?: InputMaybe<CompletedLessonCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CompletedLessonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompletedLessonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompletedLessonWhereUniqueInput>>;
  set?: InputMaybe<Array<CompletedLessonWhereUniqueInput>>;
  update?: InputMaybe<Array<CompletedLessonUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CompletedLessonUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CompletedLessonUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CompletedLessonUpdateWithWhereUniqueWithoutLessonInput = {
  data: CompletedLessonUpdateWithoutLessonInput;
  where: CompletedLessonWhereUniqueInput;
};

export type CompletedLessonUpdateWithWhereUniqueWithoutUserInput = {
  data: CompletedLessonUpdateWithoutUserInput;
  where: CompletedLessonWhereUniqueInput;
};

export type CompletedLessonUpdateWithoutLessonInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCompletedLessonsInput>;
};

export type CompletedLessonUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lesson?: InputMaybe<LessonUpdateOneRequiredWithoutCompletedLessonInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompletedLessonUpsertWithWhereUniqueWithoutLessonInput = {
  create: CompletedLessonCreateWithoutLessonInput;
  update: CompletedLessonUpdateWithoutLessonInput;
  where: CompletedLessonWhereUniqueInput;
};

export type CompletedLessonUpsertWithWhereUniqueWithoutUserInput = {
  create: CompletedLessonCreateWithoutUserInput;
  update: CompletedLessonUpdateWithoutUserInput;
  where: CompletedLessonWhereUniqueInput;
};

export type CompletedLessonWhereInput = {
  AND?: InputMaybe<Array<CompletedLessonWhereInput>>;
  NOT?: InputMaybe<Array<CompletedLessonWhereInput>>;
  OR?: InputMaybe<Array<CompletedLessonWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lesson?: InputMaybe<LessonRelationFilter>;
  lessonId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CompletedLessonWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Content = {
  __typename?: 'Content';
  Lesson?: Maybe<Lesson>;
  _count?: Maybe<ContentCount>;
  audio?: Maybe<Scalars['String']>;
  contentType: ContentType;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  files: Array<Scalars['String']>;
  id: Scalars['String'];
  lessonId?: Maybe<Scalars['String']>;
  mediaType: MediaType;
  news?: Maybe<News>;
  newsId?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  page?: Maybe<Page>;
  pageId?: Maybe<Scalars['String']>;
  photos: Array<Scalars['String']>;
  question?: Maybe<Question>;
  questionId?: Maybe<Scalars['String']>;
  showOnProposal: Scalars['Boolean'];
  showOnWeb: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  video?: Maybe<Scalars['String']>;
};


export type ContentDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};

export type ContentAvgAggregate = {
  __typename?: 'ContentAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type ContentAvgOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type ContentCount = {
  __typename?: 'ContentCount';
  descriptions: Scalars['Int'];
};

export type ContentCountAggregate = {
  __typename?: 'ContentCountAggregate';
  _all: Scalars['Int'];
  audio: Scalars['Int'];
  contentType: Scalars['Int'];
  createdAt: Scalars['Int'];
  files: Scalars['Int'];
  id: Scalars['Int'];
  lessonId: Scalars['Int'];
  mediaType: Scalars['Int'];
  newsId: Scalars['Int'];
  order: Scalars['Int'];
  pageId: Scalars['Int'];
  photos: Scalars['Int'];
  questionId: Scalars['Int'];
  showOnProposal: Scalars['Int'];
  showOnWeb: Scalars['Int'];
  updatedAt: Scalars['Int'];
  video: Scalars['Int'];
};

export type ContentCountOrderByAggregateInput = {
  audio?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  files?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  mediaType?: InputMaybe<SortOrder>;
  newsId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  showOnProposal?: InputMaybe<SortOrder>;
  showOnWeb?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrder>;
};

export type ContentCreateInput = {
  Lesson?: InputMaybe<LessonCreateNestedOneWithoutContentsInput>;
  audio?: InputMaybe<Scalars['String']>;
  contentType: ContentType;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutContentInput>;
  files?: InputMaybe<ContentCreatefilesInput>;
  id?: InputMaybe<Scalars['String']>;
  mediaType: MediaType;
  news?: InputMaybe<NewsCreateNestedOneWithoutContentsInput>;
  order?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<PageCreateNestedOneWithoutContentsInput>;
  photos?: InputMaybe<ContentCreatephotosInput>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutContentInput>;
  showOnProposal?: InputMaybe<Scalars['Boolean']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video?: InputMaybe<Scalars['String']>;
};

export type ContentCreateManyInput = {
  audio?: InputMaybe<Scalars['String']>;
  contentType: ContentType;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  files?: InputMaybe<ContentCreatefilesInput>;
  id?: InputMaybe<Scalars['String']>;
  lessonId?: InputMaybe<Scalars['String']>;
  mediaType: MediaType;
  newsId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageId?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<ContentCreatephotosInput>;
  questionId?: InputMaybe<Scalars['String']>;
  showOnProposal?: InputMaybe<Scalars['Boolean']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video?: InputMaybe<Scalars['String']>;
};

export type ContentCreateManyLessonInput = {
  audio?: InputMaybe<Scalars['String']>;
  contentType: ContentType;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  files?: InputMaybe<ContentCreatefilesInput>;
  id?: InputMaybe<Scalars['String']>;
  mediaType: MediaType;
  newsId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageId?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<ContentCreatephotosInput>;
  questionId?: InputMaybe<Scalars['String']>;
  showOnProposal?: InputMaybe<Scalars['Boolean']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video?: InputMaybe<Scalars['String']>;
};

export type ContentCreateManyLessonInputEnvelope = {
  data: Array<ContentCreateManyLessonInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ContentCreateManyNewsInput = {
  audio?: InputMaybe<Scalars['String']>;
  contentType: ContentType;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  files?: InputMaybe<ContentCreatefilesInput>;
  id?: InputMaybe<Scalars['String']>;
  lessonId?: InputMaybe<Scalars['String']>;
  mediaType: MediaType;
  order?: InputMaybe<Scalars['Int']>;
  pageId?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<ContentCreatephotosInput>;
  questionId?: InputMaybe<Scalars['String']>;
  showOnProposal?: InputMaybe<Scalars['Boolean']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video?: InputMaybe<Scalars['String']>;
};

export type ContentCreateManyNewsInputEnvelope = {
  data: Array<ContentCreateManyNewsInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ContentCreateManyPageInput = {
  audio?: InputMaybe<Scalars['String']>;
  contentType: ContentType;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  files?: InputMaybe<ContentCreatefilesInput>;
  id?: InputMaybe<Scalars['String']>;
  lessonId?: InputMaybe<Scalars['String']>;
  mediaType: MediaType;
  newsId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ContentCreatephotosInput>;
  questionId?: InputMaybe<Scalars['String']>;
  showOnProposal?: InputMaybe<Scalars['Boolean']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video?: InputMaybe<Scalars['String']>;
};

export type ContentCreateManyPageInputEnvelope = {
  data: Array<ContentCreateManyPageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ContentCreateManyQuestionInput = {
  audio?: InputMaybe<Scalars['String']>;
  contentType: ContentType;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  files?: InputMaybe<ContentCreatefilesInput>;
  id?: InputMaybe<Scalars['String']>;
  lessonId?: InputMaybe<Scalars['String']>;
  mediaType: MediaType;
  newsId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageId?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<ContentCreatephotosInput>;
  showOnProposal?: InputMaybe<Scalars['Boolean']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video?: InputMaybe<Scalars['String']>;
};

export type ContentCreateManyQuestionInputEnvelope = {
  data: Array<ContentCreateManyQuestionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ContentCreateNestedManyWithoutLessonInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutLessonInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutLessonInput>>;
  createMany?: InputMaybe<ContentCreateManyLessonInputEnvelope>;
};

export type ContentCreateNestedManyWithoutNewsInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutNewsInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutNewsInput>>;
  createMany?: InputMaybe<ContentCreateManyNewsInputEnvelope>;
};

export type ContentCreateNestedManyWithoutPageInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutPageInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutPageInput>>;
  createMany?: InputMaybe<ContentCreateManyPageInputEnvelope>;
};

export type ContentCreateNestedManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<ContentCreateManyQuestionInputEnvelope>;
};

export type ContentCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<ContentCreateWithoutDescriptionsInput>;
};

export type ContentCreateOrConnectWithoutDescriptionsInput = {
  create: ContentCreateWithoutDescriptionsInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateOrConnectWithoutLessonInput = {
  create: ContentCreateWithoutLessonInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateOrConnectWithoutNewsInput = {
  create: ContentCreateWithoutNewsInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateOrConnectWithoutPageInput = {
  create: ContentCreateWithoutPageInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateOrConnectWithoutQuestionInput = {
  create: ContentCreateWithoutQuestionInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateWithoutDescriptionsInput = {
  Lesson?: InputMaybe<LessonCreateNestedOneWithoutContentsInput>;
  audio?: InputMaybe<Scalars['String']>;
  contentType: ContentType;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  files?: InputMaybe<ContentCreatefilesInput>;
  id?: InputMaybe<Scalars['String']>;
  mediaType: MediaType;
  news?: InputMaybe<NewsCreateNestedOneWithoutContentsInput>;
  order?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<PageCreateNestedOneWithoutContentsInput>;
  photos?: InputMaybe<ContentCreatephotosInput>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutContentInput>;
  showOnProposal?: InputMaybe<Scalars['Boolean']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video?: InputMaybe<Scalars['String']>;
};

export type ContentCreateWithoutLessonInput = {
  audio?: InputMaybe<Scalars['String']>;
  contentType: ContentType;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutContentInput>;
  files?: InputMaybe<ContentCreatefilesInput>;
  id?: InputMaybe<Scalars['String']>;
  mediaType: MediaType;
  news?: InputMaybe<NewsCreateNestedOneWithoutContentsInput>;
  order?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<PageCreateNestedOneWithoutContentsInput>;
  photos?: InputMaybe<ContentCreatephotosInput>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutContentInput>;
  showOnProposal?: InputMaybe<Scalars['Boolean']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video?: InputMaybe<Scalars['String']>;
};

export type ContentCreateWithoutNewsInput = {
  Lesson?: InputMaybe<LessonCreateNestedOneWithoutContentsInput>;
  audio?: InputMaybe<Scalars['String']>;
  contentType: ContentType;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutContentInput>;
  files?: InputMaybe<ContentCreatefilesInput>;
  id?: InputMaybe<Scalars['String']>;
  mediaType: MediaType;
  order?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<PageCreateNestedOneWithoutContentsInput>;
  photos?: InputMaybe<ContentCreatephotosInput>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutContentInput>;
  showOnProposal?: InputMaybe<Scalars['Boolean']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video?: InputMaybe<Scalars['String']>;
};

export type ContentCreateWithoutPageInput = {
  Lesson?: InputMaybe<LessonCreateNestedOneWithoutContentsInput>;
  audio?: InputMaybe<Scalars['String']>;
  contentType: ContentType;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutContentInput>;
  files?: InputMaybe<ContentCreatefilesInput>;
  id?: InputMaybe<Scalars['String']>;
  mediaType: MediaType;
  news?: InputMaybe<NewsCreateNestedOneWithoutContentsInput>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ContentCreatephotosInput>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutContentInput>;
  showOnProposal?: InputMaybe<Scalars['Boolean']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video?: InputMaybe<Scalars['String']>;
};

export type ContentCreateWithoutQuestionInput = {
  Lesson?: InputMaybe<LessonCreateNestedOneWithoutContentsInput>;
  audio?: InputMaybe<Scalars['String']>;
  contentType: ContentType;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutContentInput>;
  files?: InputMaybe<ContentCreatefilesInput>;
  id?: InputMaybe<Scalars['String']>;
  mediaType: MediaType;
  news?: InputMaybe<NewsCreateNestedOneWithoutContentsInput>;
  order?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<PageCreateNestedOneWithoutContentsInput>;
  photos?: InputMaybe<ContentCreatephotosInput>;
  showOnProposal?: InputMaybe<Scalars['Boolean']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video?: InputMaybe<Scalars['String']>;
};

export type ContentCreatefilesInput = {
  set: Array<Scalars['String']>;
};

export type ContentCreatephotosInput = {
  set: Array<Scalars['String']>;
};

export type ContentGroupBy = {
  __typename?: 'ContentGroupBy';
  _avg?: Maybe<ContentAvgAggregate>;
  _count?: Maybe<ContentCountAggregate>;
  _max?: Maybe<ContentMaxAggregate>;
  _min?: Maybe<ContentMinAggregate>;
  _sum?: Maybe<ContentSumAggregate>;
  audio?: Maybe<Scalars['String']>;
  contentType: ContentType;
  createdAt: Scalars['DateTime'];
  files?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  lessonId?: Maybe<Scalars['String']>;
  mediaType: MediaType;
  newsId?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  pageId?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Scalars['String']>>;
  questionId?: Maybe<Scalars['String']>;
  showOnProposal: Scalars['Boolean'];
  showOnWeb: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  video?: Maybe<Scalars['String']>;
};

export type ContentListRelationFilter = {
  every?: InputMaybe<ContentWhereInput>;
  none?: InputMaybe<ContentWhereInput>;
  some?: InputMaybe<ContentWhereInput>;
};

export type ContentMaxAggregate = {
  __typename?: 'ContentMaxAggregate';
  audio?: Maybe<Scalars['String']>;
  contentType?: Maybe<ContentType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lessonId?: Maybe<Scalars['String']>;
  mediaType?: Maybe<MediaType>;
  newsId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  showOnProposal?: Maybe<Scalars['Boolean']>;
  showOnWeb?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  video?: Maybe<Scalars['String']>;
};

export type ContentMaxOrderByAggregateInput = {
  audio?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  mediaType?: InputMaybe<SortOrder>;
  newsId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  showOnProposal?: InputMaybe<SortOrder>;
  showOnWeb?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrder>;
};

export type ContentMinAggregate = {
  __typename?: 'ContentMinAggregate';
  audio?: Maybe<Scalars['String']>;
  contentType?: Maybe<ContentType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lessonId?: Maybe<Scalars['String']>;
  mediaType?: Maybe<MediaType>;
  newsId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  showOnProposal?: Maybe<Scalars['Boolean']>;
  showOnWeb?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  video?: Maybe<Scalars['String']>;
};

export type ContentMinOrderByAggregateInput = {
  audio?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  mediaType?: InputMaybe<SortOrder>;
  newsId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  showOnProposal?: InputMaybe<SortOrder>;
  showOnWeb?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrder>;
};

export type ContentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ContentOrderByRelevanceFieldEnum {
  audio = 'audio',
  files = 'files',
  id = 'id',
  lessonId = 'lessonId',
  newsId = 'newsId',
  pageId = 'pageId',
  photos = 'photos',
  questionId = 'questionId',
  video = 'video'
}

export type ContentOrderByRelevanceInput = {
  fields: Array<ContentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ContentOrderByWithAggregationInput = {
  _avg?: InputMaybe<ContentAvgOrderByAggregateInput>;
  _count?: InputMaybe<ContentCountOrderByAggregateInput>;
  _max?: InputMaybe<ContentMaxOrderByAggregateInput>;
  _min?: InputMaybe<ContentMinOrderByAggregateInput>;
  _sum?: InputMaybe<ContentSumOrderByAggregateInput>;
  audio?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  files?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  mediaType?: InputMaybe<SortOrder>;
  newsId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  showOnProposal?: InputMaybe<SortOrder>;
  showOnWeb?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrder>;
};

export type ContentOrderByWithRelationAndSearchRelevanceInput = {
  Lesson?: InputMaybe<LessonOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: InputMaybe<ContentOrderByRelevanceInput>;
  audio?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  files?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  mediaType?: InputMaybe<SortOrder>;
  news?: InputMaybe<NewsOrderByWithRelationAndSearchRelevanceInput>;
  newsId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<PageOrderByWithRelationAndSearchRelevanceInput>;
  pageId?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByWithRelationAndSearchRelevanceInput>;
  questionId?: InputMaybe<SortOrder>;
  showOnProposal?: InputMaybe<SortOrder>;
  showOnWeb?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrder>;
};

export type ContentRelationFilter = {
  is?: InputMaybe<ContentWhereInput>;
  isNot?: InputMaybe<ContentWhereInput>;
};

export enum ContentScalarFieldEnum {
  audio = 'audio',
  contentType = 'contentType',
  createdAt = 'createdAt',
  files = 'files',
  id = 'id',
  lessonId = 'lessonId',
  mediaType = 'mediaType',
  newsId = 'newsId',
  order = 'order',
  pageId = 'pageId',
  photos = 'photos',
  questionId = 'questionId',
  showOnProposal = 'showOnProposal',
  showOnWeb = 'showOnWeb',
  updatedAt = 'updatedAt',
  video = 'video'
}

export type ContentScalarWhereInput = {
  AND?: InputMaybe<Array<ContentScalarWhereInput>>;
  NOT?: InputMaybe<Array<ContentScalarWhereInput>>;
  OR?: InputMaybe<Array<ContentScalarWhereInput>>;
  audio?: InputMaybe<StringNullableFilter>;
  contentType?: InputMaybe<EnumContentTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  files?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  lessonId?: InputMaybe<StringNullableFilter>;
  mediaType?: InputMaybe<EnumMediaTypeFilter>;
  newsId?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<IntFilter>;
  pageId?: InputMaybe<StringNullableFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  questionId?: InputMaybe<StringNullableFilter>;
  showOnProposal?: InputMaybe<BoolFilter>;
  showOnWeb?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
};

export type ContentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ContentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ContentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ContentScalarWhereWithAggregatesInput>>;
  audio?: InputMaybe<StringNullableWithAggregatesFilter>;
  contentType?: InputMaybe<EnumContentTypeWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  files?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lessonId?: InputMaybe<StringNullableWithAggregatesFilter>;
  mediaType?: InputMaybe<EnumMediaTypeWithAggregatesFilter>;
  newsId?: InputMaybe<StringNullableWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  pageId?: InputMaybe<StringNullableWithAggregatesFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  questionId?: InputMaybe<StringNullableWithAggregatesFilter>;
  showOnProposal?: InputMaybe<BoolWithAggregatesFilter>;
  showOnWeb?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  video?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type ContentSumAggregate = {
  __typename?: 'ContentSumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type ContentSumOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export enum ContentType {
  AUDIO = 'AUDIO',
  FILE = 'FILE',
  IMAGE = 'IMAGE',
  LIST = 'LIST',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO'
}

export type ContentUpdateInput = {
  Lesson?: InputMaybe<LessonUpdateOneWithoutContentsInput>;
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contentType?: InputMaybe<EnumContentTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutContentInput>;
  files?: InputMaybe<ContentUpdatefilesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaType?: InputMaybe<EnumMediaTypeFieldUpdateOperationsInput>;
  news?: InputMaybe<NewsUpdateOneWithoutContentsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutContentsInput>;
  photos?: InputMaybe<ContentUpdatephotosInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutContentInput>;
  showOnProposal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdateManyMutationInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contentType?: InputMaybe<EnumContentTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  files?: InputMaybe<ContentUpdatefilesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaType?: InputMaybe<EnumMediaTypeFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ContentUpdatephotosInput>;
  showOnProposal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdateManyWithWhereWithoutLessonInput = {
  data: ContentUpdateManyMutationInput;
  where: ContentScalarWhereInput;
};

export type ContentUpdateManyWithWhereWithoutNewsInput = {
  data: ContentUpdateManyMutationInput;
  where: ContentScalarWhereInput;
};

export type ContentUpdateManyWithWhereWithoutPageInput = {
  data: ContentUpdateManyMutationInput;
  where: ContentScalarWhereInput;
};

export type ContentUpdateManyWithWhereWithoutQuestionInput = {
  data: ContentUpdateManyMutationInput;
  where: ContentScalarWhereInput;
};

export type ContentUpdateManyWithoutLessonInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutLessonInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutLessonInput>>;
  createMany?: InputMaybe<ContentCreateManyLessonInputEnvelope>;
  delete?: InputMaybe<Array<ContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentUpdateWithWhereUniqueWithoutLessonInput>>;
  updateMany?: InputMaybe<Array<ContentUpdateManyWithWhereWithoutLessonInput>>;
  upsert?: InputMaybe<Array<ContentUpsertWithWhereUniqueWithoutLessonInput>>;
};

export type ContentUpdateManyWithoutNewsInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutNewsInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutNewsInput>>;
  createMany?: InputMaybe<ContentCreateManyNewsInputEnvelope>;
  delete?: InputMaybe<Array<ContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentUpdateWithWhereUniqueWithoutNewsInput>>;
  updateMany?: InputMaybe<Array<ContentUpdateManyWithWhereWithoutNewsInput>>;
  upsert?: InputMaybe<Array<ContentUpsertWithWhereUniqueWithoutNewsInput>>;
};

export type ContentUpdateManyWithoutPageInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutPageInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutPageInput>>;
  createMany?: InputMaybe<ContentCreateManyPageInputEnvelope>;
  delete?: InputMaybe<Array<ContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentUpdateWithWhereUniqueWithoutPageInput>>;
  updateMany?: InputMaybe<Array<ContentUpdateManyWithWhereWithoutPageInput>>;
  upsert?: InputMaybe<Array<ContentUpsertWithWhereUniqueWithoutPageInput>>;
};

export type ContentUpdateManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<ContentCreateManyQuestionInputEnvelope>;
  delete?: InputMaybe<Array<ContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentUpdateWithWhereUniqueWithoutQuestionInput>>;
  updateMany?: InputMaybe<Array<ContentUpdateManyWithWhereWithoutQuestionInput>>;
  upsert?: InputMaybe<Array<ContentUpsertWithWhereUniqueWithoutQuestionInput>>;
};

export type ContentUpdateOneWithoutDescriptionsInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<ContentCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ContentUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<ContentUpsertWithoutDescriptionsInput>;
};

export type ContentUpdateWithWhereUniqueWithoutLessonInput = {
  data: ContentUpdateWithoutLessonInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpdateWithWhereUniqueWithoutNewsInput = {
  data: ContentUpdateWithoutNewsInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpdateWithWhereUniqueWithoutPageInput = {
  data: ContentUpdateWithoutPageInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpdateWithWhereUniqueWithoutQuestionInput = {
  data: ContentUpdateWithoutQuestionInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpdateWithoutDescriptionsInput = {
  Lesson?: InputMaybe<LessonUpdateOneWithoutContentsInput>;
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contentType?: InputMaybe<EnumContentTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  files?: InputMaybe<ContentUpdatefilesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaType?: InputMaybe<EnumMediaTypeFieldUpdateOperationsInput>;
  news?: InputMaybe<NewsUpdateOneWithoutContentsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutContentsInput>;
  photos?: InputMaybe<ContentUpdatephotosInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutContentInput>;
  showOnProposal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdateWithoutLessonInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contentType?: InputMaybe<EnumContentTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutContentInput>;
  files?: InputMaybe<ContentUpdatefilesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaType?: InputMaybe<EnumMediaTypeFieldUpdateOperationsInput>;
  news?: InputMaybe<NewsUpdateOneWithoutContentsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutContentsInput>;
  photos?: InputMaybe<ContentUpdatephotosInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutContentInput>;
  showOnProposal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdateWithoutNewsInput = {
  Lesson?: InputMaybe<LessonUpdateOneWithoutContentsInput>;
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contentType?: InputMaybe<EnumContentTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutContentInput>;
  files?: InputMaybe<ContentUpdatefilesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaType?: InputMaybe<EnumMediaTypeFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutContentsInput>;
  photos?: InputMaybe<ContentUpdatephotosInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutContentInput>;
  showOnProposal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdateWithoutPageInput = {
  Lesson?: InputMaybe<LessonUpdateOneWithoutContentsInput>;
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contentType?: InputMaybe<EnumContentTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutContentInput>;
  files?: InputMaybe<ContentUpdatefilesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaType?: InputMaybe<EnumMediaTypeFieldUpdateOperationsInput>;
  news?: InputMaybe<NewsUpdateOneWithoutContentsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ContentUpdatephotosInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutContentInput>;
  showOnProposal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdateWithoutQuestionInput = {
  Lesson?: InputMaybe<LessonUpdateOneWithoutContentsInput>;
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contentType?: InputMaybe<EnumContentTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutContentInput>;
  files?: InputMaybe<ContentUpdatefilesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaType?: InputMaybe<EnumMediaTypeFieldUpdateOperationsInput>;
  news?: InputMaybe<NewsUpdateOneWithoutContentsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutContentsInput>;
  photos?: InputMaybe<ContentUpdatephotosInput>;
  showOnProposal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdatefilesInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ContentUpdatephotosInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ContentUpsertWithWhereUniqueWithoutLessonInput = {
  create: ContentCreateWithoutLessonInput;
  update: ContentUpdateWithoutLessonInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpsertWithWhereUniqueWithoutNewsInput = {
  create: ContentCreateWithoutNewsInput;
  update: ContentUpdateWithoutNewsInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpsertWithWhereUniqueWithoutPageInput = {
  create: ContentCreateWithoutPageInput;
  update: ContentUpdateWithoutPageInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpsertWithWhereUniqueWithoutQuestionInput = {
  create: ContentCreateWithoutQuestionInput;
  update: ContentUpdateWithoutQuestionInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpsertWithoutDescriptionsInput = {
  create: ContentCreateWithoutDescriptionsInput;
  update: ContentUpdateWithoutDescriptionsInput;
};

export type ContentWhereInput = {
  AND?: InputMaybe<Array<ContentWhereInput>>;
  Lesson?: InputMaybe<LessonRelationFilter>;
  NOT?: InputMaybe<Array<ContentWhereInput>>;
  OR?: InputMaybe<Array<ContentWhereInput>>;
  audio?: InputMaybe<StringNullableFilter>;
  contentType?: InputMaybe<EnumContentTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  files?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  lessonId?: InputMaybe<StringNullableFilter>;
  mediaType?: InputMaybe<EnumMediaTypeFilter>;
  news?: InputMaybe<NewsRelationFilter>;
  newsId?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<IntFilter>;
  page?: InputMaybe<PageRelationFilter>;
  pageId?: InputMaybe<StringNullableFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  question?: InputMaybe<QuestionRelationFilter>;
  questionId?: InputMaybe<StringNullableFilter>;
  showOnProposal?: InputMaybe<BoolFilter>;
  showOnWeb?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
};

export type ContentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type Description = {
  __typename?: 'Description';
  News?: Maybe<News>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['String']>;
  choice?: Maybe<Choice>;
  choiceId?: Maybe<Scalars['String']>;
  content?: Maybe<Content>;
  contentId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  language: LanguageOptions;
  lesson?: Maybe<Lesson>;
  lessonId?: Maybe<Scalars['String']>;
  newsId?: Maybe<Scalars['String']>;
  page?: Maybe<Page>;
  pageId?: Maybe<Scalars['String']>;
  question?: Maybe<Question>;
  questionId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCountAggregate = {
  __typename?: 'DescriptionCountAggregate';
  _all: Scalars['Int'];
  buttonText: Scalars['Int'];
  buttonUrl: Scalars['Int'];
  categoryId: Scalars['Int'];
  choiceId: Scalars['Int'];
  contentId: Scalars['Int'];
  description: Scalars['Int'];
  headline: Scalars['Int'];
  id: Scalars['Int'];
  language: Scalars['Int'];
  lessonId: Scalars['Int'];
  newsId: Scalars['Int'];
  pageId: Scalars['Int'];
  questionId: Scalars['Int'];
  slug: Scalars['Int'];
  title: Scalars['Int'];
};

export type DescriptionCountOrderByAggregateInput = {
  buttonText?: InputMaybe<SortOrder>;
  buttonUrl?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  choiceId?: InputMaybe<SortOrder>;
  contentId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  headline?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  newsId?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type DescriptionCreateInput = {
  News?: InputMaybe<NewsCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  choice?: InputMaybe<ChoiceCreateNestedOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lesson?: InputMaybe<LessonCreateNestedOneWithoutDescriptionsInput>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateManyCategoryInput = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  choiceId?: InputMaybe<Scalars['String']>;
  contentId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lessonId?: InputMaybe<Scalars['String']>;
  newsId?: InputMaybe<Scalars['String']>;
  pageId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateManyCategoryInputEnvelope = {
  data: Array<DescriptionCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyChoiceInput = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  contentId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lessonId?: InputMaybe<Scalars['String']>;
  newsId?: InputMaybe<Scalars['String']>;
  pageId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateManyChoiceInputEnvelope = {
  data: Array<DescriptionCreateManyChoiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyContentInput = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  choiceId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lessonId?: InputMaybe<Scalars['String']>;
  newsId?: InputMaybe<Scalars['String']>;
  pageId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateManyContentInputEnvelope = {
  data: Array<DescriptionCreateManyContentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyInput = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  choiceId?: InputMaybe<Scalars['String']>;
  contentId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lessonId?: InputMaybe<Scalars['String']>;
  newsId?: InputMaybe<Scalars['String']>;
  pageId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateManyLessonInput = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  choiceId?: InputMaybe<Scalars['String']>;
  contentId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  newsId?: InputMaybe<Scalars['String']>;
  pageId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateManyLessonInputEnvelope = {
  data: Array<DescriptionCreateManyLessonInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyNewsInput = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  choiceId?: InputMaybe<Scalars['String']>;
  contentId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lessonId?: InputMaybe<Scalars['String']>;
  pageId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateManyNewsInputEnvelope = {
  data: Array<DescriptionCreateManyNewsInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyPageInput = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  choiceId?: InputMaybe<Scalars['String']>;
  contentId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lessonId?: InputMaybe<Scalars['String']>;
  newsId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateManyPageInputEnvelope = {
  data: Array<DescriptionCreateManyPageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyQuestionInput = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  choiceId?: InputMaybe<Scalars['String']>;
  contentId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lessonId?: InputMaybe<Scalars['String']>;
  newsId?: InputMaybe<Scalars['String']>;
  pageId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateManyQuestionInputEnvelope = {
  data: Array<DescriptionCreateManyQuestionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<DescriptionCreateManyCategoryInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutChoiceInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutChoiceInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutChoiceInput>>;
  createMany?: InputMaybe<DescriptionCreateManyChoiceInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutContentInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutContentInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutContentInput>>;
  createMany?: InputMaybe<DescriptionCreateManyContentInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutLessonInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutLessonInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutLessonInput>>;
  createMany?: InputMaybe<DescriptionCreateManyLessonInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutNewsInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutNewsInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutNewsInput>>;
  createMany?: InputMaybe<DescriptionCreateManyNewsInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutPageInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutPageInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutPageInput>>;
  createMany?: InputMaybe<DescriptionCreateManyPageInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<DescriptionCreateManyQuestionInputEnvelope>;
};

export type DescriptionCreateOrConnectWithoutCategoryInput = {
  create: DescriptionCreateWithoutCategoryInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutChoiceInput = {
  create: DescriptionCreateWithoutChoiceInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutContentInput = {
  create: DescriptionCreateWithoutContentInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutLessonInput = {
  create: DescriptionCreateWithoutLessonInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutNewsInput = {
  create: DescriptionCreateWithoutNewsInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutPageInput = {
  create: DescriptionCreateWithoutPageInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutQuestionInput = {
  create: DescriptionCreateWithoutQuestionInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateWithoutCategoryInput = {
  News?: InputMaybe<NewsCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  choice?: InputMaybe<ChoiceCreateNestedOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lesson?: InputMaybe<LessonCreateNestedOneWithoutDescriptionsInput>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateWithoutChoiceInput = {
  News?: InputMaybe<NewsCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lesson?: InputMaybe<LessonCreateNestedOneWithoutDescriptionsInput>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateWithoutContentInput = {
  News?: InputMaybe<NewsCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  choice?: InputMaybe<ChoiceCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lesson?: InputMaybe<LessonCreateNestedOneWithoutDescriptionsInput>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateWithoutLessonInput = {
  News?: InputMaybe<NewsCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  choice?: InputMaybe<ChoiceCreateNestedOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateWithoutNewsInput = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  choice?: InputMaybe<ChoiceCreateNestedOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lesson?: InputMaybe<LessonCreateNestedOneWithoutDescriptionsInput>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateWithoutPageInput = {
  News?: InputMaybe<NewsCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  choice?: InputMaybe<ChoiceCreateNestedOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lesson?: InputMaybe<LessonCreateNestedOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateWithoutQuestionInput = {
  News?: InputMaybe<NewsCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  choice?: InputMaybe<ChoiceCreateNestedOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  lesson?: InputMaybe<LessonCreateNestedOneWithoutDescriptionsInput>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionGroupBy = {
  __typename?: 'DescriptionGroupBy';
  _count?: Maybe<DescriptionCountAggregate>;
  _max?: Maybe<DescriptionMaxAggregate>;
  _min?: Maybe<DescriptionMinAggregate>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  choiceId?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  language: LanguageOptions;
  lessonId?: Maybe<Scalars['String']>;
  newsId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionListRelationFilter = {
  every?: InputMaybe<DescriptionWhereInput>;
  none?: InputMaybe<DescriptionWhereInput>;
  some?: InputMaybe<DescriptionWhereInput>;
};

export type DescriptionMaxAggregate = {
  __typename?: 'DescriptionMaxAggregate';
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  choiceId?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageOptions>;
  lessonId?: Maybe<Scalars['String']>;
  newsId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DescriptionMaxOrderByAggregateInput = {
  buttonText?: InputMaybe<SortOrder>;
  buttonUrl?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  choiceId?: InputMaybe<SortOrder>;
  contentId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  headline?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  newsId?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type DescriptionMinAggregate = {
  __typename?: 'DescriptionMinAggregate';
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  choiceId?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageOptions>;
  lessonId?: Maybe<Scalars['String']>;
  newsId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DescriptionMinOrderByAggregateInput = {
  buttonText?: InputMaybe<SortOrder>;
  buttonUrl?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  choiceId?: InputMaybe<SortOrder>;
  contentId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  headline?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  newsId?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type DescriptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum DescriptionOrderByRelevanceFieldEnum {
  buttonText = 'buttonText',
  buttonUrl = 'buttonUrl',
  categoryId = 'categoryId',
  choiceId = 'choiceId',
  contentId = 'contentId',
  description = 'description',
  headline = 'headline',
  id = 'id',
  lessonId = 'lessonId',
  newsId = 'newsId',
  pageId = 'pageId',
  questionId = 'questionId',
  slug = 'slug',
  title = 'title'
}

export type DescriptionOrderByRelevanceInput = {
  fields: Array<DescriptionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type DescriptionOrderByWithAggregationInput = {
  _count?: InputMaybe<DescriptionCountOrderByAggregateInput>;
  _max?: InputMaybe<DescriptionMaxOrderByAggregateInput>;
  _min?: InputMaybe<DescriptionMinOrderByAggregateInput>;
  buttonText?: InputMaybe<SortOrder>;
  buttonUrl?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  choiceId?: InputMaybe<SortOrder>;
  contentId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  headline?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  lessonId?: InputMaybe<SortOrder>;
  newsId?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type DescriptionOrderByWithRelationAndSearchRelevanceInput = {
  News?: InputMaybe<NewsOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: InputMaybe<DescriptionOrderByRelevanceInput>;
  buttonText?: InputMaybe<SortOrder>;
  buttonUrl?: InputMaybe<SortOrder>;
  category?: InputMaybe<CategoryOrderByWithRelationAndSearchRelevanceInput>;
  categoryId?: InputMaybe<SortOrder>;
  choice?: InputMaybe<ChoiceOrderByWithRelationAndSearchRelevanceInput>;
  choiceId?: InputMaybe<SortOrder>;
  content?: InputMaybe<ContentOrderByWithRelationAndSearchRelevanceInput>;
  contentId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  headline?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  lesson?: InputMaybe<LessonOrderByWithRelationAndSearchRelevanceInput>;
  lessonId?: InputMaybe<SortOrder>;
  newsId?: InputMaybe<SortOrder>;
  page?: InputMaybe<PageOrderByWithRelationAndSearchRelevanceInput>;
  pageId?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByWithRelationAndSearchRelevanceInput>;
  questionId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export enum DescriptionScalarFieldEnum {
  buttonText = 'buttonText',
  buttonUrl = 'buttonUrl',
  categoryId = 'categoryId',
  choiceId = 'choiceId',
  contentId = 'contentId',
  description = 'description',
  headline = 'headline',
  id = 'id',
  language = 'language',
  lessonId = 'lessonId',
  newsId = 'newsId',
  pageId = 'pageId',
  questionId = 'questionId',
  slug = 'slug',
  title = 'title'
}

export type DescriptionScalarWhereInput = {
  AND?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  OR?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  buttonText?: InputMaybe<StringNullableFilter>;
  buttonUrl?: InputMaybe<StringNullableFilter>;
  categoryId?: InputMaybe<StringNullableFilter>;
  choiceId?: InputMaybe<StringNullableFilter>;
  contentId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  headline?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  language?: InputMaybe<EnumLanguageOptionsFilter>;
  lessonId?: InputMaybe<StringNullableFilter>;
  newsId?: InputMaybe<StringNullableFilter>;
  pageId?: InputMaybe<StringNullableFilter>;
  questionId?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type DescriptionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DescriptionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DescriptionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DescriptionScalarWhereWithAggregatesInput>>;
  buttonText?: InputMaybe<StringNullableWithAggregatesFilter>;
  buttonUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  categoryId?: InputMaybe<StringNullableWithAggregatesFilter>;
  choiceId?: InputMaybe<StringNullableWithAggregatesFilter>;
  contentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  headline?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  language?: InputMaybe<EnumLanguageOptionsWithAggregatesFilter>;
  lessonId?: InputMaybe<StringNullableWithAggregatesFilter>;
  newsId?: InputMaybe<StringNullableWithAggregatesFilter>;
  pageId?: InputMaybe<StringNullableWithAggregatesFilter>;
  questionId?: InputMaybe<StringNullableWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
};

export type DescriptionUpdateInput = {
  News?: InputMaybe<NewsUpdateOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsInput>;
  choice?: InputMaybe<ChoiceUpdateOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentUpdateOneWithoutDescriptionsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  lesson?: InputMaybe<LessonUpdateOneWithoutDescriptionsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutDescriptionsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateManyMutationInput = {
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateManyWithWhereWithoutCategoryInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutChoiceInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutContentInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutLessonInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutNewsInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutPageInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutQuestionInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<DescriptionCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type DescriptionUpdateManyWithoutChoiceInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutChoiceInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutChoiceInput>>;
  createMany?: InputMaybe<DescriptionCreateManyChoiceInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutChoiceInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutChoiceInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutChoiceInput>>;
};

export type DescriptionUpdateManyWithoutContentInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutContentInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutContentInput>>;
  createMany?: InputMaybe<DescriptionCreateManyContentInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutContentInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutContentInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutContentInput>>;
};

export type DescriptionUpdateManyWithoutLessonInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutLessonInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutLessonInput>>;
  createMany?: InputMaybe<DescriptionCreateManyLessonInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutLessonInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutLessonInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutLessonInput>>;
};

export type DescriptionUpdateManyWithoutNewsInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutNewsInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutNewsInput>>;
  createMany?: InputMaybe<DescriptionCreateManyNewsInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutNewsInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutNewsInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutNewsInput>>;
};

export type DescriptionUpdateManyWithoutPageInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutPageInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutPageInput>>;
  createMany?: InputMaybe<DescriptionCreateManyPageInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutPageInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutPageInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutPageInput>>;
};

export type DescriptionUpdateManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<DescriptionCreateManyQuestionInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutQuestionInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutQuestionInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutQuestionInput>>;
};

export type DescriptionUpdateWithWhereUniqueWithoutCategoryInput = {
  data: DescriptionUpdateWithoutCategoryInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutChoiceInput = {
  data: DescriptionUpdateWithoutChoiceInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutContentInput = {
  data: DescriptionUpdateWithoutContentInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutLessonInput = {
  data: DescriptionUpdateWithoutLessonInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutNewsInput = {
  data: DescriptionUpdateWithoutNewsInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutPageInput = {
  data: DescriptionUpdateWithoutPageInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutQuestionInput = {
  data: DescriptionUpdateWithoutQuestionInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithoutCategoryInput = {
  News?: InputMaybe<NewsUpdateOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  choice?: InputMaybe<ChoiceUpdateOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentUpdateOneWithoutDescriptionsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  lesson?: InputMaybe<LessonUpdateOneWithoutDescriptionsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutDescriptionsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutChoiceInput = {
  News?: InputMaybe<NewsUpdateOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentUpdateOneWithoutDescriptionsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  lesson?: InputMaybe<LessonUpdateOneWithoutDescriptionsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutDescriptionsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutContentInput = {
  News?: InputMaybe<NewsUpdateOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsInput>;
  choice?: InputMaybe<ChoiceUpdateOneWithoutDescriptionsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  lesson?: InputMaybe<LessonUpdateOneWithoutDescriptionsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutDescriptionsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutLessonInput = {
  News?: InputMaybe<NewsUpdateOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsInput>;
  choice?: InputMaybe<ChoiceUpdateOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentUpdateOneWithoutDescriptionsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutDescriptionsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutNewsInput = {
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsInput>;
  choice?: InputMaybe<ChoiceUpdateOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentUpdateOneWithoutDescriptionsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  lesson?: InputMaybe<LessonUpdateOneWithoutDescriptionsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutDescriptionsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutPageInput = {
  News?: InputMaybe<NewsUpdateOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsInput>;
  choice?: InputMaybe<ChoiceUpdateOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentUpdateOneWithoutDescriptionsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  lesson?: InputMaybe<LessonUpdateOneWithoutDescriptionsInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutDescriptionsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutQuestionInput = {
  News?: InputMaybe<NewsUpdateOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsInput>;
  choice?: InputMaybe<ChoiceUpdateOneWithoutDescriptionsInput>;
  content?: InputMaybe<ContentUpdateOneWithoutDescriptionsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  lesson?: InputMaybe<LessonUpdateOneWithoutDescriptionsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpsertWithWhereUniqueWithoutCategoryInput = {
  create: DescriptionCreateWithoutCategoryInput;
  update: DescriptionUpdateWithoutCategoryInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutChoiceInput = {
  create: DescriptionCreateWithoutChoiceInput;
  update: DescriptionUpdateWithoutChoiceInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutContentInput = {
  create: DescriptionCreateWithoutContentInput;
  update: DescriptionUpdateWithoutContentInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutLessonInput = {
  create: DescriptionCreateWithoutLessonInput;
  update: DescriptionUpdateWithoutLessonInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutNewsInput = {
  create: DescriptionCreateWithoutNewsInput;
  update: DescriptionUpdateWithoutNewsInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutPageInput = {
  create: DescriptionCreateWithoutPageInput;
  update: DescriptionUpdateWithoutPageInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutQuestionInput = {
  create: DescriptionCreateWithoutQuestionInput;
  update: DescriptionUpdateWithoutQuestionInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionWhereInput = {
  AND?: InputMaybe<Array<DescriptionWhereInput>>;
  NOT?: InputMaybe<Array<DescriptionWhereInput>>;
  News?: InputMaybe<NewsRelationFilter>;
  OR?: InputMaybe<Array<DescriptionWhereInput>>;
  buttonText?: InputMaybe<StringNullableFilter>;
  buttonUrl?: InputMaybe<StringNullableFilter>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringNullableFilter>;
  choice?: InputMaybe<ChoiceRelationFilter>;
  choiceId?: InputMaybe<StringNullableFilter>;
  content?: InputMaybe<ContentRelationFilter>;
  contentId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  headline?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  language?: InputMaybe<EnumLanguageOptionsFilter>;
  lesson?: InputMaybe<LessonRelationFilter>;
  lessonId?: InputMaybe<StringNullableFilter>;
  newsId?: InputMaybe<StringNullableFilter>;
  page?: InputMaybe<PageRelationFilter>;
  pageId?: InputMaybe<StringNullableFilter>;
  question?: InputMaybe<QuestionRelationFilter>;
  questionId?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type DescriptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type EnumContentTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ContentType>;
};

export type EnumContentTypeFilter = {
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  not?: InputMaybe<NestedEnumContentTypeFilter>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type EnumContentTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumContentTypeFilter>;
  _min?: InputMaybe<NestedEnumContentTypeFilter>;
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  not?: InputMaybe<NestedEnumContentTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type EnumLanguageOptionsFieldUpdateOperationsInput = {
  set?: InputMaybe<LanguageOptions>;
};

export type EnumLanguageOptionsFilter = {
  equals?: InputMaybe<LanguageOptions>;
  in?: InputMaybe<Array<LanguageOptions>>;
  not?: InputMaybe<NestedEnumLanguageOptionsFilter>;
  notIn?: InputMaybe<Array<LanguageOptions>>;
};

export type EnumLanguageOptionsWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumLanguageOptionsFilter>;
  _min?: InputMaybe<NestedEnumLanguageOptionsFilter>;
  equals?: InputMaybe<LanguageOptions>;
  in?: InputMaybe<Array<LanguageOptions>>;
  not?: InputMaybe<NestedEnumLanguageOptionsWithAggregatesFilter>;
  notIn?: InputMaybe<Array<LanguageOptions>>;
};

export type EnumMediaTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<MediaType>;
};

export type EnumMediaTypeFilter = {
  equals?: InputMaybe<MediaType>;
  in?: InputMaybe<Array<MediaType>>;
  not?: InputMaybe<NestedEnumMediaTypeFilter>;
  notIn?: InputMaybe<Array<MediaType>>;
};

export type EnumMediaTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMediaTypeFilter>;
  _min?: InputMaybe<NestedEnumMediaTypeFilter>;
  equals?: InputMaybe<MediaType>;
  in?: InputMaybe<Array<MediaType>>;
  not?: InputMaybe<NestedEnumMediaTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<MediaType>>;
};

export type EnumPageTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<PageType>;
};

export type EnumPageTypeFilter = {
  equals?: InputMaybe<PageType>;
  in?: InputMaybe<Array<PageType>>;
  not?: InputMaybe<NestedEnumPageTypeFilter>;
  notIn?: InputMaybe<Array<PageType>>;
};

export type EnumPageTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPageTypeFilter>;
  _min?: InputMaybe<NestedEnumPageTypeFilter>;
  equals?: InputMaybe<PageType>;
  in?: InputMaybe<Array<PageType>>;
  not?: InputMaybe<NestedEnumPageTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PageType>>;
};

export type EnumUserTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<UserType>;
};

export type EnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type EnumUserTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUserTypeFilter>;
  _min?: InputMaybe<NestedEnumUserTypeFilter>;
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export enum LanguageOptions {
  EN = 'EN',
  TR = 'TR'
}

export type Lesson = {
  __typename?: 'Lesson';
  _count?: Maybe<LessonCount>;
  category: Category;
  categoryId: Scalars['String'];
  completedLesson: Array<CompletedLesson>;
  contents: Array<Content>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  isApplication: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};


export type LessonCompletedLessonArgs = {
  cursor?: InputMaybe<CompletedLessonWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompletedLessonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompletedLessonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompletedLessonWhereInput>;
};


export type LessonContentsArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type LessonDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};

export type LessonCount = {
  __typename?: 'LessonCount';
  completedLesson: Scalars['Int'];
  contents: Scalars['Int'];
  descriptions: Scalars['Int'];
};

export type LessonCountAggregate = {
  __typename?: 'LessonCountAggregate';
  _all: Scalars['Int'];
  categoryId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isApplication: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LessonCountOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isApplication?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LessonCreateInput = {
  category: CategoryCreateNestedOneWithoutLessonsInput;
  completedLesson?: InputMaybe<CompletedLessonCreateNestedManyWithoutLessonInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutLessonInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutLessonInput>;
  id?: InputMaybe<Scalars['String']>;
  isApplication?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LessonCreateManyCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isApplication?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LessonCreateManyCategoryInputEnvelope = {
  data: Array<LessonCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LessonCreateManyInput = {
  categoryId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isApplication?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LessonCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<LessonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LessonCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<LessonCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<LessonCreateManyCategoryInputEnvelope>;
};

export type LessonCreateNestedOneWithoutCompletedLessonInput = {
  connect?: InputMaybe<LessonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LessonCreateOrConnectWithoutCompletedLessonInput>;
  create?: InputMaybe<LessonCreateWithoutCompletedLessonInput>;
};

export type LessonCreateNestedOneWithoutContentsInput = {
  connect?: InputMaybe<LessonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LessonCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<LessonCreateWithoutContentsInput>;
};

export type LessonCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<LessonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LessonCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<LessonCreateWithoutDescriptionsInput>;
};

export type LessonCreateOrConnectWithoutCategoryInput = {
  create: LessonCreateWithoutCategoryInput;
  where: LessonWhereUniqueInput;
};

export type LessonCreateOrConnectWithoutCompletedLessonInput = {
  create: LessonCreateWithoutCompletedLessonInput;
  where: LessonWhereUniqueInput;
};

export type LessonCreateOrConnectWithoutContentsInput = {
  create: LessonCreateWithoutContentsInput;
  where: LessonWhereUniqueInput;
};

export type LessonCreateOrConnectWithoutDescriptionsInput = {
  create: LessonCreateWithoutDescriptionsInput;
  where: LessonWhereUniqueInput;
};

export type LessonCreateWithoutCategoryInput = {
  completedLesson?: InputMaybe<CompletedLessonCreateNestedManyWithoutLessonInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutLessonInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutLessonInput>;
  id?: InputMaybe<Scalars['String']>;
  isApplication?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LessonCreateWithoutCompletedLessonInput = {
  category: CategoryCreateNestedOneWithoutLessonsInput;
  contents?: InputMaybe<ContentCreateNestedManyWithoutLessonInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutLessonInput>;
  id?: InputMaybe<Scalars['String']>;
  isApplication?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LessonCreateWithoutContentsInput = {
  category: CategoryCreateNestedOneWithoutLessonsInput;
  completedLesson?: InputMaybe<CompletedLessonCreateNestedManyWithoutLessonInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutLessonInput>;
  id?: InputMaybe<Scalars['String']>;
  isApplication?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LessonCreateWithoutDescriptionsInput = {
  category: CategoryCreateNestedOneWithoutLessonsInput;
  completedLesson?: InputMaybe<CompletedLessonCreateNestedManyWithoutLessonInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutLessonInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isApplication?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LessonGroupBy = {
  __typename?: 'LessonGroupBy';
  _count?: Maybe<LessonCountAggregate>;
  _max?: Maybe<LessonMaxAggregate>;
  _min?: Maybe<LessonMinAggregate>;
  categoryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isApplication: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type LessonListRelationFilter = {
  every?: InputMaybe<LessonWhereInput>;
  none?: InputMaybe<LessonWhereInput>;
  some?: InputMaybe<LessonWhereInput>;
};

export type LessonMaxAggregate = {
  __typename?: 'LessonMaxAggregate';
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isApplication?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LessonMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isApplication?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LessonMinAggregate = {
  __typename?: 'LessonMinAggregate';
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isApplication?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LessonMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isApplication?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LessonOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum LessonOrderByRelevanceFieldEnum {
  categoryId = 'categoryId',
  id = 'id'
}

export type LessonOrderByRelevanceInput = {
  fields: Array<LessonOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LessonOrderByWithAggregationInput = {
  _count?: InputMaybe<LessonCountOrderByAggregateInput>;
  _max?: InputMaybe<LessonMaxOrderByAggregateInput>;
  _min?: InputMaybe<LessonMinOrderByAggregateInput>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isApplication?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LessonOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<LessonOrderByRelevanceInput>;
  category?: InputMaybe<CategoryOrderByWithRelationAndSearchRelevanceInput>;
  categoryId?: InputMaybe<SortOrder>;
  completedLesson?: InputMaybe<CompletedLessonOrderByRelationAggregateInput>;
  contents?: InputMaybe<ContentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isApplication?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LessonRelationFilter = {
  is?: InputMaybe<LessonWhereInput>;
  isNot?: InputMaybe<LessonWhereInput>;
};

export enum LessonScalarFieldEnum {
  categoryId = 'categoryId',
  createdAt = 'createdAt',
  id = 'id',
  isApplication = 'isApplication',
  updatedAt = 'updatedAt'
}

export type LessonScalarWhereInput = {
  AND?: InputMaybe<Array<LessonScalarWhereInput>>;
  NOT?: InputMaybe<Array<LessonScalarWhereInput>>;
  OR?: InputMaybe<Array<LessonScalarWhereInput>>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isApplication?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LessonScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LessonScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<LessonScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LessonScalarWhereWithAggregatesInput>>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isApplication?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type LessonUpdateInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutLessonsInput>;
  completedLesson?: InputMaybe<CompletedLessonUpdateManyWithoutLessonInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutLessonInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutLessonInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isApplication?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LessonUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isApplication?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LessonUpdateManyWithWhereWithoutCategoryInput = {
  data: LessonUpdateManyMutationInput;
  where: LessonScalarWhereInput;
};

export type LessonUpdateManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<LessonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LessonCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<LessonCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<LessonCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<LessonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LessonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LessonWhereUniqueInput>>;
  set?: InputMaybe<Array<LessonWhereUniqueInput>>;
  update?: InputMaybe<Array<LessonUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<LessonUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<LessonUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type LessonUpdateOneRequiredWithoutCompletedLessonInput = {
  connect?: InputMaybe<LessonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LessonCreateOrConnectWithoutCompletedLessonInput>;
  create?: InputMaybe<LessonCreateWithoutCompletedLessonInput>;
  update?: InputMaybe<LessonUpdateWithoutCompletedLessonInput>;
  upsert?: InputMaybe<LessonUpsertWithoutCompletedLessonInput>;
};

export type LessonUpdateOneWithoutContentsInput = {
  connect?: InputMaybe<LessonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LessonCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<LessonCreateWithoutContentsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<LessonUpdateWithoutContentsInput>;
  upsert?: InputMaybe<LessonUpsertWithoutContentsInput>;
};

export type LessonUpdateOneWithoutDescriptionsInput = {
  connect?: InputMaybe<LessonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LessonCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<LessonCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<LessonUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<LessonUpsertWithoutDescriptionsInput>;
};

export type LessonUpdateWithWhereUniqueWithoutCategoryInput = {
  data: LessonUpdateWithoutCategoryInput;
  where: LessonWhereUniqueInput;
};

export type LessonUpdateWithoutCategoryInput = {
  completedLesson?: InputMaybe<CompletedLessonUpdateManyWithoutLessonInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutLessonInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutLessonInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isApplication?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LessonUpdateWithoutCompletedLessonInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutLessonsInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutLessonInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutLessonInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isApplication?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LessonUpdateWithoutContentsInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutLessonsInput>;
  completedLesson?: InputMaybe<CompletedLessonUpdateManyWithoutLessonInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutLessonInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isApplication?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LessonUpdateWithoutDescriptionsInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutLessonsInput>;
  completedLesson?: InputMaybe<CompletedLessonUpdateManyWithoutLessonInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutLessonInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isApplication?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LessonUpsertWithWhereUniqueWithoutCategoryInput = {
  create: LessonCreateWithoutCategoryInput;
  update: LessonUpdateWithoutCategoryInput;
  where: LessonWhereUniqueInput;
};

export type LessonUpsertWithoutCompletedLessonInput = {
  create: LessonCreateWithoutCompletedLessonInput;
  update: LessonUpdateWithoutCompletedLessonInput;
};

export type LessonUpsertWithoutContentsInput = {
  create: LessonCreateWithoutContentsInput;
  update: LessonUpdateWithoutContentsInput;
};

export type LessonUpsertWithoutDescriptionsInput = {
  create: LessonCreateWithoutDescriptionsInput;
  update: LessonUpdateWithoutDescriptionsInput;
};

export type LessonWhereInput = {
  AND?: InputMaybe<Array<LessonWhereInput>>;
  NOT?: InputMaybe<Array<LessonWhereInput>>;
  OR?: InputMaybe<Array<LessonWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  completedLesson?: InputMaybe<CompletedLessonListRelationFilter>;
  contents?: InputMaybe<ContentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isApplication?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LessonWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum MediaType {
  GALLERY = 'GALLERY',
  NOIMAGE = 'NOIMAGE',
  SIDEBYSIDE = 'SIDEBYSIDE',
  SINGLE = 'SINGLE',
  THUMBNAIL = 'THUMBNAIL'
}

export type Mutation = {
  __typename?: 'Mutation';
  createAnswer: Answer;
  createCategory: Category;
  createChoice: Choice;
  createCompletedLesson: CompletedLesson;
  createContent: Content;
  createCustomQuestionAnswer: QuestionAnswer;
  createDescription: Description;
  createLesson: Lesson;
  createManyAnswer: AffectedRowsOutput;
  createManyCategory: AffectedRowsOutput;
  createManyChoice: AffectedRowsOutput;
  createManyCompletedLesson: AffectedRowsOutput;
  createManyContent: AffectedRowsOutput;
  createManyDescription: AffectedRowsOutput;
  createManyLesson: AffectedRowsOutput;
  createManyNews: AffectedRowsOutput;
  createManyPage: AffectedRowsOutput;
  createManyQuestion: AffectedRowsOutput;
  createManyQuestionAnswer: AffectedRowsOutput;
  createManySubject: AffectedRowsOutput;
  createManyTransaction: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createNews: News;
  createPage: Page;
  createQuestion: Question;
  createQuestionAnswer: QuestionAnswer;
  createSubject: Subject;
  createTransaction: Transaction;
  createUser: User;
  deleteAnswer?: Maybe<Answer>;
  deleteCategory?: Maybe<Category>;
  deleteChoice?: Maybe<Choice>;
  deleteCompletedLesson?: Maybe<CompletedLesson>;
  deleteContent?: Maybe<Content>;
  deleteDescription?: Maybe<Description>;
  deleteLesson?: Maybe<Lesson>;
  deleteManyAnswer: AffectedRowsOutput;
  deleteManyCategory: AffectedRowsOutput;
  deleteManyChoice: AffectedRowsOutput;
  deleteManyCompletedLesson: AffectedRowsOutput;
  deleteManyContent: AffectedRowsOutput;
  deleteManyDescription: AffectedRowsOutput;
  deleteManyLesson: AffectedRowsOutput;
  deleteManyNews: AffectedRowsOutput;
  deleteManyPage: AffectedRowsOutput;
  deleteManyQuestion: AffectedRowsOutput;
  deleteManyQuestionAnswer: AffectedRowsOutput;
  deleteManySubject: AffectedRowsOutput;
  deleteManyTransaction: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteNews?: Maybe<News>;
  deletePage?: Maybe<Page>;
  deleteQuestion?: Maybe<Question>;
  deleteQuestionAnswer?: Maybe<QuestionAnswer>;
  deleteSubject?: Maybe<Subject>;
  deleteTransaction?: Maybe<Transaction>;
  deleteUser?: Maybe<User>;
  login: UserAuthPayload;
  register: Scalars['Boolean'];
  updateAnswer?: Maybe<Answer>;
  updateCategory?: Maybe<Category>;
  updateChoice?: Maybe<Choice>;
  updateCompletedLesson?: Maybe<CompletedLesson>;
  updateContent?: Maybe<Content>;
  updateDescription?: Maybe<Description>;
  updateLesson?: Maybe<Lesson>;
  updateManyAnswer: AffectedRowsOutput;
  updateManyCategory: AffectedRowsOutput;
  updateManyChoice: AffectedRowsOutput;
  updateManyCompletedLesson: AffectedRowsOutput;
  updateManyContent: AffectedRowsOutput;
  updateManyDescription: AffectedRowsOutput;
  updateManyLesson: AffectedRowsOutput;
  updateManyNews: AffectedRowsOutput;
  updateManyPage: AffectedRowsOutput;
  updateManyQuestion: AffectedRowsOutput;
  updateManyQuestionAnswer: AffectedRowsOutput;
  updateManySubject: AffectedRowsOutput;
  updateManyTransaction: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateNews?: Maybe<News>;
  updatePage?: Maybe<Page>;
  updateQuestion?: Maybe<Question>;
  updateQuestionAnswer?: Maybe<QuestionAnswer>;
  updateSubject?: Maybe<Subject>;
  updateTransaction?: Maybe<Transaction>;
  updateUser?: Maybe<User>;
  uploadFile: Scalars['JSONObject'];
  upsertAnswer: Answer;
  upsertCategory: Category;
  upsertChoice: Choice;
  upsertCompletedLesson: CompletedLesson;
  upsertContent: Content;
  upsertDescription: Description;
  upsertLesson: Lesson;
  upsertNews: News;
  upsertPage: Page;
  upsertQuestion: Question;
  upsertQuestionAnswer: QuestionAnswer;
  upsertSubject: Subject;
  upsertTransaction: Transaction;
  upsertUser: User;
};


export type MutationCreateAnswerArgs = {
  data: AnswerCreateInput;
};


export type MutationCreateCategoryArgs = {
  data: CategoryCreateInput;
};


export type MutationCreateChoiceArgs = {
  data: ChoiceCreateInput;
};


export type MutationCreateCompletedLessonArgs = {
  data: CompletedLessonCreateInput;
};


export type MutationCreateContentArgs = {
  data: ContentCreateInput;
};


export type MutationCreateCustomQuestionAnswerArgs = {
  choiceId: Scalars['String'];
  questionId: Scalars['String'];
};


export type MutationCreateDescriptionArgs = {
  data: DescriptionCreateInput;
};


export type MutationCreateLessonArgs = {
  data: LessonCreateInput;
};


export type MutationCreateManyAnswerArgs = {
  data: Array<AnswerCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCategoryArgs = {
  data: Array<CategoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyChoiceArgs = {
  data: Array<ChoiceCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCompletedLessonArgs = {
  data: Array<CompletedLessonCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyContentArgs = {
  data: Array<ContentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyDescriptionArgs = {
  data: Array<DescriptionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyLessonArgs = {
  data: Array<LessonCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyNewsArgs = {
  data: Array<NewsCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPageArgs = {
  data: Array<PageCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyQuestionArgs = {
  data: Array<QuestionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyQuestionAnswerArgs = {
  data: Array<QuestionAnswerCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManySubjectArgs = {
  data: Array<SubjectCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyTransactionArgs = {
  data: Array<TransactionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateNewsArgs = {
  data: NewsCreateInput;
};


export type MutationCreatePageArgs = {
  data: PageCreateInput;
};


export type MutationCreateQuestionArgs = {
  data: QuestionCreateInput;
};


export type MutationCreateQuestionAnswerArgs = {
  data: QuestionAnswerCreateInput;
};


export type MutationCreateSubjectArgs = {
  data: SubjectCreateInput;
};


export type MutationCreateTransactionArgs = {
  data: TransactionCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteAnswerArgs = {
  where: AnswerWhereUniqueInput;
};


export type MutationDeleteCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type MutationDeleteChoiceArgs = {
  where: ChoiceWhereUniqueInput;
};


export type MutationDeleteCompletedLessonArgs = {
  where: CompletedLessonWhereUniqueInput;
};


export type MutationDeleteContentArgs = {
  where: ContentWhereUniqueInput;
};


export type MutationDeleteDescriptionArgs = {
  where: DescriptionWhereUniqueInput;
};


export type MutationDeleteLessonArgs = {
  where: LessonWhereUniqueInput;
};


export type MutationDeleteManyAnswerArgs = {
  where?: InputMaybe<AnswerWhereInput>;
};


export type MutationDeleteManyCategoryArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};


export type MutationDeleteManyChoiceArgs = {
  where?: InputMaybe<ChoiceWhereInput>;
};


export type MutationDeleteManyCompletedLessonArgs = {
  where?: InputMaybe<CompletedLessonWhereInput>;
};


export type MutationDeleteManyContentArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationDeleteManyDescriptionArgs = {
  where?: InputMaybe<DescriptionWhereInput>;
};


export type MutationDeleteManyLessonArgs = {
  where?: InputMaybe<LessonWhereInput>;
};


export type MutationDeleteManyNewsArgs = {
  where?: InputMaybe<NewsWhereInput>;
};


export type MutationDeleteManyPageArgs = {
  where?: InputMaybe<PageWhereInput>;
};


export type MutationDeleteManyQuestionArgs = {
  where?: InputMaybe<QuestionWhereInput>;
};


export type MutationDeleteManyQuestionAnswerArgs = {
  where?: InputMaybe<QuestionAnswerWhereInput>;
};


export type MutationDeleteManySubjectArgs = {
  where?: InputMaybe<SubjectWhereInput>;
};


export type MutationDeleteManyTransactionArgs = {
  where?: InputMaybe<TransactionWhereInput>;
};


export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type MutationDeleteNewsArgs = {
  where: NewsWhereUniqueInput;
};


export type MutationDeletePageArgs = {
  where: PageWhereUniqueInput;
};


export type MutationDeleteQuestionArgs = {
  where: QuestionWhereUniqueInput;
};


export type MutationDeleteQuestionAnswerArgs = {
  where: QuestionAnswerWhereUniqueInput;
};


export type MutationDeleteSubjectArgs = {
  where: SubjectWhereUniqueInput;
};


export type MutationDeleteTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationRegisterArgs = {
  data: UserCreateInput;
};


export type MutationUpdateAnswerArgs = {
  data: AnswerUpdateInput;
  where: AnswerWhereUniqueInput;
};


export type MutationUpdateCategoryArgs = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationUpdateChoiceArgs = {
  data: ChoiceUpdateInput;
  where: ChoiceWhereUniqueInput;
};


export type MutationUpdateCompletedLessonArgs = {
  data: CompletedLessonUpdateInput;
  where: CompletedLessonWhereUniqueInput;
};


export type MutationUpdateContentArgs = {
  data: ContentUpdateInput;
  where: ContentWhereUniqueInput;
};


export type MutationUpdateDescriptionArgs = {
  data: DescriptionUpdateInput;
  where: DescriptionWhereUniqueInput;
};


export type MutationUpdateLessonArgs = {
  data: LessonUpdateInput;
  where: LessonWhereUniqueInput;
};


export type MutationUpdateManyAnswerArgs = {
  data: AnswerUpdateManyMutationInput;
  where?: InputMaybe<AnswerWhereInput>;
};


export type MutationUpdateManyCategoryArgs = {
  data: CategoryUpdateManyMutationInput;
  where?: InputMaybe<CategoryWhereInput>;
};


export type MutationUpdateManyChoiceArgs = {
  data: ChoiceUpdateManyMutationInput;
  where?: InputMaybe<ChoiceWhereInput>;
};


export type MutationUpdateManyCompletedLessonArgs = {
  data: CompletedLessonUpdateManyMutationInput;
  where?: InputMaybe<CompletedLessonWhereInput>;
};


export type MutationUpdateManyContentArgs = {
  data: ContentUpdateManyMutationInput;
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationUpdateManyDescriptionArgs = {
  data: DescriptionUpdateManyMutationInput;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type MutationUpdateManyLessonArgs = {
  data: LessonUpdateManyMutationInput;
  where?: InputMaybe<LessonWhereInput>;
};


export type MutationUpdateManyNewsArgs = {
  data: NewsUpdateManyMutationInput;
  where?: InputMaybe<NewsWhereInput>;
};


export type MutationUpdateManyPageArgs = {
  data: PageUpdateManyMutationInput;
  where?: InputMaybe<PageWhereInput>;
};


export type MutationUpdateManyQuestionArgs = {
  data: QuestionUpdateManyMutationInput;
  where?: InputMaybe<QuestionWhereInput>;
};


export type MutationUpdateManyQuestionAnswerArgs = {
  data: QuestionAnswerUpdateManyMutationInput;
  where?: InputMaybe<QuestionAnswerWhereInput>;
};


export type MutationUpdateManySubjectArgs = {
  data: SubjectUpdateManyMutationInput;
  where?: InputMaybe<SubjectWhereInput>;
};


export type MutationUpdateManyTransactionArgs = {
  data: TransactionUpdateManyMutationInput;
  where?: InputMaybe<TransactionWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationUpdateNewsArgs = {
  data: NewsUpdateInput;
  where: NewsWhereUniqueInput;
};


export type MutationUpdatePageArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};


export type MutationUpdateQuestionArgs = {
  data: QuestionUpdateInput;
  where: QuestionWhereUniqueInput;
};


export type MutationUpdateQuestionAnswerArgs = {
  data: QuestionAnswerUpdateInput;
  where: QuestionAnswerWhereUniqueInput;
};


export type MutationUpdateSubjectArgs = {
  data: SubjectUpdateInput;
  where: SubjectWhereUniqueInput;
};


export type MutationUpdateTransactionArgs = {
  data: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationUpsertAnswerArgs = {
  create: AnswerCreateInput;
  update: AnswerUpdateInput;
  where: AnswerWhereUniqueInput;
};


export type MutationUpsertCategoryArgs = {
  create: CategoryCreateInput;
  update: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationUpsertChoiceArgs = {
  create: ChoiceCreateInput;
  update: ChoiceUpdateInput;
  where: ChoiceWhereUniqueInput;
};


export type MutationUpsertCompletedLessonArgs = {
  create: CompletedLessonCreateInput;
  update: CompletedLessonUpdateInput;
  where: CompletedLessonWhereUniqueInput;
};


export type MutationUpsertContentArgs = {
  create: ContentCreateInput;
  update: ContentUpdateInput;
  where: ContentWhereUniqueInput;
};


export type MutationUpsertDescriptionArgs = {
  create: DescriptionCreateInput;
  update: DescriptionUpdateInput;
  where: DescriptionWhereUniqueInput;
};


export type MutationUpsertLessonArgs = {
  create: LessonCreateInput;
  update: LessonUpdateInput;
  where: LessonWhereUniqueInput;
};


export type MutationUpsertNewsArgs = {
  create: NewsCreateInput;
  update: NewsUpdateInput;
  where: NewsWhereUniqueInput;
};


export type MutationUpsertPageArgs = {
  create: PageCreateInput;
  update: PageUpdateInput;
  where: PageWhereUniqueInput;
};


export type MutationUpsertQuestionArgs = {
  create: QuestionCreateInput;
  update: QuestionUpdateInput;
  where: QuestionWhereUniqueInput;
};


export type MutationUpsertQuestionAnswerArgs = {
  create: QuestionAnswerCreateInput;
  update: QuestionAnswerUpdateInput;
  where: QuestionAnswerWhereUniqueInput;
};


export type MutationUpsertSubjectArgs = {
  create: SubjectCreateInput;
  update: SubjectUpdateInput;
  where: SubjectWhereUniqueInput;
};


export type MutationUpsertTransactionArgs = {
  create: TransactionCreateInput;
  update: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationUpsertUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumContentTypeFilter = {
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  not?: InputMaybe<NestedEnumContentTypeFilter>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type NestedEnumContentTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumContentTypeFilter>;
  _min?: InputMaybe<NestedEnumContentTypeFilter>;
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  not?: InputMaybe<NestedEnumContentTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type NestedEnumLanguageOptionsFilter = {
  equals?: InputMaybe<LanguageOptions>;
  in?: InputMaybe<Array<LanguageOptions>>;
  not?: InputMaybe<NestedEnumLanguageOptionsFilter>;
  notIn?: InputMaybe<Array<LanguageOptions>>;
};

export type NestedEnumLanguageOptionsWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumLanguageOptionsFilter>;
  _min?: InputMaybe<NestedEnumLanguageOptionsFilter>;
  equals?: InputMaybe<LanguageOptions>;
  in?: InputMaybe<Array<LanguageOptions>>;
  not?: InputMaybe<NestedEnumLanguageOptionsWithAggregatesFilter>;
  notIn?: InputMaybe<Array<LanguageOptions>>;
};

export type NestedEnumMediaTypeFilter = {
  equals?: InputMaybe<MediaType>;
  in?: InputMaybe<Array<MediaType>>;
  not?: InputMaybe<NestedEnumMediaTypeFilter>;
  notIn?: InputMaybe<Array<MediaType>>;
};

export type NestedEnumMediaTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMediaTypeFilter>;
  _min?: InputMaybe<NestedEnumMediaTypeFilter>;
  equals?: InputMaybe<MediaType>;
  in?: InputMaybe<Array<MediaType>>;
  not?: InputMaybe<NestedEnumMediaTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<MediaType>>;
};

export type NestedEnumPageTypeFilter = {
  equals?: InputMaybe<PageType>;
  in?: InputMaybe<Array<PageType>>;
  not?: InputMaybe<NestedEnumPageTypeFilter>;
  notIn?: InputMaybe<Array<PageType>>;
};

export type NestedEnumPageTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPageTypeFilter>;
  _min?: InputMaybe<NestedEnumPageTypeFilter>;
  equals?: InputMaybe<PageType>;
  in?: InputMaybe<Array<PageType>>;
  not?: InputMaybe<NestedEnumPageTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PageType>>;
};

export type NestedEnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type NestedEnumUserTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUserTypeFilter>;
  _min?: InputMaybe<NestedEnumUserTypeFilter>;
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type News = {
  __typename?: 'News';
  _count?: Maybe<NewsCount>;
  contents: Array<Content>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  image: Scalars['String'];
  order: Scalars['Int'];
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};


export type NewsContentsArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type NewsDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};

export type NewsAvgAggregate = {
  __typename?: 'NewsAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type NewsAvgOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type NewsCount = {
  __typename?: 'NewsCount';
  contents: Scalars['Int'];
  descriptions: Scalars['Int'];
};

export type NewsCountAggregate = {
  __typename?: 'NewsCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['Int'];
  order: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type NewsCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NewsCreateInput = {
  contents?: InputMaybe<ContentCreateNestedManyWithoutNewsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutNewsInput>;
  id?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NewsCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NewsCreateNestedOneWithoutContentsInput = {
  connect?: InputMaybe<NewsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NewsCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<NewsCreateWithoutContentsInput>;
};

export type NewsCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<NewsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NewsCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<NewsCreateWithoutDescriptionsInput>;
};

export type NewsCreateOrConnectWithoutContentsInput = {
  create: NewsCreateWithoutContentsInput;
  where: NewsWhereUniqueInput;
};

export type NewsCreateOrConnectWithoutDescriptionsInput = {
  create: NewsCreateWithoutDescriptionsInput;
  where: NewsWhereUniqueInput;
};

export type NewsCreateWithoutContentsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutNewsInput>;
  id?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NewsCreateWithoutDescriptionsInput = {
  contents?: InputMaybe<ContentCreateNestedManyWithoutNewsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NewsGroupBy = {
  __typename?: 'NewsGroupBy';
  _avg?: Maybe<NewsAvgAggregate>;
  _count?: Maybe<NewsCountAggregate>;
  _max?: Maybe<NewsMaxAggregate>;
  _min?: Maybe<NewsMinAggregate>;
  _sum?: Maybe<NewsSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  image: Scalars['String'];
  order: Scalars['Int'];
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type NewsMaxAggregate = {
  __typename?: 'NewsMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NewsMinAggregate = {
  __typename?: 'NewsMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum NewsOrderByRelevanceFieldEnum {
  id = 'id',
  image = 'image'
}

export type NewsOrderByRelevanceInput = {
  fields: Array<NewsOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type NewsOrderByWithAggregationInput = {
  _avg?: InputMaybe<NewsAvgOrderByAggregateInput>;
  _count?: InputMaybe<NewsCountOrderByAggregateInput>;
  _max?: InputMaybe<NewsMaxOrderByAggregateInput>;
  _min?: InputMaybe<NewsMinOrderByAggregateInput>;
  _sum?: InputMaybe<NewsSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NewsOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<NewsOrderByRelevanceInput>;
  contents?: InputMaybe<ContentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NewsRelationFilter = {
  is?: InputMaybe<NewsWhereInput>;
  isNot?: InputMaybe<NewsWhereInput>;
};

export enum NewsScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  image = 'image',
  order = 'order',
  status = 'status',
  updatedAt = 'updatedAt'
}

export type NewsScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<NewsScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<NewsScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<NewsScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  image?: InputMaybe<StringWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type NewsSumAggregate = {
  __typename?: 'NewsSumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type NewsSumOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type NewsUpdateInput = {
  contents?: InputMaybe<ContentUpdateManyWithoutNewsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutNewsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NewsUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NewsUpdateOneWithoutContentsInput = {
  connect?: InputMaybe<NewsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NewsCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<NewsCreateWithoutContentsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<NewsUpdateWithoutContentsInput>;
  upsert?: InputMaybe<NewsUpsertWithoutContentsInput>;
};

export type NewsUpdateOneWithoutDescriptionsInput = {
  connect?: InputMaybe<NewsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NewsCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<NewsCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<NewsUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<NewsUpsertWithoutDescriptionsInput>;
};

export type NewsUpdateWithoutContentsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutNewsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NewsUpdateWithoutDescriptionsInput = {
  contents?: InputMaybe<ContentUpdateManyWithoutNewsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NewsUpsertWithoutContentsInput = {
  create: NewsCreateWithoutContentsInput;
  update: NewsUpdateWithoutContentsInput;
};

export type NewsUpsertWithoutDescriptionsInput = {
  create: NewsCreateWithoutDescriptionsInput;
  update: NewsUpdateWithoutDescriptionsInput;
};

export type NewsWhereInput = {
  AND?: InputMaybe<Array<NewsWhereInput>>;
  NOT?: InputMaybe<Array<NewsWhereInput>>;
  OR?: InputMaybe<Array<NewsWhereInput>>;
  contents?: InputMaybe<ContentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  status?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NewsWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type Page = {
  __typename?: 'Page';
  _count?: Maybe<PageCount>;
  contents: Array<Content>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  order: Scalars['Int'];
  pageType: PageType;
  parent?: Maybe<Page>;
  parentId?: Maybe<Scalars['String']>;
  showOnFooter: Scalars['Boolean'];
  showOnHeader: Scalars['Boolean'];
  status: Scalars['Boolean'];
  subPage: Array<Page>;
  updatedAt: Scalars['DateTime'];
};


export type PageContentsArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type PageDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type PageSubPageArgs = {
  cursor?: InputMaybe<PageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PageOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageWhereInput>;
};

export type PageAvgAggregate = {
  __typename?: 'PageAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type PageAvgOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type PageCount = {
  __typename?: 'PageCount';
  contents: Scalars['Int'];
  descriptions: Scalars['Int'];
  subPage: Scalars['Int'];
};

export type PageCountAggregate = {
  __typename?: 'PageCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  pageType: Scalars['Int'];
  parentId: Scalars['Int'];
  showOnFooter: Scalars['Int'];
  showOnHeader: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PageCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageType?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  showOnFooter?: InputMaybe<SortOrder>;
  showOnHeader?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PageCreateInput = {
  contents?: InputMaybe<ContentCreateNestedManyWithoutPageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutPageInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageType?: InputMaybe<PageType>;
  parent?: InputMaybe<PageCreateNestedOneWithoutSubPageInput>;
  showOnFooter?: InputMaybe<Scalars['Boolean']>;
  showOnHeader?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  subPage?: InputMaybe<PageCreateNestedManyWithoutParentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PageCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageType?: InputMaybe<PageType>;
  parentId?: InputMaybe<Scalars['String']>;
  showOnFooter?: InputMaybe<Scalars['Boolean']>;
  showOnHeader?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PageCreateManyParentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageType?: InputMaybe<PageType>;
  showOnFooter?: InputMaybe<Scalars['Boolean']>;
  showOnHeader?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PageCreateManyParentInputEnvelope = {
  data: Array<PageCreateManyParentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PageCreateNestedManyWithoutParentInput = {
  connect?: InputMaybe<Array<PageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PageCreateOrConnectWithoutParentInput>>;
  create?: InputMaybe<Array<PageCreateWithoutParentInput>>;
  createMany?: InputMaybe<PageCreateManyParentInputEnvelope>;
};

export type PageCreateNestedOneWithoutContentsInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PageCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<PageCreateWithoutContentsInput>;
};

export type PageCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PageCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<PageCreateWithoutDescriptionsInput>;
};

export type PageCreateNestedOneWithoutSubPageInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PageCreateOrConnectWithoutSubPageInput>;
  create?: InputMaybe<PageCreateWithoutSubPageInput>;
};

export type PageCreateOrConnectWithoutContentsInput = {
  create: PageCreateWithoutContentsInput;
  where: PageWhereUniqueInput;
};

export type PageCreateOrConnectWithoutDescriptionsInput = {
  create: PageCreateWithoutDescriptionsInput;
  where: PageWhereUniqueInput;
};

export type PageCreateOrConnectWithoutParentInput = {
  create: PageCreateWithoutParentInput;
  where: PageWhereUniqueInput;
};

export type PageCreateOrConnectWithoutSubPageInput = {
  create: PageCreateWithoutSubPageInput;
  where: PageWhereUniqueInput;
};

export type PageCreateWithoutContentsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutPageInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageType?: InputMaybe<PageType>;
  parent?: InputMaybe<PageCreateNestedOneWithoutSubPageInput>;
  showOnFooter?: InputMaybe<Scalars['Boolean']>;
  showOnHeader?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  subPage?: InputMaybe<PageCreateNestedManyWithoutParentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PageCreateWithoutDescriptionsInput = {
  contents?: InputMaybe<ContentCreateNestedManyWithoutPageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageType?: InputMaybe<PageType>;
  parent?: InputMaybe<PageCreateNestedOneWithoutSubPageInput>;
  showOnFooter?: InputMaybe<Scalars['Boolean']>;
  showOnHeader?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  subPage?: InputMaybe<PageCreateNestedManyWithoutParentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PageCreateWithoutParentInput = {
  contents?: InputMaybe<ContentCreateNestedManyWithoutPageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutPageInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageType?: InputMaybe<PageType>;
  showOnFooter?: InputMaybe<Scalars['Boolean']>;
  showOnHeader?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  subPage?: InputMaybe<PageCreateNestedManyWithoutParentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PageCreateWithoutSubPageInput = {
  contents?: InputMaybe<ContentCreateNestedManyWithoutPageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutPageInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageType?: InputMaybe<PageType>;
  parent?: InputMaybe<PageCreateNestedOneWithoutSubPageInput>;
  showOnFooter?: InputMaybe<Scalars['Boolean']>;
  showOnHeader?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PageGroupBy = {
  __typename?: 'PageGroupBy';
  _avg?: Maybe<PageAvgAggregate>;
  _count?: Maybe<PageCountAggregate>;
  _max?: Maybe<PageMaxAggregate>;
  _min?: Maybe<PageMinAggregate>;
  _sum?: Maybe<PageSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Scalars['Int'];
  pageType: PageType;
  parentId?: Maybe<Scalars['String']>;
  showOnFooter: Scalars['Boolean'];
  showOnHeader: Scalars['Boolean'];
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type PageListRelationFilter = {
  every?: InputMaybe<PageWhereInput>;
  none?: InputMaybe<PageWhereInput>;
  some?: InputMaybe<PageWhereInput>;
};

export type PageMaxAggregate = {
  __typename?: 'PageMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageType?: Maybe<PageType>;
  parentId?: Maybe<Scalars['String']>;
  showOnFooter?: Maybe<Scalars['Boolean']>;
  showOnHeader?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PageMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageType?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  showOnFooter?: InputMaybe<SortOrder>;
  showOnHeader?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PageMinAggregate = {
  __typename?: 'PageMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageType?: Maybe<PageType>;
  parentId?: Maybe<Scalars['String']>;
  showOnFooter?: Maybe<Scalars['Boolean']>;
  showOnHeader?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PageMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageType?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  showOnFooter?: InputMaybe<SortOrder>;
  showOnHeader?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum PageOrderByRelevanceFieldEnum {
  id = 'id',
  parentId = 'parentId'
}

export type PageOrderByRelevanceInput = {
  fields: Array<PageOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PageOrderByWithAggregationInput = {
  _avg?: InputMaybe<PageAvgOrderByAggregateInput>;
  _count?: InputMaybe<PageCountOrderByAggregateInput>;
  _max?: InputMaybe<PageMaxOrderByAggregateInput>;
  _min?: InputMaybe<PageMinOrderByAggregateInput>;
  _sum?: InputMaybe<PageSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageType?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  showOnFooter?: InputMaybe<SortOrder>;
  showOnHeader?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PageOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<PageOrderByRelevanceInput>;
  contents?: InputMaybe<ContentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageType?: InputMaybe<SortOrder>;
  parent?: InputMaybe<PageOrderByWithRelationAndSearchRelevanceInput>;
  parentId?: InputMaybe<SortOrder>;
  showOnFooter?: InputMaybe<SortOrder>;
  showOnHeader?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subPage?: InputMaybe<PageOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PageRelationFilter = {
  is?: InputMaybe<PageWhereInput>;
  isNot?: InputMaybe<PageWhereInput>;
};

export enum PageScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  order = 'order',
  pageType = 'pageType',
  parentId = 'parentId',
  showOnFooter = 'showOnFooter',
  showOnHeader = 'showOnHeader',
  status = 'status',
  updatedAt = 'updatedAt'
}

export type PageScalarWhereInput = {
  AND?: InputMaybe<Array<PageScalarWhereInput>>;
  NOT?: InputMaybe<Array<PageScalarWhereInput>>;
  OR?: InputMaybe<Array<PageScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  pageType?: InputMaybe<EnumPageTypeFilter>;
  parentId?: InputMaybe<StringNullableFilter>;
  showOnFooter?: InputMaybe<BoolFilter>;
  showOnHeader?: InputMaybe<BoolFilter>;
  status?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PageScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PageScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PageScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PageScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  pageType?: InputMaybe<EnumPageTypeWithAggregatesFilter>;
  parentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  showOnFooter?: InputMaybe<BoolWithAggregatesFilter>;
  showOnHeader?: InputMaybe<BoolWithAggregatesFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PageSumAggregate = {
  __typename?: 'PageSumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type PageSumOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export enum PageType {
  ABOUT = 'ABOUT',
  BLOG = 'BLOG',
  CONTACT = 'CONTACT',
  DEFAULT = 'DEFAULT',
  KVKK = 'KVKK',
  OTHER = 'OTHER',
  PRIVACYPOLICY = 'PRIVACYPOLICY',
  SSS = 'SSS',
  USERAGREEMENT = 'USERAGREEMENT'
}

export type PageUpdateInput = {
  contents?: InputMaybe<ContentUpdateManyWithoutPageInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutPageInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  pageType?: InputMaybe<EnumPageTypeFieldUpdateOperationsInput>;
  parent?: InputMaybe<PageUpdateOneWithoutSubPageInput>;
  showOnFooter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnHeader?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subPage?: InputMaybe<PageUpdateManyWithoutParentInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PageUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  pageType?: InputMaybe<EnumPageTypeFieldUpdateOperationsInput>;
  showOnFooter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnHeader?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PageUpdateManyWithWhereWithoutParentInput = {
  data: PageUpdateManyMutationInput;
  where: PageScalarWhereInput;
};

export type PageUpdateManyWithoutParentInput = {
  connect?: InputMaybe<Array<PageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PageCreateOrConnectWithoutParentInput>>;
  create?: InputMaybe<Array<PageCreateWithoutParentInput>>;
  createMany?: InputMaybe<PageCreateManyParentInputEnvelope>;
  delete?: InputMaybe<Array<PageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PageWhereUniqueInput>>;
  set?: InputMaybe<Array<PageWhereUniqueInput>>;
  update?: InputMaybe<Array<PageUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: InputMaybe<Array<PageUpdateManyWithWhereWithoutParentInput>>;
  upsert?: InputMaybe<Array<PageUpsertWithWhereUniqueWithoutParentInput>>;
};

export type PageUpdateOneWithoutContentsInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PageCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<PageCreateWithoutContentsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PageUpdateWithoutContentsInput>;
  upsert?: InputMaybe<PageUpsertWithoutContentsInput>;
};

export type PageUpdateOneWithoutDescriptionsInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PageCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<PageCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PageUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<PageUpsertWithoutDescriptionsInput>;
};

export type PageUpdateOneWithoutSubPageInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PageCreateOrConnectWithoutSubPageInput>;
  create?: InputMaybe<PageCreateWithoutSubPageInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PageUpdateWithoutSubPageInput>;
  upsert?: InputMaybe<PageUpsertWithoutSubPageInput>;
};

export type PageUpdateWithWhereUniqueWithoutParentInput = {
  data: PageUpdateWithoutParentInput;
  where: PageWhereUniqueInput;
};

export type PageUpdateWithoutContentsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutPageInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  pageType?: InputMaybe<EnumPageTypeFieldUpdateOperationsInput>;
  parent?: InputMaybe<PageUpdateOneWithoutSubPageInput>;
  showOnFooter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnHeader?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subPage?: InputMaybe<PageUpdateManyWithoutParentInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PageUpdateWithoutDescriptionsInput = {
  contents?: InputMaybe<ContentUpdateManyWithoutPageInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  pageType?: InputMaybe<EnumPageTypeFieldUpdateOperationsInput>;
  parent?: InputMaybe<PageUpdateOneWithoutSubPageInput>;
  showOnFooter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnHeader?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subPage?: InputMaybe<PageUpdateManyWithoutParentInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PageUpdateWithoutParentInput = {
  contents?: InputMaybe<ContentUpdateManyWithoutPageInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutPageInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  pageType?: InputMaybe<EnumPageTypeFieldUpdateOperationsInput>;
  showOnFooter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnHeader?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subPage?: InputMaybe<PageUpdateManyWithoutParentInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PageUpdateWithoutSubPageInput = {
  contents?: InputMaybe<ContentUpdateManyWithoutPageInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutPageInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  pageType?: InputMaybe<EnumPageTypeFieldUpdateOperationsInput>;
  parent?: InputMaybe<PageUpdateOneWithoutSubPageInput>;
  showOnFooter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnHeader?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PageUpsertWithWhereUniqueWithoutParentInput = {
  create: PageCreateWithoutParentInput;
  update: PageUpdateWithoutParentInput;
  where: PageWhereUniqueInput;
};

export type PageUpsertWithoutContentsInput = {
  create: PageCreateWithoutContentsInput;
  update: PageUpdateWithoutContentsInput;
};

export type PageUpsertWithoutDescriptionsInput = {
  create: PageCreateWithoutDescriptionsInput;
  update: PageUpdateWithoutDescriptionsInput;
};

export type PageUpsertWithoutSubPageInput = {
  create: PageCreateWithoutSubPageInput;
  update: PageUpdateWithoutSubPageInput;
};

export type PageWhereInput = {
  AND?: InputMaybe<Array<PageWhereInput>>;
  NOT?: InputMaybe<Array<PageWhereInput>>;
  OR?: InputMaybe<Array<PageWhereInput>>;
  contents?: InputMaybe<ContentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  pageType?: InputMaybe<EnumPageTypeFilter>;
  parent?: InputMaybe<PageRelationFilter>;
  parentId?: InputMaybe<StringNullableFilter>;
  showOnFooter?: InputMaybe<BoolFilter>;
  showOnHeader?: InputMaybe<BoolFilter>;
  status?: InputMaybe<BoolFilter>;
  subPage?: InputMaybe<PageListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  aggregateAnswer: AggregateAnswer;
  aggregateCategory: AggregateCategory;
  aggregateChoice: AggregateChoice;
  aggregateCompletedLesson: AggregateCompletedLesson;
  aggregateContent: AggregateContent;
  aggregateDescription: AggregateDescription;
  aggregateLesson: AggregateLesson;
  aggregateNews: AggregateNews;
  aggregatePage: AggregatePage;
  aggregateQuestion: AggregateQuestion;
  aggregateQuestionAnswer: AggregateQuestionAnswer;
  aggregateSubject: AggregateSubject;
  aggregateTransaction: AggregateTransaction;
  aggregateUser: AggregateUser;
  answer?: Maybe<Answer>;
  answers: Array<Answer>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  choice?: Maybe<Choice>;
  choices: Array<Choice>;
  completedLesson?: Maybe<CompletedLesson>;
  completedLessons: Array<CompletedLesson>;
  content?: Maybe<Content>;
  contents: Array<Content>;
  description?: Maybe<Description>;
  descriptions: Array<Description>;
  findFirstAnswer?: Maybe<Answer>;
  findFirstCategory?: Maybe<Category>;
  findFirstChoice?: Maybe<Choice>;
  findFirstCompletedLesson?: Maybe<CompletedLesson>;
  findFirstContent?: Maybe<Content>;
  findFirstDescription?: Maybe<Description>;
  findFirstLesson?: Maybe<Lesson>;
  findFirstNews?: Maybe<News>;
  findFirstPage?: Maybe<Page>;
  findFirstQuestion?: Maybe<Question>;
  findFirstQuestionAnswer?: Maybe<QuestionAnswer>;
  findFirstSubject?: Maybe<Subject>;
  findFirstTransaction?: Maybe<Transaction>;
  findFirstUser?: Maybe<User>;
  findManyNews: Array<News>;
  findUniqueNews?: Maybe<News>;
  groupByAnswer: Array<AnswerGroupBy>;
  groupByCategory: Array<CategoryGroupBy>;
  groupByChoice: Array<ChoiceGroupBy>;
  groupByCompletedLesson: Array<CompletedLessonGroupBy>;
  groupByContent: Array<ContentGroupBy>;
  groupByDescription: Array<DescriptionGroupBy>;
  groupByLesson: Array<LessonGroupBy>;
  groupByNews: Array<NewsGroupBy>;
  groupByPage: Array<PageGroupBy>;
  groupByQuestion: Array<QuestionGroupBy>;
  groupByQuestionAnswer: Array<QuestionAnswerGroupBy>;
  groupBySubject: Array<SubjectGroupBy>;
  groupByTransaction: Array<TransactionGroupBy>;
  groupByUser: Array<UserGroupBy>;
  lesson?: Maybe<Lesson>;
  lessons: Array<Lesson>;
  page?: Maybe<Page>;
  pages: Array<Page>;
  question?: Maybe<Question>;
  questionAnswer?: Maybe<QuestionAnswer>;
  questionAnswered?: Maybe<Transaction>;
  questionAnswers: Array<QuestionAnswer>;
  questions: Array<Question>;
  subject?: Maybe<Subject>;
  subjects: Array<Subject>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  user?: Maybe<User>;
  users: Array<User>;
  webPage: Page;
};


export type QueryAggregateAnswerArgs = {
  cursor?: InputMaybe<AnswerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AnswerOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnswerWhereInput>;
};


export type QueryAggregateCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryAggregateChoiceArgs = {
  cursor?: InputMaybe<ChoiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ChoiceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChoiceWhereInput>;
};


export type QueryAggregateCompletedLessonArgs = {
  cursor?: InputMaybe<CompletedLessonWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CompletedLessonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompletedLessonWhereInput>;
};


export type QueryAggregateContentArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryAggregateDescriptionArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type QueryAggregateLessonArgs = {
  cursor?: InputMaybe<LessonWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LessonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LessonWhereInput>;
};


export type QueryAggregateNewsArgs = {
  cursor?: InputMaybe<NewsWhereUniqueInput>;
  orderBy?: InputMaybe<Array<NewsOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NewsWhereInput>;
};


export type QueryAggregatePageArgs = {
  cursor?: InputMaybe<PageWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PageOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageWhereInput>;
};


export type QueryAggregateQuestionArgs = {
  cursor?: InputMaybe<QuestionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<QuestionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionWhereInput>;
};


export type QueryAggregateQuestionAnswerArgs = {
  cursor?: InputMaybe<QuestionAnswerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<QuestionAnswerOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionAnswerWhereInput>;
};


export type QueryAggregateSubjectArgs = {
  cursor?: InputMaybe<SubjectWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SubjectOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubjectWhereInput>;
};


export type QueryAggregateTransactionArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAnswerArgs = {
  where: AnswerWhereUniqueInput;
};


export type QueryAnswersArgs = {
  cursor?: InputMaybe<AnswerWhereUniqueInput>;
  distinct?: InputMaybe<Array<AnswerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AnswerOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnswerWhereInput>;
};


export type QueryCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type QueryChoiceArgs = {
  where: ChoiceWhereUniqueInput;
};


export type QueryChoicesArgs = {
  cursor?: InputMaybe<ChoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChoiceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChoiceWhereInput>;
};


export type QueryCompletedLessonArgs = {
  where: CompletedLessonWhereUniqueInput;
};


export type QueryCompletedLessonsArgs = {
  cursor?: InputMaybe<CompletedLessonWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompletedLessonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompletedLessonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompletedLessonWhereInput>;
};


export type QueryContentArgs = {
  where: ContentWhereUniqueInput;
};


export type QueryContentsArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryDescriptionArgs = {
  where: DescriptionWhereUniqueInput;
};


export type QueryDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type QueryFindFirstAnswerArgs = {
  cursor?: InputMaybe<AnswerWhereUniqueInput>;
  distinct?: InputMaybe<Array<AnswerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AnswerOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnswerWhereInput>;
};


export type QueryFindFirstCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryFindFirstChoiceArgs = {
  cursor?: InputMaybe<ChoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChoiceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChoiceWhereInput>;
};


export type QueryFindFirstCompletedLessonArgs = {
  cursor?: InputMaybe<CompletedLessonWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompletedLessonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompletedLessonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompletedLessonWhereInput>;
};


export type QueryFindFirstContentArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryFindFirstDescriptionArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type QueryFindFirstLessonArgs = {
  cursor?: InputMaybe<LessonWhereUniqueInput>;
  distinct?: InputMaybe<Array<LessonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LessonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LessonWhereInput>;
};


export type QueryFindFirstNewsArgs = {
  cursor?: InputMaybe<NewsWhereUniqueInput>;
  distinct?: InputMaybe<Array<NewsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NewsOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NewsWhereInput>;
};


export type QueryFindFirstPageArgs = {
  cursor?: InputMaybe<PageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PageOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageWhereInput>;
};


export type QueryFindFirstQuestionArgs = {
  cursor?: InputMaybe<QuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionWhereInput>;
};


export type QueryFindFirstQuestionAnswerArgs = {
  cursor?: InputMaybe<QuestionAnswerWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestionAnswerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestionAnswerOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionAnswerWhereInput>;
};


export type QueryFindFirstSubjectArgs = {
  cursor?: InputMaybe<SubjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubjectOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubjectWhereInput>;
};


export type QueryFindFirstTransactionArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindManyNewsArgs = {
  cursor?: InputMaybe<NewsWhereUniqueInput>;
  distinct?: InputMaybe<Array<NewsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NewsOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NewsWhereInput>;
};


export type QueryFindUniqueNewsArgs = {
  where: NewsWhereUniqueInput;
};


export type QueryGroupByAnswerArgs = {
  by: Array<AnswerScalarFieldEnum>;
  having?: InputMaybe<AnswerScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AnswerOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnswerWhereInput>;
};


export type QueryGroupByCategoryArgs = {
  by: Array<CategoryScalarFieldEnum>;
  having?: InputMaybe<CategoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryGroupByChoiceArgs = {
  by: Array<ChoiceScalarFieldEnum>;
  having?: InputMaybe<ChoiceScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ChoiceOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChoiceWhereInput>;
};


export type QueryGroupByCompletedLessonArgs = {
  by: Array<CompletedLessonScalarFieldEnum>;
  having?: InputMaybe<CompletedLessonScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CompletedLessonOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompletedLessonWhereInput>;
};


export type QueryGroupByContentArgs = {
  by: Array<ContentScalarFieldEnum>;
  having?: InputMaybe<ContentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ContentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryGroupByDescriptionArgs = {
  by: Array<DescriptionScalarFieldEnum>;
  having?: InputMaybe<DescriptionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type QueryGroupByLessonArgs = {
  by: Array<LessonScalarFieldEnum>;
  having?: InputMaybe<LessonScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LessonOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LessonWhereInput>;
};


export type QueryGroupByNewsArgs = {
  by: Array<NewsScalarFieldEnum>;
  having?: InputMaybe<NewsScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<NewsOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NewsWhereInput>;
};


export type QueryGroupByPageArgs = {
  by: Array<PageScalarFieldEnum>;
  having?: InputMaybe<PageScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PageOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageWhereInput>;
};


export type QueryGroupByQuestionArgs = {
  by: Array<QuestionScalarFieldEnum>;
  having?: InputMaybe<QuestionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<QuestionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionWhereInput>;
};


export type QueryGroupByQuestionAnswerArgs = {
  by: Array<QuestionAnswerScalarFieldEnum>;
  having?: InputMaybe<QuestionAnswerScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<QuestionAnswerOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionAnswerWhereInput>;
};


export type QueryGroupBySubjectArgs = {
  by: Array<SubjectScalarFieldEnum>;
  having?: InputMaybe<SubjectScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SubjectOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubjectWhereInput>;
};


export type QueryGroupByTransactionArgs = {
  by: Array<TransactionScalarFieldEnum>;
  having?: InputMaybe<TransactionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryLessonArgs = {
  where: LessonWhereUniqueInput;
};


export type QueryLessonsArgs = {
  cursor?: InputMaybe<LessonWhereUniqueInput>;
  distinct?: InputMaybe<Array<LessonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LessonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LessonWhereInput>;
};


export type QueryPageArgs = {
  where: PageWhereUniqueInput;
};


export type QueryPagesArgs = {
  cursor?: InputMaybe<PageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PageOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageWhereInput>;
};


export type QueryQuestionArgs = {
  where: QuestionWhereUniqueInput;
};


export type QueryQuestionAnswerArgs = {
  where: QuestionAnswerWhereUniqueInput;
};


export type QueryQuestionAnsweredArgs = {
  questionId: Scalars['String'];
};


export type QueryQuestionAnswersArgs = {
  cursor?: InputMaybe<QuestionAnswerWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestionAnswerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestionAnswerOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionAnswerWhereInput>;
};


export type QueryQuestionsArgs = {
  cursor?: InputMaybe<QuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionWhereInput>;
};


export type QuerySubjectArgs = {
  where: SubjectWhereUniqueInput;
};


export type QuerySubjectsArgs = {
  cursor?: InputMaybe<SubjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubjectOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubjectWhereInput>;
};


export type QueryTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type QueryTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryWebPageArgs = {
  language: Scalars['String'];
  slug: Scalars['String'];
};

export enum QueryMode {
  default = 'default',
  insensitive = 'insensitive'
}

export type Question = {
  __typename?: 'Question';
  _count?: Maybe<QuestionCount>;
  answers: Array<QuestionAnswer>;
  category: Category;
  categoryId: Scalars['String'];
  choices: Array<Choice>;
  content: Array<Content>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  isPaid: Scalars['Boolean'];
  order: Scalars['Int'];
  photos: Array<Scalars['String']>;
  transactions: Array<Transaction>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type QuestionAnswersArgs = {
  cursor?: InputMaybe<QuestionAnswerWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestionAnswerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestionAnswerOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionAnswerWhereInput>;
};


export type QuestionChoicesArgs = {
  cursor?: InputMaybe<ChoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChoiceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChoiceWhereInput>;
};


export type QuestionContentArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QuestionDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type QuestionTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QuestionAnswer = {
  __typename?: 'QuestionAnswer';
  choice: Choice;
  choiceId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  question: Question;
  questionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['String'];
};

export type QuestionAnswerCountAggregate = {
  __typename?: 'QuestionAnswerCountAggregate';
  _all: Scalars['Int'];
  choiceId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  questionId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type QuestionAnswerCountOrderByAggregateInput = {
  choiceId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type QuestionAnswerCreateInput = {
  choice: ChoiceCreateNestedOneWithoutAnswersInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  question: QuestionCreateNestedOneWithoutAnswersInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutQuestionAnswersInput;
};

export type QuestionAnswerCreateManyChoiceInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  questionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type QuestionAnswerCreateManyChoiceInputEnvelope = {
  data: Array<QuestionAnswerCreateManyChoiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type QuestionAnswerCreateManyInput = {
  choiceId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  questionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type QuestionAnswerCreateManyQuestionInput = {
  choiceId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type QuestionAnswerCreateManyQuestionInputEnvelope = {
  data: Array<QuestionAnswerCreateManyQuestionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type QuestionAnswerCreateManyUserInput = {
  choiceId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  questionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionAnswerCreateManyUserInputEnvelope = {
  data: Array<QuestionAnswerCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type QuestionAnswerCreateNestedManyWithoutChoiceInput = {
  connect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionAnswerCreateOrConnectWithoutChoiceInput>>;
  create?: InputMaybe<Array<QuestionAnswerCreateWithoutChoiceInput>>;
  createMany?: InputMaybe<QuestionAnswerCreateManyChoiceInputEnvelope>;
};

export type QuestionAnswerCreateNestedManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionAnswerCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<QuestionAnswerCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<QuestionAnswerCreateManyQuestionInputEnvelope>;
};

export type QuestionAnswerCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionAnswerCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<QuestionAnswerCreateWithoutUserInput>>;
  createMany?: InputMaybe<QuestionAnswerCreateManyUserInputEnvelope>;
};

export type QuestionAnswerCreateOrConnectWithoutChoiceInput = {
  create: QuestionAnswerCreateWithoutChoiceInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerCreateOrConnectWithoutQuestionInput = {
  create: QuestionAnswerCreateWithoutQuestionInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerCreateOrConnectWithoutUserInput = {
  create: QuestionAnswerCreateWithoutUserInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerCreateWithoutChoiceInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  question: QuestionCreateNestedOneWithoutAnswersInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutQuestionAnswersInput;
};

export type QuestionAnswerCreateWithoutQuestionInput = {
  choice: ChoiceCreateNestedOneWithoutAnswersInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutQuestionAnswersInput;
};

export type QuestionAnswerCreateWithoutUserInput = {
  choice: ChoiceCreateNestedOneWithoutAnswersInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  question: QuestionCreateNestedOneWithoutAnswersInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionAnswerGroupBy = {
  __typename?: 'QuestionAnswerGroupBy';
  _count?: Maybe<QuestionAnswerCountAggregate>;
  _max?: Maybe<QuestionAnswerMaxAggregate>;
  _min?: Maybe<QuestionAnswerMinAggregate>;
  choiceId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  questionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type QuestionAnswerListRelationFilter = {
  every?: InputMaybe<QuestionAnswerWhereInput>;
  none?: InputMaybe<QuestionAnswerWhereInput>;
  some?: InputMaybe<QuestionAnswerWhereInput>;
};

export type QuestionAnswerMaxAggregate = {
  __typename?: 'QuestionAnswerMaxAggregate';
  choiceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type QuestionAnswerMaxOrderByAggregateInput = {
  choiceId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type QuestionAnswerMinAggregate = {
  __typename?: 'QuestionAnswerMinAggregate';
  choiceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type QuestionAnswerMinOrderByAggregateInput = {
  choiceId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type QuestionAnswerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum QuestionAnswerOrderByRelevanceFieldEnum {
  choiceId = 'choiceId',
  id = 'id',
  questionId = 'questionId',
  userId = 'userId'
}

export type QuestionAnswerOrderByRelevanceInput = {
  fields: Array<QuestionAnswerOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type QuestionAnswerOrderByWithAggregationInput = {
  _count?: InputMaybe<QuestionAnswerCountOrderByAggregateInput>;
  _max?: InputMaybe<QuestionAnswerMaxOrderByAggregateInput>;
  _min?: InputMaybe<QuestionAnswerMinOrderByAggregateInput>;
  choiceId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type QuestionAnswerOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<QuestionAnswerOrderByRelevanceInput>;
  choice?: InputMaybe<ChoiceOrderByWithRelationAndSearchRelevanceInput>;
  choiceId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByWithRelationAndSearchRelevanceInput>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum QuestionAnswerScalarFieldEnum {
  choiceId = 'choiceId',
  createdAt = 'createdAt',
  id = 'id',
  questionId = 'questionId',
  updatedAt = 'updatedAt',
  userId = 'userId'
}

export type QuestionAnswerScalarWhereInput = {
  AND?: InputMaybe<Array<QuestionAnswerScalarWhereInput>>;
  NOT?: InputMaybe<Array<QuestionAnswerScalarWhereInput>>;
  OR?: InputMaybe<Array<QuestionAnswerScalarWhereInput>>;
  choiceId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  questionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type QuestionAnswerScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<QuestionAnswerScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<QuestionAnswerScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<QuestionAnswerScalarWhereWithAggregatesInput>>;
  choiceId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  questionId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type QuestionAnswerUpdateInput = {
  choice?: InputMaybe<ChoiceUpdateOneRequiredWithoutAnswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutAnswersInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutQuestionAnswersInput>;
};

export type QuestionAnswerUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QuestionAnswerUpdateManyWithWhereWithoutChoiceInput = {
  data: QuestionAnswerUpdateManyMutationInput;
  where: QuestionAnswerScalarWhereInput;
};

export type QuestionAnswerUpdateManyWithWhereWithoutQuestionInput = {
  data: QuestionAnswerUpdateManyMutationInput;
  where: QuestionAnswerScalarWhereInput;
};

export type QuestionAnswerUpdateManyWithWhereWithoutUserInput = {
  data: QuestionAnswerUpdateManyMutationInput;
  where: QuestionAnswerScalarWhereInput;
};

export type QuestionAnswerUpdateManyWithoutChoiceInput = {
  connect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionAnswerCreateOrConnectWithoutChoiceInput>>;
  create?: InputMaybe<Array<QuestionAnswerCreateWithoutChoiceInput>>;
  createMany?: InputMaybe<QuestionAnswerCreateManyChoiceInputEnvelope>;
  delete?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QuestionAnswerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  set?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  update?: InputMaybe<Array<QuestionAnswerUpdateWithWhereUniqueWithoutChoiceInput>>;
  updateMany?: InputMaybe<Array<QuestionAnswerUpdateManyWithWhereWithoutChoiceInput>>;
  upsert?: InputMaybe<Array<QuestionAnswerUpsertWithWhereUniqueWithoutChoiceInput>>;
};

export type QuestionAnswerUpdateManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionAnswerCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<QuestionAnswerCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<QuestionAnswerCreateManyQuestionInputEnvelope>;
  delete?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QuestionAnswerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  set?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  update?: InputMaybe<Array<QuestionAnswerUpdateWithWhereUniqueWithoutQuestionInput>>;
  updateMany?: InputMaybe<Array<QuestionAnswerUpdateManyWithWhereWithoutQuestionInput>>;
  upsert?: InputMaybe<Array<QuestionAnswerUpsertWithWhereUniqueWithoutQuestionInput>>;
};

export type QuestionAnswerUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionAnswerCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<QuestionAnswerCreateWithoutUserInput>>;
  createMany?: InputMaybe<QuestionAnswerCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QuestionAnswerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  set?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  update?: InputMaybe<Array<QuestionAnswerUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<QuestionAnswerUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<QuestionAnswerUpsertWithWhereUniqueWithoutUserInput>>;
};

export type QuestionAnswerUpdateWithWhereUniqueWithoutChoiceInput = {
  data: QuestionAnswerUpdateWithoutChoiceInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerUpdateWithWhereUniqueWithoutQuestionInput = {
  data: QuestionAnswerUpdateWithoutQuestionInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerUpdateWithWhereUniqueWithoutUserInput = {
  data: QuestionAnswerUpdateWithoutUserInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerUpdateWithoutChoiceInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutAnswersInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutQuestionAnswersInput>;
};

export type QuestionAnswerUpdateWithoutQuestionInput = {
  choice?: InputMaybe<ChoiceUpdateOneRequiredWithoutAnswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutQuestionAnswersInput>;
};

export type QuestionAnswerUpdateWithoutUserInput = {
  choice?: InputMaybe<ChoiceUpdateOneRequiredWithoutAnswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutAnswersInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QuestionAnswerUpsertWithWhereUniqueWithoutChoiceInput = {
  create: QuestionAnswerCreateWithoutChoiceInput;
  update: QuestionAnswerUpdateWithoutChoiceInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerUpsertWithWhereUniqueWithoutQuestionInput = {
  create: QuestionAnswerCreateWithoutQuestionInput;
  update: QuestionAnswerUpdateWithoutQuestionInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerUpsertWithWhereUniqueWithoutUserInput = {
  create: QuestionAnswerCreateWithoutUserInput;
  update: QuestionAnswerUpdateWithoutUserInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerWhereInput = {
  AND?: InputMaybe<Array<QuestionAnswerWhereInput>>;
  NOT?: InputMaybe<Array<QuestionAnswerWhereInput>>;
  OR?: InputMaybe<Array<QuestionAnswerWhereInput>>;
  choice?: InputMaybe<ChoiceRelationFilter>;
  choiceId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  question?: InputMaybe<QuestionRelationFilter>;
  questionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type QuestionAnswerWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type QuestionAvgAggregate = {
  __typename?: 'QuestionAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type QuestionAvgOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type QuestionCount = {
  __typename?: 'QuestionCount';
  answers: Scalars['Int'];
  choices: Scalars['Int'];
  content: Scalars['Int'];
  descriptions: Scalars['Int'];
  transactions: Scalars['Int'];
};

export type QuestionCountAggregate = {
  __typename?: 'QuestionCountAggregate';
  _all: Scalars['Int'];
  categoryId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isPaid: Scalars['Int'];
  order: Scalars['Int'];
  photos: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type QuestionCountOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QuestionCreateInput = {
  answers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutQuestionInput>;
  category: CategoryCreateNestedOneWithoutQuestionsInput;
  choices?: InputMaybe<ChoiceCreateNestedManyWithoutQuestionInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutQuestionInput>;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<QuestionCreatephotosInput>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutQuestionInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateManyCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<QuestionCreatephotosInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateManyCategoryInputEnvelope = {
  data: Array<QuestionCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type QuestionCreateManyInput = {
  categoryId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<QuestionCreatephotosInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<QuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<QuestionCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<QuestionCreateManyCategoryInputEnvelope>;
};

export type QuestionCreateNestedOneWithoutAnswersInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<QuestionCreateWithoutAnswersInput>;
};

export type QuestionCreateNestedOneWithoutChoicesInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutChoicesInput>;
  create?: InputMaybe<QuestionCreateWithoutChoicesInput>;
};

export type QuestionCreateNestedOneWithoutContentInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutContentInput>;
  create?: InputMaybe<QuestionCreateWithoutContentInput>;
};

export type QuestionCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<QuestionCreateWithoutDescriptionsInput>;
};

export type QuestionCreateNestedOneWithoutTransactionsInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<QuestionCreateWithoutTransactionsInput>;
};

export type QuestionCreateOrConnectWithoutAnswersInput = {
  create: QuestionCreateWithoutAnswersInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateOrConnectWithoutCategoryInput = {
  create: QuestionCreateWithoutCategoryInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateOrConnectWithoutChoicesInput = {
  create: QuestionCreateWithoutChoicesInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateOrConnectWithoutContentInput = {
  create: QuestionCreateWithoutContentInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateOrConnectWithoutDescriptionsInput = {
  create: QuestionCreateWithoutDescriptionsInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateOrConnectWithoutTransactionsInput = {
  create: QuestionCreateWithoutTransactionsInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateWithoutAnswersInput = {
  category: CategoryCreateNestedOneWithoutQuestionsInput;
  choices?: InputMaybe<ChoiceCreateNestedManyWithoutQuestionInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutQuestionInput>;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<QuestionCreatephotosInput>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutQuestionInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateWithoutCategoryInput = {
  answers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutQuestionInput>;
  choices?: InputMaybe<ChoiceCreateNestedManyWithoutQuestionInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutQuestionInput>;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<QuestionCreatephotosInput>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutQuestionInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateWithoutChoicesInput = {
  answers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutQuestionInput>;
  category: CategoryCreateNestedOneWithoutQuestionsInput;
  content?: InputMaybe<ContentCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutQuestionInput>;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<QuestionCreatephotosInput>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutQuestionInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateWithoutContentInput = {
  answers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutQuestionInput>;
  category: CategoryCreateNestedOneWithoutQuestionsInput;
  choices?: InputMaybe<ChoiceCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutQuestionInput>;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<QuestionCreatephotosInput>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutQuestionInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateWithoutDescriptionsInput = {
  answers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutQuestionInput>;
  category: CategoryCreateNestedOneWithoutQuestionsInput;
  choices?: InputMaybe<ChoiceCreateNestedManyWithoutQuestionInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<QuestionCreatephotosInput>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutQuestionInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateWithoutTransactionsInput = {
  answers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutQuestionInput>;
  category: CategoryCreateNestedOneWithoutQuestionsInput;
  choices?: InputMaybe<ChoiceCreateNestedManyWithoutQuestionInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutQuestionInput>;
  id?: InputMaybe<Scalars['String']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<QuestionCreatephotosInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreatephotosInput = {
  set: Array<Scalars['String']>;
};

export type QuestionGroupBy = {
  __typename?: 'QuestionGroupBy';
  _avg?: Maybe<QuestionAvgAggregate>;
  _count?: Maybe<QuestionCountAggregate>;
  _max?: Maybe<QuestionMaxAggregate>;
  _min?: Maybe<QuestionMinAggregate>;
  _sum?: Maybe<QuestionSumAggregate>;
  categoryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isPaid: Scalars['Boolean'];
  order: Scalars['Int'];
  photos?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type QuestionListRelationFilter = {
  every?: InputMaybe<QuestionWhereInput>;
  none?: InputMaybe<QuestionWhereInput>;
  some?: InputMaybe<QuestionWhereInput>;
};

export type QuestionMaxAggregate = {
  __typename?: 'QuestionMaxAggregate';
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type QuestionMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QuestionMinAggregate = {
  __typename?: 'QuestionMinAggregate';
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type QuestionMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QuestionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum QuestionOrderByRelevanceFieldEnum {
  categoryId = 'categoryId',
  id = 'id',
  photos = 'photos'
}

export type QuestionOrderByRelevanceInput = {
  fields: Array<QuestionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type QuestionOrderByWithAggregationInput = {
  _avg?: InputMaybe<QuestionAvgOrderByAggregateInput>;
  _count?: InputMaybe<QuestionCountOrderByAggregateInput>;
  _max?: InputMaybe<QuestionMaxOrderByAggregateInput>;
  _min?: InputMaybe<QuestionMinOrderByAggregateInput>;
  _sum?: InputMaybe<QuestionSumOrderByAggregateInput>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QuestionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<QuestionOrderByRelevanceInput>;
  answers?: InputMaybe<QuestionAnswerOrderByRelationAggregateInput>;
  category?: InputMaybe<CategoryOrderByWithRelationAndSearchRelevanceInput>;
  categoryId?: InputMaybe<SortOrder>;
  choices?: InputMaybe<ChoiceOrderByRelationAggregateInput>;
  content?: InputMaybe<ContentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  transactions?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QuestionRelationFilter = {
  is?: InputMaybe<QuestionWhereInput>;
  isNot?: InputMaybe<QuestionWhereInput>;
};

export enum QuestionScalarFieldEnum {
  categoryId = 'categoryId',
  createdAt = 'createdAt',
  id = 'id',
  isPaid = 'isPaid',
  order = 'order',
  photos = 'photos',
  updatedAt = 'updatedAt'
}

export type QuestionScalarWhereInput = {
  AND?: InputMaybe<Array<QuestionScalarWhereInput>>;
  NOT?: InputMaybe<Array<QuestionScalarWhereInput>>;
  OR?: InputMaybe<Array<QuestionScalarWhereInput>>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isPaid?: InputMaybe<BoolFilter>;
  order?: InputMaybe<IntFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type QuestionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<QuestionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<QuestionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<QuestionScalarWhereWithAggregatesInput>>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPaid?: InputMaybe<BoolWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type QuestionSumAggregate = {
  __typename?: 'QuestionSumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type QuestionSumOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type QuestionUpdateInput = {
  answers?: InputMaybe<QuestionAnswerUpdateManyWithoutQuestionInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutQuestionsInput>;
  choices?: InputMaybe<ChoiceUpdateManyWithoutQuestionInput>;
  content?: InputMaybe<ContentUpdateManyWithoutQuestionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutQuestionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<QuestionUpdatephotosInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutQuestionInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<QuestionUpdatephotosInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdateManyWithWhereWithoutCategoryInput = {
  data: QuestionUpdateManyMutationInput;
  where: QuestionScalarWhereInput;
};

export type QuestionUpdateManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<QuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<QuestionCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<QuestionCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<QuestionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QuestionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QuestionWhereUniqueInput>>;
  set?: InputMaybe<Array<QuestionWhereUniqueInput>>;
  update?: InputMaybe<Array<QuestionUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<QuestionUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<QuestionUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type QuestionUpdateOneRequiredWithoutAnswersInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<QuestionCreateWithoutAnswersInput>;
  update?: InputMaybe<QuestionUpdateWithoutAnswersInput>;
  upsert?: InputMaybe<QuestionUpsertWithoutAnswersInput>;
};

export type QuestionUpdateOneRequiredWithoutChoicesInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutChoicesInput>;
  create?: InputMaybe<QuestionCreateWithoutChoicesInput>;
  update?: InputMaybe<QuestionUpdateWithoutChoicesInput>;
  upsert?: InputMaybe<QuestionUpsertWithoutChoicesInput>;
};

export type QuestionUpdateOneWithoutContentInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutContentInput>;
  create?: InputMaybe<QuestionCreateWithoutContentInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<QuestionUpdateWithoutContentInput>;
  upsert?: InputMaybe<QuestionUpsertWithoutContentInput>;
};

export type QuestionUpdateOneWithoutDescriptionsInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<QuestionCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<QuestionUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<QuestionUpsertWithoutDescriptionsInput>;
};

export type QuestionUpdateOneWithoutTransactionsInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<QuestionCreateWithoutTransactionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<QuestionUpdateWithoutTransactionsInput>;
  upsert?: InputMaybe<QuestionUpsertWithoutTransactionsInput>;
};

export type QuestionUpdateWithWhereUniqueWithoutCategoryInput = {
  data: QuestionUpdateWithoutCategoryInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionUpdateWithoutAnswersInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutQuestionsInput>;
  choices?: InputMaybe<ChoiceUpdateManyWithoutQuestionInput>;
  content?: InputMaybe<ContentUpdateManyWithoutQuestionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutQuestionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<QuestionUpdatephotosInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutQuestionInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdateWithoutCategoryInput = {
  answers?: InputMaybe<QuestionAnswerUpdateManyWithoutQuestionInput>;
  choices?: InputMaybe<ChoiceUpdateManyWithoutQuestionInput>;
  content?: InputMaybe<ContentUpdateManyWithoutQuestionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutQuestionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<QuestionUpdatephotosInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutQuestionInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdateWithoutChoicesInput = {
  answers?: InputMaybe<QuestionAnswerUpdateManyWithoutQuestionInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutQuestionsInput>;
  content?: InputMaybe<ContentUpdateManyWithoutQuestionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutQuestionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<QuestionUpdatephotosInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutQuestionInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdateWithoutContentInput = {
  answers?: InputMaybe<QuestionAnswerUpdateManyWithoutQuestionInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutQuestionsInput>;
  choices?: InputMaybe<ChoiceUpdateManyWithoutQuestionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutQuestionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<QuestionUpdatephotosInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutQuestionInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdateWithoutDescriptionsInput = {
  answers?: InputMaybe<QuestionAnswerUpdateManyWithoutQuestionInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutQuestionsInput>;
  choices?: InputMaybe<ChoiceUpdateManyWithoutQuestionInput>;
  content?: InputMaybe<ContentUpdateManyWithoutQuestionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<QuestionUpdatephotosInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutQuestionInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdateWithoutTransactionsInput = {
  answers?: InputMaybe<QuestionAnswerUpdateManyWithoutQuestionInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutQuestionsInput>;
  choices?: InputMaybe<ChoiceUpdateManyWithoutQuestionInput>;
  content?: InputMaybe<ContentUpdateManyWithoutQuestionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutQuestionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<QuestionUpdatephotosInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdatephotosInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type QuestionUpsertWithWhereUniqueWithoutCategoryInput = {
  create: QuestionCreateWithoutCategoryInput;
  update: QuestionUpdateWithoutCategoryInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionUpsertWithoutAnswersInput = {
  create: QuestionCreateWithoutAnswersInput;
  update: QuestionUpdateWithoutAnswersInput;
};

export type QuestionUpsertWithoutChoicesInput = {
  create: QuestionCreateWithoutChoicesInput;
  update: QuestionUpdateWithoutChoicesInput;
};

export type QuestionUpsertWithoutContentInput = {
  create: QuestionCreateWithoutContentInput;
  update: QuestionUpdateWithoutContentInput;
};

export type QuestionUpsertWithoutDescriptionsInput = {
  create: QuestionCreateWithoutDescriptionsInput;
  update: QuestionUpdateWithoutDescriptionsInput;
};

export type QuestionUpsertWithoutTransactionsInput = {
  create: QuestionCreateWithoutTransactionsInput;
  update: QuestionUpdateWithoutTransactionsInput;
};

export type QuestionWhereInput = {
  AND?: InputMaybe<Array<QuestionWhereInput>>;
  NOT?: InputMaybe<Array<QuestionWhereInput>>;
  OR?: InputMaybe<Array<QuestionWhereInput>>;
  answers?: InputMaybe<QuestionAnswerListRelationFilter>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  choices?: InputMaybe<ChoiceListRelationFilter>;
  content?: InputMaybe<ContentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isPaid?: InputMaybe<BoolFilter>;
  order?: InputMaybe<IntFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type QuestionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum SortOrder {
  asc = 'asc',
  desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subject = {
  __typename?: 'Subject';
  _count?: Maybe<SubjectCount>;
  answers: Array<Answer>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  order: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['String'];
};


export type SubjectAnswersArgs = {
  cursor?: InputMaybe<AnswerWhereUniqueInput>;
  distinct?: InputMaybe<Array<AnswerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AnswerOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnswerWhereInput>;
};

export type SubjectAvgAggregate = {
  __typename?: 'SubjectAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type SubjectAvgOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type SubjectCount = {
  __typename?: 'SubjectCount';
  answers: Scalars['Int'];
};

export type SubjectCountAggregate = {
  __typename?: 'SubjectCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  slug: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type SubjectCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SubjectCreateInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutSubjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutSubjectsInput;
};

export type SubjectCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type SubjectCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubjectCreateManyUserInputEnvelope = {
  data: Array<SubjectCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SubjectCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<SubjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubjectCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<SubjectCreateWithoutUserInput>>;
  createMany?: InputMaybe<SubjectCreateManyUserInputEnvelope>;
};

export type SubjectCreateNestedOneWithoutAnswersInput = {
  connect?: InputMaybe<SubjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubjectCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<SubjectCreateWithoutAnswersInput>;
};

export type SubjectCreateOrConnectWithoutAnswersInput = {
  create: SubjectCreateWithoutAnswersInput;
  where: SubjectWhereUniqueInput;
};

export type SubjectCreateOrConnectWithoutUserInput = {
  create: SubjectCreateWithoutUserInput;
  where: SubjectWhereUniqueInput;
};

export type SubjectCreateWithoutAnswersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutSubjectsInput;
};

export type SubjectCreateWithoutUserInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutSubjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubjectGroupBy = {
  __typename?: 'SubjectGroupBy';
  _avg?: Maybe<SubjectAvgAggregate>;
  _count?: Maybe<SubjectCountAggregate>;
  _max?: Maybe<SubjectMaxAggregate>;
  _min?: Maybe<SubjectMinAggregate>;
  _sum?: Maybe<SubjectSumAggregate>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  order: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type SubjectListRelationFilter = {
  every?: InputMaybe<SubjectWhereInput>;
  none?: InputMaybe<SubjectWhereInput>;
  some?: InputMaybe<SubjectWhereInput>;
};

export type SubjectMaxAggregate = {
  __typename?: 'SubjectMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type SubjectMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SubjectMinAggregate = {
  __typename?: 'SubjectMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type SubjectMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SubjectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum SubjectOrderByRelevanceFieldEnum {
  description = 'description',
  id = 'id',
  slug = 'slug',
  title = 'title',
  userId = 'userId'
}

export type SubjectOrderByRelevanceInput = {
  fields: Array<SubjectOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type SubjectOrderByWithAggregationInput = {
  _avg?: InputMaybe<SubjectAvgOrderByAggregateInput>;
  _count?: InputMaybe<SubjectCountOrderByAggregateInput>;
  _max?: InputMaybe<SubjectMaxOrderByAggregateInput>;
  _min?: InputMaybe<SubjectMinOrderByAggregateInput>;
  _sum?: InputMaybe<SubjectSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SubjectOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<SubjectOrderByRelevanceInput>;
  answers?: InputMaybe<AnswerOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrder>;
};

export type SubjectRelationFilter = {
  is?: InputMaybe<SubjectWhereInput>;
  isNot?: InputMaybe<SubjectWhereInput>;
};

export enum SubjectScalarFieldEnum {
  createdAt = 'createdAt',
  description = 'description',
  id = 'id',
  order = 'order',
  slug = 'slug',
  title = 'title',
  updatedAt = 'updatedAt',
  userId = 'userId'
}

export type SubjectScalarWhereInput = {
  AND?: InputMaybe<Array<SubjectScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubjectScalarWhereInput>>;
  OR?: InputMaybe<Array<SubjectScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type SubjectScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SubjectScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SubjectScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SubjectScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type SubjectSumAggregate = {
  __typename?: 'SubjectSumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type SubjectSumOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type SubjectUpdateInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutSubjectInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSubjectsInput>;
};

export type SubjectUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type SubjectUpdateManyWithWhereWithoutUserInput = {
  data: SubjectUpdateManyMutationInput;
  where: SubjectScalarWhereInput;
};

export type SubjectUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<SubjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubjectCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<SubjectCreateWithoutUserInput>>;
  createMany?: InputMaybe<SubjectCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<SubjectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubjectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubjectWhereUniqueInput>>;
  set?: InputMaybe<Array<SubjectWhereUniqueInput>>;
  update?: InputMaybe<Array<SubjectUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<SubjectUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<SubjectUpsertWithWhereUniqueWithoutUserInput>>;
};

export type SubjectUpdateOneRequiredWithoutAnswersInput = {
  connect?: InputMaybe<SubjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubjectCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<SubjectCreateWithoutAnswersInput>;
  update?: InputMaybe<SubjectUpdateWithoutAnswersInput>;
  upsert?: InputMaybe<SubjectUpsertWithoutAnswersInput>;
};

export type SubjectUpdateWithWhereUniqueWithoutUserInput = {
  data: SubjectUpdateWithoutUserInput;
  where: SubjectWhereUniqueInput;
};

export type SubjectUpdateWithoutAnswersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSubjectsInput>;
};

export type SubjectUpdateWithoutUserInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutSubjectInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type SubjectUpsertWithWhereUniqueWithoutUserInput = {
  create: SubjectCreateWithoutUserInput;
  update: SubjectUpdateWithoutUserInput;
  where: SubjectWhereUniqueInput;
};

export type SubjectUpsertWithoutAnswersInput = {
  create: SubjectCreateWithoutAnswersInput;
  update: SubjectUpdateWithoutAnswersInput;
};

export type SubjectWhereInput = {
  AND?: InputMaybe<Array<SubjectWhereInput>>;
  NOT?: InputMaybe<Array<SubjectWhereInput>>;
  OR?: InputMaybe<Array<SubjectWhereInput>>;
  answers?: InputMaybe<AnswerListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type SubjectWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  choice?: Maybe<Choice>;
  choiceId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  credit: Scalars['Int'];
  id: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  message: Scalars['String'];
  question?: Maybe<Question>;
  questionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['String'];
};

export type TransactionAvgAggregate = {
  __typename?: 'TransactionAvgAggregate';
  credit?: Maybe<Scalars['Float']>;
};

export type TransactionAvgOrderByAggregateInput = {
  credit?: InputMaybe<SortOrder>;
};

export type TransactionCountAggregate = {
  __typename?: 'TransactionCountAggregate';
  _all: Scalars['Int'];
  choiceId: Scalars['Int'];
  createdAt: Scalars['Int'];
  credit: Scalars['Int'];
  id: Scalars['Int'];
  isCorrect: Scalars['Int'];
  message: Scalars['Int'];
  questionId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type TransactionCountOrderByAggregateInput = {
  choiceId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  credit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCorrect?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionCreateInput = {
  choice?: InputMaybe<ChoiceCreateNestedOneWithoutTransactionsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutTransactionsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutTransactionsInput;
};

export type TransactionCreateManyChoiceInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type TransactionCreateManyChoiceInputEnvelope = {
  data: Array<TransactionCreateManyChoiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionCreateManyInput = {
  choiceId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type TransactionCreateManyQuestionInput = {
  choiceId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type TransactionCreateManyQuestionInputEnvelope = {
  data: Array<TransactionCreateManyQuestionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionCreateManyUserInput = {
  choiceId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TransactionCreateManyUserInputEnvelope = {
  data: Array<TransactionCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionCreateNestedManyWithoutChoiceInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutChoiceInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutChoiceInput>>;
  createMany?: InputMaybe<TransactionCreateManyChoiceInputEnvelope>;
};

export type TransactionCreateNestedManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<TransactionCreateManyQuestionInputEnvelope>;
};

export type TransactionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutUserInput>>;
  createMany?: InputMaybe<TransactionCreateManyUserInputEnvelope>;
};

export type TransactionCreateOrConnectWithoutChoiceInput = {
  create: TransactionCreateWithoutChoiceInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutQuestionInput = {
  create: TransactionCreateWithoutQuestionInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutUserInput = {
  create: TransactionCreateWithoutUserInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateWithoutChoiceInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutTransactionsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutTransactionsInput;
};

export type TransactionCreateWithoutQuestionInput = {
  choice?: InputMaybe<ChoiceCreateNestedOneWithoutTransactionsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutTransactionsInput;
};

export type TransactionCreateWithoutUserInput = {
  choice?: InputMaybe<ChoiceCreateNestedOneWithoutTransactionsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<QuestionCreateNestedOneWithoutTransactionsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TransactionGroupBy = {
  __typename?: 'TransactionGroupBy';
  _avg?: Maybe<TransactionAvgAggregate>;
  _count?: Maybe<TransactionCountAggregate>;
  _max?: Maybe<TransactionMaxAggregate>;
  _min?: Maybe<TransactionMinAggregate>;
  _sum?: Maybe<TransactionSumAggregate>;
  choiceId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  credit: Scalars['Int'];
  id: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  message: Scalars['String'];
  questionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type TransactionListRelationFilter = {
  every?: InputMaybe<TransactionWhereInput>;
  none?: InputMaybe<TransactionWhereInput>;
  some?: InputMaybe<TransactionWhereInput>;
};

export type TransactionMaxAggregate = {
  __typename?: 'TransactionMaxAggregate';
  choiceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  credit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type TransactionMaxOrderByAggregateInput = {
  choiceId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  credit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCorrect?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionMinAggregate = {
  __typename?: 'TransactionMinAggregate';
  choiceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  credit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type TransactionMinOrderByAggregateInput = {
  choiceId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  credit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCorrect?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum TransactionOrderByRelevanceFieldEnum {
  choiceId = 'choiceId',
  id = 'id',
  message = 'message',
  questionId = 'questionId',
  userId = 'userId'
}

export type TransactionOrderByRelevanceInput = {
  fields: Array<TransactionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type TransactionOrderByWithAggregationInput = {
  _avg?: InputMaybe<TransactionAvgOrderByAggregateInput>;
  _count?: InputMaybe<TransactionCountOrderByAggregateInput>;
  _max?: InputMaybe<TransactionMaxOrderByAggregateInput>;
  _min?: InputMaybe<TransactionMinOrderByAggregateInput>;
  _sum?: InputMaybe<TransactionSumOrderByAggregateInput>;
  choiceId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  credit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCorrect?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<TransactionOrderByRelevanceInput>;
  choice?: InputMaybe<ChoiceOrderByWithRelationAndSearchRelevanceInput>;
  choiceId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  credit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCorrect?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByWithRelationAndSearchRelevanceInput>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum TransactionScalarFieldEnum {
  choiceId = 'choiceId',
  createdAt = 'createdAt',
  credit = 'credit',
  id = 'id',
  isCorrect = 'isCorrect',
  message = 'message',
  questionId = 'questionId',
  updatedAt = 'updatedAt',
  userId = 'userId'
}

export type TransactionScalarWhereInput = {
  AND?: InputMaybe<Array<TransactionScalarWhereInput>>;
  NOT?: InputMaybe<Array<TransactionScalarWhereInput>>;
  OR?: InputMaybe<Array<TransactionScalarWhereInput>>;
  choiceId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credit?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  isCorrect?: InputMaybe<BoolFilter>;
  message?: InputMaybe<StringFilter>;
  questionId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TransactionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  choiceId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  credit?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isCorrect?: InputMaybe<BoolWithAggregatesFilter>;
  message?: InputMaybe<StringWithAggregatesFilter>;
  questionId?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type TransactionSumAggregate = {
  __typename?: 'TransactionSumAggregate';
  credit?: Maybe<Scalars['Int']>;
};

export type TransactionSumOrderByAggregateInput = {
  credit?: InputMaybe<SortOrder>;
};

export type TransactionUpdateInput = {
  choice?: InputMaybe<ChoiceUpdateOneWithoutTransactionsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credit?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCorrect?: InputMaybe<BoolFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutTransactionsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTransactionsInput>;
};

export type TransactionUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credit?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCorrect?: InputMaybe<BoolFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type TransactionUpdateManyWithWhereWithoutChoiceInput = {
  data: TransactionUpdateManyMutationInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithWhereWithoutQuestionInput = {
  data: TransactionUpdateManyMutationInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithWhereWithoutUserInput = {
  data: TransactionUpdateManyMutationInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithoutChoiceInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutChoiceInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutChoiceInput>>;
  createMany?: InputMaybe<TransactionCreateManyChoiceInputEnvelope>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<TransactionUpdateWithWhereUniqueWithoutChoiceInput>>;
  updateMany?: InputMaybe<Array<TransactionUpdateManyWithWhereWithoutChoiceInput>>;
  upsert?: InputMaybe<Array<TransactionUpsertWithWhereUniqueWithoutChoiceInput>>;
};

export type TransactionUpdateManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<TransactionCreateManyQuestionInputEnvelope>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<TransactionUpdateWithWhereUniqueWithoutQuestionInput>>;
  updateMany?: InputMaybe<Array<TransactionUpdateManyWithWhereWithoutQuestionInput>>;
  upsert?: InputMaybe<Array<TransactionUpsertWithWhereUniqueWithoutQuestionInput>>;
};

export type TransactionUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutUserInput>>;
  createMany?: InputMaybe<TransactionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<TransactionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<TransactionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<TransactionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type TransactionUpdateWithWhereUniqueWithoutChoiceInput = {
  data: TransactionUpdateWithoutChoiceInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithWhereUniqueWithoutQuestionInput = {
  data: TransactionUpdateWithoutQuestionInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithWhereUniqueWithoutUserInput = {
  data: TransactionUpdateWithoutUserInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithoutChoiceInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credit?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCorrect?: InputMaybe<BoolFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutTransactionsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTransactionsInput>;
};

export type TransactionUpdateWithoutQuestionInput = {
  choice?: InputMaybe<ChoiceUpdateOneWithoutTransactionsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credit?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCorrect?: InputMaybe<BoolFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTransactionsInput>;
};

export type TransactionUpdateWithoutUserInput = {
  choice?: InputMaybe<ChoiceUpdateOneWithoutTransactionsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credit?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCorrect?: InputMaybe<BoolFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutTransactionsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type TransactionUpsertWithWhereUniqueWithoutChoiceInput = {
  create: TransactionCreateWithoutChoiceInput;
  update: TransactionUpdateWithoutChoiceInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpsertWithWhereUniqueWithoutQuestionInput = {
  create: TransactionCreateWithoutQuestionInput;
  update: TransactionUpdateWithoutQuestionInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpsertWithWhereUniqueWithoutUserInput = {
  create: TransactionCreateWithoutUserInput;
  update: TransactionUpdateWithoutUserInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionWhereInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  choice?: InputMaybe<ChoiceRelationFilter>;
  choiceId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credit?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  isCorrect?: InputMaybe<BoolFilter>;
  message?: InputMaybe<StringFilter>;
  question?: InputMaybe<QuestionRelationFilter>;
  questionId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TransactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userType: UserType;
};

export type UserAuthPayload = {
  __typename?: 'UserAuthPayload';
  email: Scalars['String'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  userType: Scalars['String'];
};

export type UserCount = {
  __typename?: 'UserCount';
  answers: Scalars['Int'];
  completedLessons: Scalars['Int'];
  questionAnswers: Scalars['Int'];
  subjects: Scalars['Int'];
  transactions: Scalars['Int'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  password: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  profilePicture: Scalars['Int'];
  surname: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userType: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  profilePicture?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userType?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutUserInput>;
  completedLessons?: InputMaybe<CompletedLessonCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  questionAnswers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutUserInput>;
  subjects?: InputMaybe<SubjectCreateNestedManyWithoutUserInput>;
  surname?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userType?: InputMaybe<UserType>;
};

export type UserCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userType?: InputMaybe<UserType>;
};

export type UserCreateNestedOneWithoutAnswersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<UserCreateWithoutAnswersInput>;
};

export type UserCreateNestedOneWithoutCompletedLessonsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCompletedLessonsInput>;
  create?: InputMaybe<UserCreateWithoutCompletedLessonsInput>;
};

export type UserCreateNestedOneWithoutQuestionAnswersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutQuestionAnswersInput>;
  create?: InputMaybe<UserCreateWithoutQuestionAnswersInput>;
};

export type UserCreateNestedOneWithoutSubjectsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSubjectsInput>;
  create?: InputMaybe<UserCreateWithoutSubjectsInput>;
};

export type UserCreateNestedOneWithoutTransactionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<UserCreateWithoutTransactionsInput>;
};

export type UserCreateOrConnectWithoutAnswersInput = {
  create: UserCreateWithoutAnswersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCompletedLessonsInput = {
  create: UserCreateWithoutCompletedLessonsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutQuestionAnswersInput = {
  create: UserCreateWithoutQuestionAnswersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSubjectsInput = {
  create: UserCreateWithoutSubjectsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTransactionsInput = {
  create: UserCreateWithoutTransactionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutAnswersInput = {
  completedLessons?: InputMaybe<CompletedLessonCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  questionAnswers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutUserInput>;
  subjects?: InputMaybe<SubjectCreateNestedManyWithoutUserInput>;
  surname?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userType?: InputMaybe<UserType>;
};

export type UserCreateWithoutCompletedLessonsInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  questionAnswers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutUserInput>;
  subjects?: InputMaybe<SubjectCreateNestedManyWithoutUserInput>;
  surname?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userType?: InputMaybe<UserType>;
};

export type UserCreateWithoutQuestionAnswersInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutUserInput>;
  completedLessons?: InputMaybe<CompletedLessonCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  subjects?: InputMaybe<SubjectCreateNestedManyWithoutUserInput>;
  surname?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userType?: InputMaybe<UserType>;
};

export type UserCreateWithoutSubjectsInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutUserInput>;
  completedLessons?: InputMaybe<CompletedLessonCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  questionAnswers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutUserInput>;
  surname?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userType?: InputMaybe<UserType>;
};

export type UserCreateWithoutTransactionsInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutUserInput>;
  completedLessons?: InputMaybe<CompletedLessonCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  questionAnswers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutUserInput>;
  subjects?: InputMaybe<SubjectCreateNestedManyWithoutUserInput>;
  surname?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userType?: InputMaybe<UserType>;
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userType: UserType;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userType?: Maybe<UserType>;
};

export type UserMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  profilePicture?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userType?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userType?: Maybe<UserType>;
};

export type UserMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  profilePicture?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userType?: InputMaybe<SortOrder>;
};

export enum UserOrderByRelevanceFieldEnum {
  email = 'email',
  id = 'id',
  name = 'name',
  password = 'password',
  phoneNumber = 'phoneNumber',
  profilePicture = 'profilePicture',
  surname = 'surname'
}

export type UserOrderByRelevanceInput = {
  fields: Array<UserOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type UserOrderByWithAggregationInput = {
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  profilePicture?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userType?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<UserOrderByRelevanceInput>;
  answers?: InputMaybe<AnswerOrderByRelationAggregateInput>;
  completedLessons?: InputMaybe<CompletedLessonOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  profilePicture?: InputMaybe<SortOrder>;
  questionAnswers?: InputMaybe<QuestionAnswerOrderByRelationAggregateInput>;
  subjects?: InputMaybe<SubjectOrderByRelationAggregateInput>;
  surname?: InputMaybe<SortOrder>;
  transactions?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userType?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  createdAt = 'createdAt',
  email = 'email',
  id = 'id',
  name = 'name',
  password = 'password',
  phoneNumber = 'phoneNumber',
  profilePicture = 'profilePicture',
  surname = 'surname',
  updatedAt = 'updatedAt',
  userType = 'userType'
}

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  password?: InputMaybe<StringWithAggregatesFilter>;
  phoneNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  profilePicture?: InputMaybe<StringNullableWithAggregatesFilter>;
  surname?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  userType?: InputMaybe<EnumUserTypeWithAggregatesFilter>;
};

export enum UserType {
  ADMIN = 'ADMIN',
  COMPANY = 'COMPANY',
  MANAGER = 'MANAGER',
  SALESMAN = 'SALESMAN',
  USER = 'USER'
}

export type UserUpdateInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutUserInput>;
  completedLessons?: InputMaybe<CompletedLessonUpdateManyWithoutUserInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profilePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionAnswers?: InputMaybe<QuestionAnswerUpdateManyWithoutUserInput>;
  subjects?: InputMaybe<SubjectUpdateManyWithoutUserInput>;
  surname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profilePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  surname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutAnswersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<UserCreateWithoutAnswersInput>;
  update?: InputMaybe<UserUpdateWithoutAnswersInput>;
  upsert?: InputMaybe<UserUpsertWithoutAnswersInput>;
};

export type UserUpdateOneRequiredWithoutCompletedLessonsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCompletedLessonsInput>;
  create?: InputMaybe<UserCreateWithoutCompletedLessonsInput>;
  update?: InputMaybe<UserUpdateWithoutCompletedLessonsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCompletedLessonsInput>;
};

export type UserUpdateOneRequiredWithoutQuestionAnswersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutQuestionAnswersInput>;
  create?: InputMaybe<UserCreateWithoutQuestionAnswersInput>;
  update?: InputMaybe<UserUpdateWithoutQuestionAnswersInput>;
  upsert?: InputMaybe<UserUpsertWithoutQuestionAnswersInput>;
};

export type UserUpdateOneRequiredWithoutSubjectsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSubjectsInput>;
  create?: InputMaybe<UserCreateWithoutSubjectsInput>;
  update?: InputMaybe<UserUpdateWithoutSubjectsInput>;
  upsert?: InputMaybe<UserUpsertWithoutSubjectsInput>;
};

export type UserUpdateOneRequiredWithoutTransactionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<UserCreateWithoutTransactionsInput>;
  update?: InputMaybe<UserUpdateWithoutTransactionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutTransactionsInput>;
};

export type UserUpdateWithoutAnswersInput = {
  completedLessons?: InputMaybe<CompletedLessonUpdateManyWithoutUserInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profilePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionAnswers?: InputMaybe<QuestionAnswerUpdateManyWithoutUserInput>;
  subjects?: InputMaybe<SubjectUpdateManyWithoutUserInput>;
  surname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCompletedLessonsInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutUserInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profilePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionAnswers?: InputMaybe<QuestionAnswerUpdateManyWithoutUserInput>;
  subjects?: InputMaybe<SubjectUpdateManyWithoutUserInput>;
  surname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutQuestionAnswersInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutUserInput>;
  completedLessons?: InputMaybe<CompletedLessonUpdateManyWithoutUserInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profilePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subjects?: InputMaybe<SubjectUpdateManyWithoutUserInput>;
  surname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSubjectsInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutUserInput>;
  completedLessons?: InputMaybe<CompletedLessonUpdateManyWithoutUserInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profilePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionAnswers?: InputMaybe<QuestionAnswerUpdateManyWithoutUserInput>;
  surname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTransactionsInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutUserInput>;
  completedLessons?: InputMaybe<CompletedLessonUpdateManyWithoutUserInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profilePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionAnswers?: InputMaybe<QuestionAnswerUpdateManyWithoutUserInput>;
  subjects?: InputMaybe<SubjectUpdateManyWithoutUserInput>;
  surname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  userType?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
};

export type UserUpsertWithoutAnswersInput = {
  create: UserCreateWithoutAnswersInput;
  update: UserUpdateWithoutAnswersInput;
};

export type UserUpsertWithoutCompletedLessonsInput = {
  create: UserCreateWithoutCompletedLessonsInput;
  update: UserUpdateWithoutCompletedLessonsInput;
};

export type UserUpsertWithoutQuestionAnswersInput = {
  create: UserCreateWithoutQuestionAnswersInput;
  update: UserUpdateWithoutQuestionAnswersInput;
};

export type UserUpsertWithoutSubjectsInput = {
  create: UserCreateWithoutSubjectsInput;
  update: UserUpdateWithoutSubjectsInput;
};

export type UserUpsertWithoutTransactionsInput = {
  create: UserCreateWithoutTransactionsInput;
  update: UserUpdateWithoutTransactionsInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  answers?: InputMaybe<AnswerListRelationFilter>;
  completedLessons?: InputMaybe<CompletedLessonListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  password?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  questionAnswers?: InputMaybe<QuestionAnswerListRelationFilter>;
  subjects?: InputMaybe<SubjectListRelationFilter>;
  surname?: InputMaybe<StringNullableFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userType?: InputMaybe<EnumUserTypeFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type AnswersQueryVariables = Exact<{
  where?: InputMaybe<AnswerWhereInput>;
  orderBy?: InputMaybe<Array<AnswerOrderByWithRelationAndSearchRelevanceInput> | AnswerOrderByWithRelationAndSearchRelevanceInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AnswersQuery = { __typename?: 'Query', answers: Array<{ __typename?: 'Answer', id: string, createdAt: any, updatedAt?: any | null, description: string, upVote: number, downVote: number, subjectId: string, userId: string, user: { __typename?: 'User', id: string, name?: string | null, surname?: string | null }, subject: { __typename?: 'Subject', id: string, updatedAt?: any | null, createdAt: any, title: string, slug: string, order: number } }> };

export type CreateAnswerMutationVariables = Exact<{
  data: AnswerCreateInput;
}>;


export type CreateAnswerMutation = { __typename?: 'Mutation', createAnswer: { __typename?: 'Answer', id: string } };

export type UpdateAnswerMutationVariables = Exact<{
  data: AnswerUpdateInput;
  where: AnswerWhereUniqueInput;
}>;


export type UpdateAnswerMutation = { __typename?: 'Mutation', updateAnswer?: { __typename?: 'Answer', id: string } | null };

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'UserAuthPayload', token: string, id: string, email: string, userType: string, name?: string | null } };

export type RegisterMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: boolean };

export type CategoriesQueryVariables = Exact<{
  where?: InputMaybe<CategoryWhereInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput> | CategoryOrderByWithRelationAndSearchRelevanceInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  descriptionsWhere2?: InputMaybe<DescriptionWhereInput>;
  choicesWhere2?: InputMaybe<ChoiceWhereInput>;
  questionsOrderBy2?: InputMaybe<Array<QuestionOrderByWithRelationAndSearchRelevanceInput> | QuestionOrderByWithRelationAndSearchRelevanceInput>;
}>;


export type CategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: string, photos: Array<string>, parentId?: string | null, order: number, status: boolean, createdAt: any, updatedAt?: any | null, descriptions: Array<{ __typename?: 'Description', title: string, id: string, description?: string | null, slug: string, language: LanguageOptions }>, _count?: { __typename?: 'CategoryCount', questions: number, lessons: number } | null, questions: Array<{ __typename?: 'Question', id: string, choices: Array<{ __typename?: 'Choice', id: string, credit: number, isCorrect: boolean, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }> }>, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }> }> }> };

export type CategoryQueryVariables = Exact<{
  where: CategoryWhereUniqueInput;
}>;


export type CategoryQuery = { __typename?: 'Query', category?: { __typename?: 'Category', id: string, descriptions: Array<{ __typename?: 'Description', title: string, slug: string, id: string, language: LanguageOptions }>, lessons: Array<{ __typename?: 'Lesson', id: string, isApplication: boolean, updatedAt: any, createdAt: any, descriptions: Array<{ __typename?: 'Description', slug: string, title: string, id: string, language: LanguageOptions }> }> } | null };

export type LessonQueryVariables = Exact<{
  where: LessonWhereUniqueInput;
  contentsWhere2?: InputMaybe<ContentWhereInput>;
  descriptionsWhere2?: InputMaybe<DescriptionWhereInput>;
  descriptionsWhere3?: InputMaybe<DescriptionWhereInput>;
}>;


export type LessonQuery = { __typename?: 'Query', lesson?: { __typename?: 'Lesson', id: string, updatedAt: any, createdAt: any, isApplication: boolean, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string }>, category: { __typename?: 'Category', id: string, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null }> }, contents: Array<{ __typename?: 'Content', id: string, updatedAt: any, createdAt: any, contentType: ContentType, mediaType: MediaType, audio?: string | null, photos: Array<string>, files: Array<string>, video?: string | null, order: number, showOnWeb: boolean }> } | null };

export type LessonsQueryVariables = Exact<{
  where?: InputMaybe<LessonWhereInput>;
  orderBy?: InputMaybe<Array<LessonOrderByWithRelationAndSearchRelevanceInput> | LessonOrderByWithRelationAndSearchRelevanceInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  descriptionsWhere2?: InputMaybe<DescriptionWhereInput>;
  descriptionsWhere3?: InputMaybe<DescriptionWhereInput>;
  contentsWhere2?: InputMaybe<ContentWhereInput>;
}>;


export type LessonsQuery = { __typename?: 'Query', lessons: Array<{ __typename?: 'Lesson', id: string, updatedAt: any, createdAt: any, isApplication: boolean, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string }>, category: { __typename?: 'Category', id: string, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null }> }, contents: Array<{ __typename?: 'Content', id: string, updatedAt: any, createdAt: any, contentType: ContentType, mediaType: MediaType, photos: Array<string>, audio?: string | null, files: Array<string>, video?: string | null, order: number, showOnWeb: boolean }> }> };

export type CreateCustomQuestionAnswerMutationVariables = Exact<{
  choiceId: Scalars['String'];
  questionId: Scalars['String'];
}>;


export type CreateCustomQuestionAnswerMutation = { __typename?: 'Mutation', createCustomQuestionAnswer: { __typename?: 'QuestionAnswer', id: string } };

export type CreateQuestionAnswerMutationVariables = Exact<{
  data: QuestionAnswerCreateInput;
}>;


export type CreateQuestionAnswerMutation = { __typename?: 'Mutation', createQuestionAnswer: { __typename?: 'QuestionAnswer', id: string } };

export type CreateTransactionMutationVariables = Exact<{
  data: TransactionCreateInput;
}>;


export type CreateTransactionMutation = { __typename?: 'Mutation', createTransaction: { __typename?: 'Transaction', id: string } };

export type DeleteQuestionAnswerMutationVariables = Exact<{
  where: QuestionAnswerWhereUniqueInput;
}>;


export type DeleteQuestionAnswerMutation = { __typename?: 'Mutation', deleteQuestionAnswer?: { __typename?: 'QuestionAnswer', id: string } | null };

export type DeleteTransactionMutationVariables = Exact<{
  where: TransactionWhereUniqueInput;
}>;


export type DeleteTransactionMutation = { __typename?: 'Mutation', deleteTransaction?: { __typename?: 'Transaction', id: string } | null };

export type QuestionAnsweredQueryVariables = Exact<{
  questionId: Scalars['String'];
}>;


export type QuestionAnsweredQuery = { __typename?: 'Query', questionAnswered?: { __typename?: 'Transaction', id: string, choiceId?: string | null, credit: number } | null };

export type QuestionsQueryVariables = Exact<{
  where?: InputMaybe<QuestionWhereInput>;
  orderBy?: InputMaybe<Array<QuestionOrderByWithRelationAndSearchRelevanceInput> | QuestionOrderByWithRelationAndSearchRelevanceInput>;
  answersWhere2?: InputMaybe<QuestionAnswerWhereInput>;
}>;


export type QuestionsQuery = { __typename?: 'Query', questions: Array<{ __typename?: 'Question', id: string, updatedAt?: any | null, createdAt: any, isPaid: boolean, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }>, choices: Array<{ __typename?: 'Choice', id: string, createdAt: any, updatedAt?: any | null, isCorrect: boolean, credit: number, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }>, answers: Array<{ __typename?: 'QuestionAnswer', id: string, choice: { __typename?: 'Choice', id: string, credit: number } }> }> }> };

export type TransactionsQueryVariables = Exact<{ [key: string]: never; }>;


export type TransactionsQuery = { __typename?: 'Query', transactions: Array<{ __typename?: 'Transaction', id: string }> };

export type CreateSubjectMutationVariables = Exact<{
  data: SubjectCreateInput;
}>;


export type CreateSubjectMutation = { __typename?: 'Mutation', createSubject: { __typename?: 'Subject', id: string } };

export type SubjectQueryVariables = Exact<{
  where: SubjectWhereUniqueInput;
  answersWhere2?: InputMaybe<AnswerWhereInput>;
  orderBy?: InputMaybe<Array<AnswerOrderByWithRelationAndSearchRelevanceInput> | AnswerOrderByWithRelationAndSearchRelevanceInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type SubjectQuery = { __typename?: 'Query', subject?: { __typename?: 'Subject', id: string, updatedAt?: any | null, createdAt: any, title: string, slug: string, description?: string | null, order: number, user: { __typename?: 'User', id: string, name?: string | null, surname?: string | null }, answers: Array<{ __typename?: 'Answer', id: string, createdAt: any, updatedAt?: any | null, description: string, upVote: number, downVote: number, subjectId: string, userId: string, user: { __typename?: 'User', id: string, name?: string | null, surname?: string | null } }> } | null };

export type SubjectsQueryVariables = Exact<{
  where?: InputMaybe<SubjectWhereInput>;
  orderBy?: InputMaybe<Array<SubjectOrderByWithRelationAndSearchRelevanceInput> | SubjectOrderByWithRelationAndSearchRelevanceInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type SubjectsQuery = { __typename?: 'Query', subjects: Array<{ __typename?: 'Subject', id: string, updatedAt?: any | null, createdAt: any, title: string, slug: string, order: number, user: { __typename?: 'User', id: string, name?: string | null, surname?: string | null }, _count?: { __typename?: 'SubjectCount', answers: number } | null }> };

export type UpdateSubjectMutationVariables = Exact<{
  data: SubjectUpdateInput;
  where: SubjectWhereUniqueInput;
}>;


export type UpdateSubjectMutation = { __typename?: 'Mutation', updateSubject?: { __typename?: 'Subject', id: string } | null };


export const AnswersDocument = gql`
    query Answers($where: AnswerWhereInput, $orderBy: [AnswerOrderByWithRelationAndSearchRelevanceInput!], $take: Int, $skip: Int) {
  answers(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    id
    createdAt
    updatedAt
    description
    upVote
    downVote
    subjectId
    userId
    user {
      id
      name
      surname
    }
    subject {
      id
      updatedAt
      createdAt
      title
      slug
      order
    }
  }
}
    `;

/**
 * __useAnswersQuery__
 *
 * To run a query within a React component, call `useAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnswersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAnswersQuery(baseOptions?: Apollo.QueryHookOptions<AnswersQuery, AnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnswersQuery, AnswersQueryVariables>(AnswersDocument, options);
      }
export function useAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnswersQuery, AnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnswersQuery, AnswersQueryVariables>(AnswersDocument, options);
        }
export type AnswersQueryHookResult = ReturnType<typeof useAnswersQuery>;
export type AnswersLazyQueryHookResult = ReturnType<typeof useAnswersLazyQuery>;
export type AnswersQueryResult = Apollo.QueryResult<AnswersQuery, AnswersQueryVariables>;
export const CreateAnswerDocument = gql`
    mutation CreateAnswer($data: AnswerCreateInput!) {
  createAnswer(data: $data) {
    id
  }
}
    `;
export type CreateAnswerMutationFn = Apollo.MutationFunction<CreateAnswerMutation, CreateAnswerMutationVariables>;

/**
 * __useCreateAnswerMutation__
 *
 * To run a mutation, you first call `useCreateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnswerMutation, { data, loading, error }] = useCreateAnswerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnswerMutation, CreateAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnswerMutation, CreateAnswerMutationVariables>(CreateAnswerDocument, options);
      }
export type CreateAnswerMutationHookResult = ReturnType<typeof useCreateAnswerMutation>;
export type CreateAnswerMutationResult = Apollo.MutationResult<CreateAnswerMutation>;
export type CreateAnswerMutationOptions = Apollo.BaseMutationOptions<CreateAnswerMutation, CreateAnswerMutationVariables>;
export const UpdateAnswerDocument = gql`
    mutation UpdateAnswer($data: AnswerUpdateInput!, $where: AnswerWhereUniqueInput!) {
  updateAnswer(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateAnswerMutationFn = Apollo.MutationFunction<UpdateAnswerMutation, UpdateAnswerMutationVariables>;

/**
 * __useUpdateAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnswerMutation, { data, loading, error }] = useUpdateAnswerMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnswerMutation, UpdateAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnswerMutation, UpdateAnswerMutationVariables>(UpdateAnswerDocument, options);
      }
export type UpdateAnswerMutationHookResult = ReturnType<typeof useUpdateAnswerMutation>;
export type UpdateAnswerMutationResult = Apollo.MutationResult<UpdateAnswerMutation>;
export type UpdateAnswerMutationOptions = Apollo.BaseMutationOptions<UpdateAnswerMutation, UpdateAnswerMutationVariables>;
export const LoginDocument = gql`
    mutation Login($data: LoginInput!) {
  login(data: $data) {
    token
    id
    email
    userType
    name
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation register($data: UserCreateInput!) {
  register(data: $data)
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const CategoriesDocument = gql`
    query Categories($where: CategoryWhereInput, $orderBy: [CategoryOrderByWithRelationAndSearchRelevanceInput!], $take: Int, $skip: Int, $descriptionsWhere2: DescriptionWhereInput, $choicesWhere2: ChoiceWhereInput, $questionsOrderBy2: [QuestionOrderByWithRelationAndSearchRelevanceInput!]) {
  categories(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    id
    photos
    parentId
    order
    status
    createdAt
    updatedAt
    descriptions(where: $descriptionsWhere2) {
      title
      id
      description
      slug
      language
    }
    _count {
      questions
      lessons
    }
    questions(orderBy: $questionsOrderBy2) {
      id
      choices(where: $choicesWhere2) {
        id
        credit
        isCorrect
        descriptions {
          id
          title
          description
          slug
          headline
          buttonText
          buttonUrl
          language
        }
      }
      descriptions {
        id
        title
        description
        slug
        headline
        buttonText
        buttonUrl
        language
      }
    }
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      descriptionsWhere2: // value for 'descriptionsWhere2'
 *      choicesWhere2: // value for 'choicesWhere2'
 *      questionsOrderBy2: // value for 'questionsOrderBy2'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const CategoryDocument = gql`
    query Category($where: CategoryWhereUniqueInput!) {
  category(where: $where) {
    id
    descriptions {
      title
      slug
      id
      language
    }
    lessons {
      id
      isApplication
      updatedAt
      createdAt
      descriptions {
        slug
        title
        id
        language
      }
    }
  }
}
    `;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const LessonDocument = gql`
    query Lesson($where: LessonWhereUniqueInput!, $contentsWhere2: ContentWhereInput, $descriptionsWhere2: DescriptionWhereInput, $descriptionsWhere3: DescriptionWhereInput) {
  lesson(where: $where) {
    id
    updatedAt
    createdAt
    isApplication
    descriptions(where: $descriptionsWhere2) {
      id
      title
      description
      slug
    }
    category {
      id
      descriptions(where: $descriptionsWhere3) {
        id
        title
        description
      }
    }
    contents(where: $contentsWhere2) {
      id
      updatedAt
      createdAt
      contentType
      mediaType
      audio
      photos
      files
      video
      order
      showOnWeb
    }
  }
}
    `;

/**
 * __useLessonQuery__
 *
 * To run a query within a React component, call `useLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonQuery({
 *   variables: {
 *      where: // value for 'where'
 *      contentsWhere2: // value for 'contentsWhere2'
 *      descriptionsWhere2: // value for 'descriptionsWhere2'
 *      descriptionsWhere3: // value for 'descriptionsWhere3'
 *   },
 * });
 */
export function useLessonQuery(baseOptions: Apollo.QueryHookOptions<LessonQuery, LessonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessonQuery, LessonQueryVariables>(LessonDocument, options);
      }
export function useLessonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessonQuery, LessonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessonQuery, LessonQueryVariables>(LessonDocument, options);
        }
export type LessonQueryHookResult = ReturnType<typeof useLessonQuery>;
export type LessonLazyQueryHookResult = ReturnType<typeof useLessonLazyQuery>;
export type LessonQueryResult = Apollo.QueryResult<LessonQuery, LessonQueryVariables>;
export const LessonsDocument = gql`
    query Lessons($where: LessonWhereInput, $orderBy: [LessonOrderByWithRelationAndSearchRelevanceInput!], $take: Int, $skip: Int, $descriptionsWhere2: DescriptionWhereInput, $descriptionsWhere3: DescriptionWhereInput, $contentsWhere2: ContentWhereInput) {
  lessons(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    id
    updatedAt
    createdAt
    isApplication
    descriptions(where: $descriptionsWhere2) {
      id
      title
      description
      slug
    }
    category {
      id
      descriptions(where: $descriptionsWhere3) {
        id
        title
        description
      }
    }
    contents(where: $contentsWhere2) {
      id
      updatedAt
      createdAt
      contentType
      mediaType
      photos
      audio
      files
      video
      order
      showOnWeb
    }
  }
}
    `;

/**
 * __useLessonsQuery__
 *
 * To run a query within a React component, call `useLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      descriptionsWhere2: // value for 'descriptionsWhere2'
 *      descriptionsWhere3: // value for 'descriptionsWhere3'
 *      contentsWhere2: // value for 'contentsWhere2'
 *   },
 * });
 */
export function useLessonsQuery(baseOptions?: Apollo.QueryHookOptions<LessonsQuery, LessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessonsQuery, LessonsQueryVariables>(LessonsDocument, options);
      }
export function useLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessonsQuery, LessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessonsQuery, LessonsQueryVariables>(LessonsDocument, options);
        }
export type LessonsQueryHookResult = ReturnType<typeof useLessonsQuery>;
export type LessonsLazyQueryHookResult = ReturnType<typeof useLessonsLazyQuery>;
export type LessonsQueryResult = Apollo.QueryResult<LessonsQuery, LessonsQueryVariables>;
export const CreateCustomQuestionAnswerDocument = gql`
    mutation CreateCustomQuestionAnswer($choiceId: String!, $questionId: String!) {
  createCustomQuestionAnswer(choiceId: $choiceId, questionId: $questionId) {
    id
  }
}
    `;
export type CreateCustomQuestionAnswerMutationFn = Apollo.MutationFunction<CreateCustomQuestionAnswerMutation, CreateCustomQuestionAnswerMutationVariables>;

/**
 * __useCreateCustomQuestionAnswerMutation__
 *
 * To run a mutation, you first call `useCreateCustomQuestionAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomQuestionAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomQuestionAnswerMutation, { data, loading, error }] = useCreateCustomQuestionAnswerMutation({
 *   variables: {
 *      choiceId: // value for 'choiceId'
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useCreateCustomQuestionAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomQuestionAnswerMutation, CreateCustomQuestionAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomQuestionAnswerMutation, CreateCustomQuestionAnswerMutationVariables>(CreateCustomQuestionAnswerDocument, options);
      }
export type CreateCustomQuestionAnswerMutationHookResult = ReturnType<typeof useCreateCustomQuestionAnswerMutation>;
export type CreateCustomQuestionAnswerMutationResult = Apollo.MutationResult<CreateCustomQuestionAnswerMutation>;
export type CreateCustomQuestionAnswerMutationOptions = Apollo.BaseMutationOptions<CreateCustomQuestionAnswerMutation, CreateCustomQuestionAnswerMutationVariables>;
export const CreateQuestionAnswerDocument = gql`
    mutation CreateQuestionAnswer($data: QuestionAnswerCreateInput!) {
  createQuestionAnswer(data: $data) {
    id
  }
}
    `;
export type CreateQuestionAnswerMutationFn = Apollo.MutationFunction<CreateQuestionAnswerMutation, CreateQuestionAnswerMutationVariables>;

/**
 * __useCreateQuestionAnswerMutation__
 *
 * To run a mutation, you first call `useCreateQuestionAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionAnswerMutation, { data, loading, error }] = useCreateQuestionAnswerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateQuestionAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionAnswerMutation, CreateQuestionAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionAnswerMutation, CreateQuestionAnswerMutationVariables>(CreateQuestionAnswerDocument, options);
      }
export type CreateQuestionAnswerMutationHookResult = ReturnType<typeof useCreateQuestionAnswerMutation>;
export type CreateQuestionAnswerMutationResult = Apollo.MutationResult<CreateQuestionAnswerMutation>;
export type CreateQuestionAnswerMutationOptions = Apollo.BaseMutationOptions<CreateQuestionAnswerMutation, CreateQuestionAnswerMutationVariables>;
export const CreateTransactionDocument = gql`
    mutation CreateTransaction($data: TransactionCreateInput!) {
  createTransaction(data: $data) {
    id
  }
}
    `;
export type CreateTransactionMutationFn = Apollo.MutationFunction<CreateTransactionMutation, CreateTransactionMutationVariables>;

/**
 * __useCreateTransactionMutation__
 *
 * To run a mutation, you first call `useCreateTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransactionMutation, { data, loading, error }] = useCreateTransactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransactionMutation, CreateTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransactionMutation, CreateTransactionMutationVariables>(CreateTransactionDocument, options);
      }
export type CreateTransactionMutationHookResult = ReturnType<typeof useCreateTransactionMutation>;
export type CreateTransactionMutationResult = Apollo.MutationResult<CreateTransactionMutation>;
export type CreateTransactionMutationOptions = Apollo.BaseMutationOptions<CreateTransactionMutation, CreateTransactionMutationVariables>;
export const DeleteQuestionAnswerDocument = gql`
    mutation DeleteQuestionAnswer($where: QuestionAnswerWhereUniqueInput!) {
  deleteQuestionAnswer(where: $where) {
    id
  }
}
    `;
export type DeleteQuestionAnswerMutationFn = Apollo.MutationFunction<DeleteQuestionAnswerMutation, DeleteQuestionAnswerMutationVariables>;

/**
 * __useDeleteQuestionAnswerMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionAnswerMutation, { data, loading, error }] = useDeleteQuestionAnswerMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteQuestionAnswerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionAnswerMutation, DeleteQuestionAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionAnswerMutation, DeleteQuestionAnswerMutationVariables>(DeleteQuestionAnswerDocument, options);
      }
export type DeleteQuestionAnswerMutationHookResult = ReturnType<typeof useDeleteQuestionAnswerMutation>;
export type DeleteQuestionAnswerMutationResult = Apollo.MutationResult<DeleteQuestionAnswerMutation>;
export type DeleteQuestionAnswerMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionAnswerMutation, DeleteQuestionAnswerMutationVariables>;
export const DeleteTransactionDocument = gql`
    mutation DeleteTransaction($where: TransactionWhereUniqueInput!) {
  deleteTransaction(where: $where) {
    id
  }
}
    `;
export type DeleteTransactionMutationFn = Apollo.MutationFunction<DeleteTransactionMutation, DeleteTransactionMutationVariables>;

/**
 * __useDeleteTransactionMutation__
 *
 * To run a mutation, you first call `useDeleteTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransactionMutation, { data, loading, error }] = useDeleteTransactionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteTransactionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTransactionMutation, DeleteTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTransactionMutation, DeleteTransactionMutationVariables>(DeleteTransactionDocument, options);
      }
export type DeleteTransactionMutationHookResult = ReturnType<typeof useDeleteTransactionMutation>;
export type DeleteTransactionMutationResult = Apollo.MutationResult<DeleteTransactionMutation>;
export type DeleteTransactionMutationOptions = Apollo.BaseMutationOptions<DeleteTransactionMutation, DeleteTransactionMutationVariables>;
export const QuestionAnsweredDocument = gql`
    query QuestionAnswered($questionId: String!) {
  questionAnswered(questionId: $questionId) {
    id
    choiceId
    credit
  }
}
    `;

/**
 * __useQuestionAnsweredQuery__
 *
 * To run a query within a React component, call `useQuestionAnsweredQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionAnsweredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionAnsweredQuery({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useQuestionAnsweredQuery(baseOptions: Apollo.QueryHookOptions<QuestionAnsweredQuery, QuestionAnsweredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionAnsweredQuery, QuestionAnsweredQueryVariables>(QuestionAnsweredDocument, options);
      }
export function useQuestionAnsweredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionAnsweredQuery, QuestionAnsweredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionAnsweredQuery, QuestionAnsweredQueryVariables>(QuestionAnsweredDocument, options);
        }
export type QuestionAnsweredQueryHookResult = ReturnType<typeof useQuestionAnsweredQuery>;
export type QuestionAnsweredLazyQueryHookResult = ReturnType<typeof useQuestionAnsweredLazyQuery>;
export type QuestionAnsweredQueryResult = Apollo.QueryResult<QuestionAnsweredQuery, QuestionAnsweredQueryVariables>;
export const QuestionsDocument = gql`
    query Questions($where: QuestionWhereInput, $orderBy: [QuestionOrderByWithRelationAndSearchRelevanceInput!], $answersWhere2: QuestionAnswerWhereInput) {
  questions(where: $where, orderBy: $orderBy) {
    id
    updatedAt
    createdAt
    isPaid
    descriptions {
      id
      title
      description
      slug
      headline
      buttonText
      buttonUrl
      language
    }
    choices {
      id
      createdAt
      updatedAt
      isCorrect
      credit
      descriptions {
        id
        title
        description
        slug
        headline
        buttonText
        buttonUrl
        language
      }
      answers(where: $answersWhere2) {
        id
        choice {
          id
          credit
        }
      }
    }
  }
}
    `;

/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      answersWhere2: // value for 'answersWhere2'
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<QuestionsQuery, QuestionsQueryVariables>;
export const TransactionsDocument = gql`
    query Transactions {
  transactions {
    id
  }
}
    `;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, options);
      }
export function useTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, options);
        }
export type TransactionsQueryHookResult = ReturnType<typeof useTransactionsQuery>;
export type TransactionsLazyQueryHookResult = ReturnType<typeof useTransactionsLazyQuery>;
export type TransactionsQueryResult = Apollo.QueryResult<TransactionsQuery, TransactionsQueryVariables>;
export const CreateSubjectDocument = gql`
    mutation CreateSubject($data: SubjectCreateInput!) {
  createSubject(data: $data) {
    id
  }
}
    `;
export type CreateSubjectMutationFn = Apollo.MutationFunction<CreateSubjectMutation, CreateSubjectMutationVariables>;

/**
 * __useCreateSubjectMutation__
 *
 * To run a mutation, you first call `useCreateSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubjectMutation, { data, loading, error }] = useCreateSubjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSubjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubjectMutation, CreateSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubjectMutation, CreateSubjectMutationVariables>(CreateSubjectDocument, options);
      }
export type CreateSubjectMutationHookResult = ReturnType<typeof useCreateSubjectMutation>;
export type CreateSubjectMutationResult = Apollo.MutationResult<CreateSubjectMutation>;
export type CreateSubjectMutationOptions = Apollo.BaseMutationOptions<CreateSubjectMutation, CreateSubjectMutationVariables>;
export const SubjectDocument = gql`
    query Subject($where: SubjectWhereUniqueInput!, $answersWhere2: AnswerWhereInput, $orderBy: [AnswerOrderByWithRelationAndSearchRelevanceInput!], $take: Int, $skip: Int) {
  subject(where: $where) {
    id
    updatedAt
    createdAt
    title
    slug
    description
    order
    user {
      id
      name
      surname
    }
    answers(where: $answersWhere2, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      createdAt
      updatedAt
      description
      upVote
      downVote
      subjectId
      userId
      user {
        id
        name
        surname
      }
    }
  }
}
    `;

/**
 * __useSubjectQuery__
 *
 * To run a query within a React component, call `useSubjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectQuery({
 *   variables: {
 *      where: // value for 'where'
 *      answersWhere2: // value for 'answersWhere2'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useSubjectQuery(baseOptions: Apollo.QueryHookOptions<SubjectQuery, SubjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubjectQuery, SubjectQueryVariables>(SubjectDocument, options);
      }
export function useSubjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubjectQuery, SubjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubjectQuery, SubjectQueryVariables>(SubjectDocument, options);
        }
export type SubjectQueryHookResult = ReturnType<typeof useSubjectQuery>;
export type SubjectLazyQueryHookResult = ReturnType<typeof useSubjectLazyQuery>;
export type SubjectQueryResult = Apollo.QueryResult<SubjectQuery, SubjectQueryVariables>;
export const SubjectsDocument = gql`
    query Subjects($where: SubjectWhereInput, $orderBy: [SubjectOrderByWithRelationAndSearchRelevanceInput!], $take: Int, $skip: Int) {
  subjects(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    id
    updatedAt
    createdAt
    title
    slug
    order
    user {
      id
      name
      surname
    }
    _count {
      answers
    }
  }
}
    `;

/**
 * __useSubjectsQuery__
 *
 * To run a query within a React component, call `useSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useSubjectsQuery(baseOptions?: Apollo.QueryHookOptions<SubjectsQuery, SubjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubjectsQuery, SubjectsQueryVariables>(SubjectsDocument, options);
      }
export function useSubjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubjectsQuery, SubjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubjectsQuery, SubjectsQueryVariables>(SubjectsDocument, options);
        }
export type SubjectsQueryHookResult = ReturnType<typeof useSubjectsQuery>;
export type SubjectsLazyQueryHookResult = ReturnType<typeof useSubjectsLazyQuery>;
export type SubjectsQueryResult = Apollo.QueryResult<SubjectsQuery, SubjectsQueryVariables>;
export const UpdateSubjectDocument = gql`
    mutation UpdateSubject($data: SubjectUpdateInput!, $where: SubjectWhereUniqueInput!) {
  updateSubject(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateSubjectMutationFn = Apollo.MutationFunction<UpdateSubjectMutation, UpdateSubjectMutationVariables>;

/**
 * __useUpdateSubjectMutation__
 *
 * To run a mutation, you first call `useUpdateSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubjectMutation, { data, loading, error }] = useUpdateSubjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateSubjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubjectMutation, UpdateSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubjectMutation, UpdateSubjectMutationVariables>(UpdateSubjectDocument, options);
      }
export type UpdateSubjectMutationHookResult = ReturnType<typeof useUpdateSubjectMutation>;
export type UpdateSubjectMutationResult = Apollo.MutationResult<UpdateSubjectMutation>;
export type UpdateSubjectMutationOptions = Apollo.BaseMutationOptions<UpdateSubjectMutation, UpdateSubjectMutationVariables>;